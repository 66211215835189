// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import MainNav from '../blocks/navigation/MainNav';
import NotificationsContent from '../blocks/notifications/NotificationsContent';
import type {
  User, NotificationState, Project, Router,
} from '../../types';

type State = any;

type Props = {
  notifications: NotificationState,
  getNotifications: Function,
  addNotification: Function,
  checkForNotifications: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  projects: Array<Project>,
  clearNotifications: Function,
  currentUser: User,
  router: Router,
};

class Notifications extends Component<Props, State> {
  render(): Node {
    const { currentUser, router } = this.props;

    return (
      <div className="notifications-view container-view view-wrapper">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        <NotificationsContent
          {...this.props}
        />
      </div>
    );
  }
}

export default (Notifications: React$ComponentType<Props>);
