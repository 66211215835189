// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import type { Log, Project } from '../../../types';

type Props = {
  projects: Array<Project>,
  log: Log,
}

class TimeLog extends Component<Props> {
  getLogProjectTitle = (log: Log) => {
    const { projects } = this.props;
    if (!log.project_title) {
      const project = projects.find(p => p.id === log.project_id);

      return project ? project.title : '';
    }

    return log.project_title;
  };

  render(): Node {
    const { log } = this.props;
    const parsedTimeSpent = log.time_spent.toString().length >= 4 ? parseFloat(log.time_spent).toFixed(2) : parseFloat(log.time_spent).toFixed(1);
    const projectTitle = this.getLogProjectTitle(log);

    return (
      <div className="edit-log note ghost">
        <div className="clearfix">
          <input type="hidden" className="log-id" id={`log-id-${log.id}`} value={log.id} />
          <input type="hidden" className="project-id" id={`project-id-${log.project_id}`} value={log.project_id} />
          <div className="note-head">
            <h5 className="log-title float-left" id={`log-title-${log.id}`}>
              {projectTitle}
            </h5>
            <h5 className="log-hours float-right" id={`log-hours-${log.id}`}>{parsedTimeSpent}</h5>
          </div>
        </div>
        <div className="clearfix">
          <p className="log-description mb-0">
            {log.overtime && <i className="fa fa-clock-o mr-1" title="overtime" />}
            {log.description}
          </p>
        </div>
      </div>
    );
  }
}

export default (TimeLog: React$ComponentType<Props>);
