// @flow
import { ADD_NOTIFICATIONS_FULFILLED, CLEAR_NOTIFICATIONS, CHECK_FOR_NOTIFICATIONS_FULFILLED } from '../actions/types';
import type { ActionWithPayload, NotificationState } from '../../types';
import { getNormalizedNotifications } from '../../helpers';

export function notificationsReducer(state: NotificationState = { items: [], byId: {}, total: 0 }, action: ActionWithPayload): NotificationState {
  const { type, payload } = action;
  switch (type) {
    case CHECK_FOR_NOTIFICATIONS_FULFILLED:
      return getNormalizedNotifications({ ...state, total: payload.total }, payload.items);
    case ADD_NOTIFICATIONS_FULFILLED:
      return getNormalizedNotifications({ ...state, total: payload.total }, payload.items);
    case CLEAR_NOTIFICATIONS:
      return {
        items: [], byId: {}, total: state.total,
      };
    default:
      return state;
  }
}
