// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import {
  getNotifications, clearNotifications, addNotification, checkForNotifications,
  openModal, closeModal, updateModal,
} from '../redux/actions';
import NotificationsView from '../components/views/Notifications';
import { withRouter } from '../helpers/withRouter';
import type {
  State, NotificationState, User, Project, SingleModalState, Router,
} from '../types';

type Props = {
  notifications: NotificationState,
  getNotifications: Function,
  addNotification: Function,
  checkForNotifications: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  projects: Array<Project>,
  clearNotifications: Function,
  currentUser: User,
  router: Router
};

class Notifications extends Component<Props> {
  render(): Node {
    return (
      <NotificationsView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  notifications: state.notifications,
  currentUser: state.currentUser,
  projects: state.projects.items,
});

const mapDispatchToProps = dispatch => ({
  getNotifications: (page: number = 1) => dispatch(getNotifications(page)),
  clearNotifications: () => dispatch(clearNotifications()),
  addNotification: (target: string, content: string, sendTo: string|Array<string>) => dispatch(addNotification(target, content, sendTo)),
  checkForNotifications: () => dispatch(checkForNotifications()),
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  onUpdateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)((Notifications: React$ComponentType<Props>))): any);
