// @flow
/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { isBefore } from 'date-fns';
import NotificationMailContentPopover from './NotificationMailContentPopover';
import SendNotificationsModal from './SendNotificationsModal';
import ReduxModal from '../modal/ReduxModal';
import { SEND_NOTIFICATION_MODAL } from '../../../redux/reducers/modal';
import { ADD_NOTIFICATION_REJECTED } from '../../../redux/actions/types';
import type { NotificationState, Notification, Project } from '../../../types';
import { initPopElements, removePopElements } from '../../../helpers';
import { formatDate } from '../../../helpers/dates';

type State = {
  currentPage: number,
  initialLoadTime: any,
  fetching: boolean,
};

type Props = {
  notifications: NotificationState,
  getNotifications: Function,
  addNotification: Function,
  checkForNotifications: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  projects: Array<Project>,
  clearNotifications: Function,
};

declare var $: Function;

class NotificationsContent extends Component<Props, State> {
  state: State = {
    currentPage: 1,
    initialLoadTime: new Date(),
    fetching: false,
  };

  icons: { app: string, mail: string, slack: string } = {
    slack: 'fa fa-slack text-primary',
    mail: 'fa fa-envelope text-danger',
    app: 'fa fa-server text-secondary',
  };

  componentDidMount() {
    $('body').addClass('has-popover');
    this.getNotificationsOnMount();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { currentPage, fetching } = this.state;
    const { getNotifications, notifications } = this.props;
    const fetchingOnMountOver = prevState.fetching !== fetching && !fetching;
    const newNotificationItemsArrived = prevProps.notifications.items.length !== notifications.items.length;
    const loadMorePressed = prevState.currentPage !== currentPage && notifications.items.length !== 0;
    if (newNotificationItemsArrived || fetchingOnMountOver) {
      initPopElements();
    }
    if (loadMorePressed) getNotifications(currentPage);
  }

  componentWillUnmount() {
    removePopElements();
    $('body').removeClass('has-popover');
  }

  getNotificationsOnMount = () => {
    const { getNotifications } = this.props;
    this.setState({ fetching: true });
    getNotifications(1)
      .then(() => this.setState({ fetching: false }));
  };

  handleLoadMore = () => {
    const { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
  };

  canLoadMore = () => {
    const { notifications: { total, items } } = this.props;

    return total > items.length;
  };

  getContent = (notification: Notification, trigger?: string) => {
    if (notification.target !== 'mail') {
      return notification.content;
    }

    return <NotificationMailContentPopover trigger={trigger} content={notification.content} />;
  };

  getDate = (date: string) => (
    <span>
      {formatDate(new Date(date), 'dd.LL.yyyy.')}
      <br />
      {formatDate(new Date(date), 'HH:mm')}
    </span>
  );

  getDateSmallScreen = (date: string) => formatDate(new Date(date), 'dd.LL.yyyy. HH:mm');

  reloadNotifications = () => {
    const { clearNotifications, getNotifications } = this.props;
    removePopElements();
    this.setState({ currentPage: 1 });
    clearNotifications();
    getNotifications();
  };

  setRowClasses = (notification: Notification, chunkIndex: number) => {
    const classes = [];
    if (chunkIndex % 2 !== 0) {
      classes.push('table-info');
    }
    if (this.isNewlyAdded(notification.created_at)) {
      classes.push('table-success');
    }

    return classes.join(' ');
  };

  isNewlyAdded = (createdAt: string) => {
    const { initialLoadTime } = this.state;

    return isBefore(initialLoadTime, new Date(createdAt));
  };

  handleOpenModal = () => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: SEND_NOTIFICATION_MODAL });
    removePopElements();
  };

  handleCloseModal = () => {
    const { onCloseModal } = this.props;
    onCloseModal({ id: SEND_NOTIFICATION_MODAL });
    initPopElements();
  };

  handleAddNotification = (target: string, content: string, sendTo: any) => {
    const { addNotification, onUpdateModal } = this.props;
    onUpdateModal({ id: SEND_NOTIFICATION_MODAL, disableButtons: true });

    return addNotification(target, content, sendTo);
  };

  render(): Node {
    const {
      notifications, projects, checkForNotifications,
    } = this.props;
    // grouping notifications in groups of 10 elements
    // each group will get index of the group
    // index is used so table row group can have correct color highlight
    const notificationsInGroupOfTen = _.chunk(notifications.items, 10).map((items, i) => ({ items, i }));
    const notificationsToDisplay = _.reduce(notificationsInGroupOfTen, (items, n) => {
      const chunkItems = n.items.map(e => ({ ...e, index: n.i }));

      return [...items, ...chunkItems];
    }, []);

    return (
      <>
        <div className="container container-header mt-4">
          <div className="upper-header-wrapper">
            <div className="left-column">
              <h2>Notifications History</h2>
              <hr className="float-left" />
            </div>
            <div className="right-column">
              <div className="buttons-wrapper d-flex">
                <button
                  className="btn btn-primary primary-color mr-2"
                  type="button"
                  onClick={this.reloadNotifications}
                >
                  <i className="fa fa-refresh" />
                </button>
                <button
                  className="btn btn-primary primary-color"
                  type="button"
                  onClick={this.handleOpenModal}
                >
                  Send &nbsp;
                  <i className="fa fa-paper-plane" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            {notificationsToDisplay.map(n => (
              <div className="col-12 device-small" key={_.uniqueId(n.id)}>
                <div className="card mb-2">
                  <div className="card-body">
                    <h5 className="card-title">
                      <i className={this.icons[n.target]} aria-hidden="true" title={n.target} />
                      &nbsp;
                      {n.subject}
                      {this.isNewlyAdded(n.created_at) && <i className="fa fa-circle text-primary float-right" />}
                    </h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item px-1">
                        {this.getContent(n, 'focus')}
                      </li>
                      <li className="list-group-item px-1">
                        <span className="text-muted">Sent to:&nbsp;</span>
                        {n.sent_to}
                      </li>
                      <li className="list-group-item px-1">
                        <span className="text-muted">Sent at:&nbsp;</span>
                        {this.getDateSmallScreen(n.created_at)}
                      </li>
                      {n.description && (
                        <li
                          className="list-group-item px-1"
                          dangerouslySetInnerHTML={{ __html: `<span class="text-muted">More info:<br /></span${n.description}` }}
                        />
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-12 device-big">
              <table className="table mt-2 notifications-table">
                <thead className="thead-light">
                  <tr>
                    <th>Target</th>
                    <th>Sent to</th>
                    <th>Subject</th>
                    <th>Content</th>
                    <th>Sent at</th>
                    <th>More info</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationsToDisplay.map(n => (
                    <tr key={_.uniqueId(n.id)} className={this.setRowClasses(n, n.index)}>
                      <td>
                        <i className={this.icons[n.target]} aria-hidden="true" title={n.target} />
                      </td>
                      <td>{n.sent_to}</td>
                      <td>{n.subject}</td>
                      <td style={{ maxWidth: '235px' }}>{this.getContent(n, 'focus')}</td>
                      <td>{this.getDate(n.created_at)}</td>
                      <td dangerouslySetInnerHTML={{ __html: n.description }} />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-default btn-primary secondary-color float-right"
                disabled={!this.canLoadMore()}
                type="button"
                onClick={this.handleLoadMore}
              >
                Load More
              </button>
            </div>
          </div>
        </div>
        <ReduxModal id={SEND_NOTIFICATION_MODAL} watchError={ADD_NOTIFICATION_REJECTED}>
          <SendNotificationsModal
            addNotification={this.handleAddNotification}
            checkForNotifications={checkForNotifications}
            onCloseClick={this.handleCloseModal}
            projects={projects}
          />
        </ReduxModal>
      </>
    );
  }
}

export default (NotificationsContent: React$ComponentType<Props>);
