// @flow
import React from 'react';
import type { Node } from 'react';
import FormGroupWrapper from '../blocks/FormGroupWrapper';

type Props = {
  id: string,
  label: string,
  name: string,
  value: number | string,
  groupClass?: string,
  onChange: Function,
  required?: boolean,
}

const Input = (props: Props): Node => {
  const {
    id, label, onChange, value, name, required, groupClass,
  } = props;

  return (
    <FormGroupWrapper groupClass={groupClass}>
      <label htmlFor={name} className="col-form-label">{label}</label>
      <textarea
        className="form-control"
        id={id}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
      />
    </FormGroupWrapper>
  );
};

export default Input;
