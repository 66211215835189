// @flow
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { isAfter } from 'date-fns';
import Calendar from './Calendar';
// import VacationDaysEditInput from './VacationDaysEditInput';
import { getAsDateArray } from '../../../helpers';
import type {
  User, VacationsState, SingleUserVacationState, Holiday, VacationsPayload, UIVacationStat,
} from '../../../types';

declare var $: Function

type Props = {
  users: Array<User>,
  vacations: VacationsState,
  year: number,
  holidays: Array<Holiday>,
  onBulkSetVacations: Function,
  canLockVacationDate: boolean,
};

type State = {
  calendarOpenedCollapseID: string,
  calendarOpenedUser: User,
};

class VacationManagement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      calendarOpenedCollapseID: '',
      calendarOpenedUser: props.users[0],
    };
  }

  calendarEl = null;

  componentDidMount() {
    $(document).on('show.bs.collapse', '.table-rows .collapse', this.handleCollapseShow);
  }

  componentWillUnmount() {
    $(document).off('show.bs.collapse', '.table-rows .collapse', this.handleCollapseShow);
  }

  getDates = (vacations: SingleUserVacationState, year: string|number) => _.filter(vacations.usedVacationDates, { year: _.toInteger(year) });

  handleCollapseShow = (event: Object) => {
    const el = document.getElementById(event.target.id);
    if (el) {
      const { users } = this.props;
      const user = _.find(users, { id: _.toInteger(el.dataset.user) });
      this.setState({
        calendarOpenedCollapseID: event.target.id,
        calendarOpenedUser: user,
      });
    }
  };

  handleAcceptVacationsChange = (dates: VacationsPayload) => {
    const { calendarOpenedUser } = this.state;
    const {
      onBulkSetVacations, holidays, year, canLockVacationDate,
    } = this.props;

    dates = {
      ...dates,
      added: dates.added.map(d => ({ ...d, locked: canLockVacationDate })).filter(a => !_.map(holidays, 'date').includes(a.date)),
    };

    onBulkSetVacations(calendarOpenedUser.id, year, dates)
      .then(() => {
        if (this.calendarEl) {
          this.calendarEl.handleReset();
        }
      });
  };

  render() {
    const {
      users, vacations, year, holidays,
    } = this.props;
    const { calendarOpenedCollapseID, calendarOpenedUser } = this.state;
    const portalTarget = document.getElementById(calendarOpenedCollapseID);
    // $FlowFixMe
    const holidaysToDisable = [...getAsDateArray(holidays)];
    const isAfterJune30th = isAfter(new Date(), new Date(year, 5, 30));

    return (
      <>
        <div className="container mt-4">
          <div className="upper-header-wrapper">
            <div className="left-column">
              <p className="clearfix mb-4">Vacations usage for users</p>
            </div>
          </div>
        </div>
        <div className="container main-content vacations-table">
          <div className="table-navbar text-center">
            <div className="table-column">
              <h2>User</h2>
            </div>
            <div className="table-column">
              <h2>Alloted</h2>
            </div>
            <div className="table-column">
              <h2>From last year</h2>
            </div>
            <div className="table-column">
              <h2>Total</h2>
            </div>
            <div className="table-column">
              <h2>Remaining</h2>
            </div>
            {/* <div className="table-column">
              <h2>Edit total vacation days</h2>
            </div> */}
            <div className="table-column narrower-column">
              <h2>Calendar</h2>
            </div>
          </div>
          <div className="table-rows">
            {users.map(user => {
              const vacationStat: UIVacationStat = vacations[user.id].uiVacationStats[year];

              return (
                <React.Fragment key={user.id}>
                  <div className="table-row">
                    <div className="single-column centered">
                      {user.name} {user.surname}{user.student && ' (student)'}
                    </div>
                    <div className="single-column centered">
                      {vacationStat.alloted}
                    </div>
                    <div className="single-column centered">
                      {vacationStat.carriedOver}
                    </div>
                    <div className="single-column centered">
                      {vacationStat.totalAlloted}
                    </div>
                    <div className="single-column centered">
                      {vacationStat.remaining + (isAfterJune30th ? 0 : vacationStat.remainingCarriedOverUntilJuly)}
                    </div>

                    {/* <div className="single-column">
                      <VacationDaysEditInput
                        year={year}
                        vacations={vacations[user.id]}
                        user={user}
                        onEditUser={onEditUser}
                      />
                    </div> */}
                    <div className="single-column narrower-column">
                      <button
                        className="btn btn-primary primary-color"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#collapse-user-${user.id}`}
                        aria-expanded="false"
                        aria-controls={`collapse-user-${user.id}`}
                        onClick={() => $('.table-rows .collapse').collapse('hide')}
                      >
                        <i className="fa fa-calendar-check-o" aria-hidden="true" />
                        <i className="ml-2 fa fa-chevron-down" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="collapse has-holidays" id={`collapse-user-${user.id}`} data-user={user.id} />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        {calendarOpenedCollapseID && portalTarget && ReactDOM.createPortal((
          <Calendar
            ref={this.calendarEl}
            selectedYear={year}
            dates={this.getDates(vacations[calendarOpenedUser.id], year)}
            onAcceptChange={this.handleAcceptVacationsChange}
            disabledDays={holidaysToDisable}
          />
        ), portalTarget)}
      </>
    );
  }
}

export default (VacationManagement: React$ComponentType<Props>);
