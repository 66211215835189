// @flow
import React from 'react';
import type { Node } from 'react';
import TableWrapper from '../TableWrapper';
import UsersTableHeader from './table/UsersTableHeader';
import UsersTableContent from './table/UsersTableContent';
import type { User, Project } from '../../../types';

type Props = {
  users: Array<User>,
  selectedRole: string,
  selectedProjectId: number,
  onEditModalOpen: Function,
  onAddModalOpen: Function,
  onSelectRoleChange: Function,
  onSelectProjectChange: Function,
  onSearchUsersSubmit: Function,
  onSearchUsersReset: Function,
  onInactiveChange: Function,
  onStudentChange: Function,
  roles: Array<any>,
  projects: Array<Project>,
  searchTriggered: boolean,
  triggeredRole: string,
  triggeredProjectId: any,
  inactive: boolean,
  student: boolean,
};

const UsersContent = (props: Props): Node => {
  const {
    users,
    selectedRole,
    selectedProjectId,
    onEditModalOpen,
    onAddModalOpen,
    onSelectRoleChange,
    onSelectProjectChange,
    onSearchUsersSubmit,
    onSearchUsersReset,
    roles,
    projects,
    searchTriggered,
    triggeredRole,
    triggeredProjectId,
    onInactiveChange,
    onStudentChange,
    inactive,
    student,
  } = props;

  return (
    <TableWrapper
      className="small-device-user has-custom-table"
    >
      <UsersTableHeader
        selectedRole={selectedRole}
        selectedProjectId={selectedProjectId}
        onSelectRoleChange={onSelectRoleChange}
        onSelectProjectChange={onSelectProjectChange}
        onSearchUsersSubmit={onSearchUsersSubmit}
        onSearchUsersReset={onSearchUsersReset}
        onInactiveChange={onInactiveChange}
        onStudentChange={onStudentChange}
        inactive={inactive}
        student={student}
        roles={roles}
        projects={projects}
        searchTriggered={searchTriggered}
        triggeredRole={triggeredRole}
        triggeredProjectId={triggeredProjectId}
      />
      <UsersTableContent
        users={users}
        onEditModalOpen={onEditModalOpen}
        onAddModalOpen={onAddModalOpen}
      />
    </TableWrapper>
  );
};

export default UsersContent;
