// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import HolidayManagement from './HolidayManagement';
import VacationManagement from './VacationManagement';
import TabsSwitcher from '../TabsSwitcher';
import Select from '../../elements/Select';
import { getYearsForSelect, getUserRolesForSelect } from '../../../helpers';
import type { DateManagementProps } from '../../../types';

type State = {
  selectedYear: number,
  selectRole: any,
  activeView: string,
};

class DateManagementContent extends Component<DateManagementProps, State> {
  state: State = {
    selectedYear: _.toString(new Date().getFullYear()),
    selectRole: 'member',
    activeView: 'vacation',
  };

  handleYearChange = (e: Object) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  handleRoleChange = (e: Object) => {
    this.setState({ selectRole: e.target.value });
  };

  handleFetchHolidaysFromApi = () => {
    const { onFetchHolidaysFromApi } = this.props;
    const { selectedYear } = this.state;
    onFetchHolidaysFromApi(selectedYear);
  };

  render(): Node {
    const {
      users, roles, onEditUser, holidays, onAddHoliday, onUpdateModal,
      onEditHoliday, onOpenModal, onRemoveHoliday, onCloseModal, vacations,
      onBulkSetVacations, currentUser,
    } = this.props;
    const { selectedYear, selectRole, activeView } = this.state;
    const selectRoles = [{ id: 'all', title: 'all' }, ...getUserRolesForSelect(roles)];
    let userToDisplay = _.sortBy(users, ['role', 'name']);
    if (selectRole !== 'all') {
      userToDisplay = _.filter(userToDisplay, { role: selectRole });
    }

    if (_.toInteger(selectedYear) >= new Date().getFullYear()) {
      userToDisplay = _.filter(userToDisplay, 'active');
    }
    const canLockVacationDate = currentUser.isUserAdmin || currentUser.isUserOwner;

    return (
      <>
        <div className="container container-header mt-4">
          <div className="upper-header-wrapper">
            <div className="left-column">
              <h2>Date Management</h2>
              <hr className="float-left" />
            </div>
            <div className="right-column">
              {activeView === 'vacation' && (
                <div className="selects-wrapper">
                  <Select
                    groupClass="month js-month"
                    selectClass="form-control select-role"
                    labelClass=""
                    name="role"
                    label="Select role:"
                    id="role"
                    items={selectRoles}
                    value={selectRole}
                    onSelectChange={this.handleRoleChange}
                  />
                </div>
              )}
              <div className="selects-wrapper">
                <Select
                  groupClass="month js-month"
                  selectClass="form-control select-year"
                  labelClass=""
                  name="year"
                  label="Select year:"
                  id="year"
                  items={getYearsForSelect()}
                  value={selectedYear}
                  onSelectChange={this.handleYearChange}
                />
              </div>
            </div>
          </div>
        </div>
        <TabsSwitcher
          tabs={{ vacation: 'Vacation', holiday: 'Holiday' }}
          onTabCange={(tab) => this.setState({ activeView: tab })}
        />
        {activeView === 'holiday' && (
          <HolidayManagement
            holidays={_.filter(holidays, h => h.date.startsWith(selectedYear))}
            onAddHoliday={onAddHoliday}
            onEditHoliday={onEditHoliday}
            onRemoveHoliday={onRemoveHoliday}
            onOpenModal={onOpenModal}
            onCloseModal={onCloseModal}
            onUpdateModal={onUpdateModal}
            onFetchHolidaysFromApi={this.handleFetchHolidaysFromApi}
          />
        )}
        {activeView === 'vacation' && (
          <VacationManagement
            users={userToDisplay}
            vacations={vacations}
            year={selectedYear}
            holidays={holidays}
            onEditUser={onEditUser}
            onBulkSetVacations={onBulkSetVacations}
            canLockVacationDate={canLockVacationDate}
          />
        )}
      </>

    );
  }
}

export default (DateManagementContent: React$ComponentType<DateManagementProps>);
