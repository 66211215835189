// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import YearCalendarView from '../components/views/YearCalendar';
import type {
  User,
  Project,
  State,
  LogRequest,
  SingleModalState,
  Holiday,
} from '../types';
import {
  addLog,
  updateLog,
  removeLog,
  changeSelectedUser,
  requestDayLogs,
  requestDatesWithlogs,
  openModal,
  closeModal,
  updateModal,
} from '../redux/actions';
import { withRouter } from '../helpers/withRouter';

type Props = {
  logsById: Object,
  datesWithLogs: Array<string>,
  dateTotals: {[date: string]: number},
  currentUser: User,
  users: Array<User>,
  projects: Array<Project>,
  categories: any,
  selectedUser: User,
  routeParams: Object,
  navigate: Object,
  onAddLog: Function,
  onUpdateLog: Function,
  onRemoveLog: Function,
  onSelectedDayChange: Function,
  onRequestDatesWithLogs: Function,
  onSelectedUserChange: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  router: Object,
  location: Object,
  holidays: Array<Holiday>,
  vacations: Object,
}

class YearCalendar extends Component<Props> {
  render(): Node {
    return (
      <YearCalendarView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  logsById: state.logs.logsById,
  datesWithLogs: state.logs.datesWithLogs,
  dateTotals: state.logs.dateTotals,
  currentUser: state.currentUser,
  users: state.users,
  projects: state.projectsDropdown,
  selectedUser: state.selectedUser,
  routeParams: ownProps.router.params,
  categories: state.categories,
  holidays: state.holidays,
  vacations: state.vacations,
  navigate: ownProps.router.navigate,
});

const mapDispatchToProps = dispatch => ({
  onAddLog: (projectId: number, log: LogRequest) => dispatch(addLog(projectId, log)),
  onUpdateLog: (projectId: number, logId: number, log: LogRequest) => dispatch(updateLog(projectId, logId, log)),
  onRemoveLog: (projectId: number, logId: number) => dispatch(removeLog(projectId, logId)),
  onRequestDatesWithLogs: (year: number, userId: number) => dispatch(requestDatesWithlogs(year, userId)),
  onSelectedDayChange: (date: string, userId: number) => dispatch(requestDayLogs(date, userId)),
  onSelectedUserChange: (selectedUserId: number) => dispatch(changeSelectedUser(selectedUserId)),
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  onUpdateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
});

export default ((withRouter((connect(mapStateToProps, mapDispatchToProps)((YearCalendar: React$ComponentType<Props>)))): any));
