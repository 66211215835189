// @flow
import React from 'react';
import type { Node } from 'react';
import FormGroupWrapper from '../blocks/FormGroupWrapper';

type Props = {
  id: string,
  name?: string,
  items: Array<any>,
  labelClass?: string,
  groupClass?: string,
  selectClass: string,
  onSelectChange: Function,
  value: number | string,
  label: string,
  isDisabled?: boolean,
  includeChooseOption?: boolean,
}

const Select = (props: Props): Node => {
  const {
    id, items, selectClass, labelClass, onSelectChange,
    value, label, name, groupClass, isDisabled, includeChooseOption,
  } = props;
  let classes = 'form-group';
  if (groupClass) {
    classes += groupClass;
  }

  return (
    <FormGroupWrapper groupClass={classes}>
      <label htmlFor={id} className={labelClass}>{label}</label>
      <select
        className={selectClass}
        id={id}
        name={name}
        value={value}
        onChange={onSelectChange}
        disabled={isDisabled}
      >
        {includeChooseOption && <option value="">{'Please choose a ' + id}</option>}
        {items.map(item => <option value={item.id} key={item.id}>{item.title}</option>)}
      </select>
    </FormGroupWrapper>
  );
};

export default Select;
