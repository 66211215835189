// @flow
/* eslint camelcase: ["off"] */
import React, { Component } from 'react';
import type { Node } from 'react';
import Input from '../../elements/Input';
import Checkbox from '../../elements/Checkbox';
import ModalHeader from '../modal/ModalHeader';
import ModalFooter from '../modal/ModalFooter';
import ModalControls from '../modal/ModalControls';
import ModalBody from '../modal/ModalBody';
import type { Project } from '../../../types';

type State = {
  form: {
    title: string,
    client_name: string,
    active: boolean,
    billable: boolean,
    categorize_logs: boolean,
    show_task_id: boolean,
    task_id_required: boolean,
    monthly_limit: ?number,
    weekly_report_enabled: boolean,
  }
}

type Props = {
  onProjectSubmit: Function,
  onProjectDelete: Function,
  onCloseClick: Function,
  saveButtonText: string,
  title: string,
  edit: boolean,
  disableButtons?: boolean,
  project: ?Project,
}

class ProjectsModal extends Component<Props, State> {
  static defaultProps = {
    disableButtons: false,
  };

  state = {
    form: {
      title: '',
      client_name: '',
      active: true,
      billable: false,
      categorize_logs: false,
      show_task_id: false,
      monthly_limit: null,
      weekly_report_enabled: false,
      task_id_required: false,
    },
  };

  componentDidUpdate = (prevProps: Props) => {
    const { edit, project, title } = this.props;
    if (prevProps.edit !== edit || prevProps.title !== title) {
      if (edit && project) {
        const {
          title: projectTitle, client_name, active, billable,
          categorize_logs, monthly_limit, weekly_report_enabled,
          show_task_id, task_id_required,
        } = project;
        this.setState({
          form: {
            title: projectTitle,
            client_name,
            active,
            billable,
            categorize_logs,
            monthly_limit,
            weekly_report_enabled,
            show_task_id,
            task_id_required,
          },
        });
      } else {
        this.setState({
          form: {
            title: '',
            client_name: '',
            active: true,
            billable: false,
            categorize_logs: false,
            monthly_limit: null,
            weekly_report_enabled: false,
            show_task_id: false,
            task_id_required: false,
          },
        });
      }
    }
  };

  changeFormField = (field: string, value: any) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  };

  handleProjectTitleChange = (event: Object) => {
    this.changeFormField('title', event.target.value);
  };

  handleProjectClientNameChange = (event: Object) => {
    this.changeFormField('client_name', event.target.value);
  };

  handleProjectActiveChange = (event: Object) => {
    this.changeFormField('active', event.target.checked);
  };

  handleProjectBillableChange = (event: Object) => {
    this.changeFormField('billable', event.target.checked);
  };

  handleProjectCategorizeLogsChange = (event: Object) => {
    this.changeFormField('categorize_logs', event.target.checked);
  };

  handleProjectShowTaskIdChange = (event: Object) => {
    this.changeFormField('show_task_id', event.target.checked);
  };

  handleProjectTaskIdRequiredChange = (event: Object) => {
    this.changeFormField('task_id_required', event.target.checked);
  };

  handleProjectMonthlyLimitChange = (event: Object) => {
    const value = event.target.value > 0 ? event.target.value : (event.target.value * -1);
    this.changeFormField('monthly_limit', value);
  };

  handleProjectWeeklyReportEnabledChange = (event: Object) => {
    this.changeFormField('weekly_report_enabled', event.target.checked);
  };

  isFormValid = () => {
    const { form: { title, client_name } } = this.state;

    return title !== '' && client_name !== '';
  };

  handleSubmit = () => {
    const { onProjectSubmit, edit, project } = this.props;
    const { form } = this.state;
    if (edit && project) {
      const formData = { ...form, id: project.id };
      onProjectSubmit(formData);
    } else {
      onProjectSubmit({ ...form });
    }
  };

  handleDeleteClick = () => {
    const { project, onProjectDelete } = this.props;
    if (project) {
      onProjectDelete(project.id);
    }
  };

  render(): Node {
    const {
      saveButtonText, title, edit, project, disableButtons, onCloseClick,
    } = this.props;
    const { form } = this.state;

    return (
      <>
        <ModalHeader
          title={title}
          onCloseClick={onCloseClick}
        />
        <ModalBody>
          <form>
            <div className="form-group">
              <Input
                id="add-title"
                label="Project title"
                name="title"
                value={form.title}
                onChange={this.handleProjectTitleChange}
              />
            </div>
            <div className="form-group">
              <Input
                id="add-client-name"
                label="Client name"
                name="client-name"
                value={form.client_name}
                onChange={this.handleProjectClientNameChange}
              />
            </div>
            <div className="form-group">
              <Input
                type='number'
                id="add-monthly-limit"
                label="Monthly Limit"
                name="monthly-limit"
                value={form.monthly_limit || ''}
                onChange={this.handleProjectMonthlyLimitChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="active"
                label="Active"
                id="add-active"
                checked={form.active}
                onChange={this.handleProjectActiveChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="billable"
                label="Billable"
                id="add-billable"
                checked={form.billable}
                onChange={this.handleProjectBillableChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="categorize_logs"
                label="Categorize Logs"
                id="add-categorize-logs"
                checked={form.categorize_logs}
                onChange={this.handleProjectCategorizeLogsChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="show_task_id"
                label="Show task ID in reports"
                id="add-show-task-id"
                checked={form.show_task_id}
                onChange={this.handleProjectShowTaskIdChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="task_id_required"
                label="Task ID required"
                id="add-task-id-required"
                checked={form.task_id_required}
                onChange={this.handleProjectTaskIdRequiredChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="weekly_report_enabled"
                label="Weekly Report Enabled"
                id="add-weekly_report_enabled"
                checked={form.weekly_report_enabled}
                onChange={this.handleProjectWeeklyReportEnabledChange}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <ModalControls
            showEditButton={edit && !!project}
            disableButtons={disableButtons || false}
            isFormValid={!this.isFormValid()}
            onSubmitButtonClick={this.handleSubmit}
            onDeleteButtonClick={this.handleDeleteClick}
            onCloseButtonClick={onCloseClick}
            submitButtonText={saveButtonText}
            deleteButtonText="Delete project"
          />
        </ModalFooter>
      </>
    );
  }
}

export default (ProjectsModal: React$ComponentType<Props>);
