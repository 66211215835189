// @flow
import React from 'react';
import type { Node } from 'react';
import Alert from '../../elements/Alert';
import type { User } from '../../../types';

type Props = {
  currentUser: User,
  onEditButtonClick: Function,
  savedForm: boolean,
};

const ProfileView = (props: Props): Node => {
  const { currentUser, onEditButtonClick, savedForm } = props;

  return (
    <div className="card">
      <div className="card-body pt-1">
        <ul className="list-group list-group-flush">
          <li className="list-group-item px-1">
            <span className="text-muted">Name</span>
            {`: ${currentUser.name}`}
          </li>
          <li className="list-group-item px-1">
            <span className="text-muted">Surname</span>
            {`: ${currentUser.surname}`}
          </li>
          <li className="list-group-item px-1">
            <span className="text-muted">Email</span>
            {`: ${currentUser.email}`}
          </li>
          <button
            type="button"
            className="btn btn-primary primary-color"
            onClick={onEditButtonClick}
          >
            Edit profile
          </button>
          {savedForm && (
            <div className="mt-2">
              <Alert display message="Profile was updated" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ProfileView;
