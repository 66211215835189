// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import Select from '../../../elements/Select';
import { getMonthsForSelect, getYearsForSelect } from '../../../../helpers';

type Props = {
  onSelectMonthChange: Function,
  onSelectYearChange: Function,
  onSearchProjectsSubmit: Function,
  onSearchProjectsReset: Function,
  selectedYear: number,
  selectedMonth: number,
  triggerSearch: Function,
};

class ProjectsSearch extends Component<Props, any> {
  handleSelectMonthChange = (event: Object) => {
    const { onSelectMonthChange } = this.props;
    onSelectMonthChange(event.target.value);
  };

  handleSelectYearChange = (event: Object) => {
    const { onSelectYearChange } = this.props;
    onSelectYearChange(event.target.value);
  };

  handleSearchProjectsSubmit = () => {
    const { onSearchProjectsSubmit, triggerSearch } = this.props;
    triggerSearch();
    onSearchProjectsSubmit();
  };

  handleSearchProjectsReset = () => {
    const { onSearchProjectsReset } = this.props;
    onSearchProjectsReset();
  };

  render(): Node {
    const { selectedYear, selectedMonth } = this.props;
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const selectMonths = getMonthsForSelect();
    const selectYears = getYearsForSelect();

    return (
      <div className="right-column">
        <div className="selects-wrapper">
          <Select
            groupClass="month js-month"
            selectClass="form-control select-month"
            labelClass=""
            name="month"
            label="Select month:"
            id="month"
            items={selectMonths}
            value={selectedMonth || currentMonth}
            onSelectChange={this.handleSelectMonthChange}
          />
          <Select
            groupClass="year js-year"
            selectClass="form-control select-year"
            labelClass=""
            name="year"
            label="Select year:"
            id="year"
            items={selectYears}
            value={selectedYear || currentYear}
            onSelectChange={this.handleSelectYearChange}
          />
        </div>
        <div className="buttons-wrapper">
          <button
            className="btn btn-primary search js-filter-projects"
            type="button"
            onClick={this.handleSearchProjectsSubmit}
          >
            Apply filter
          </button>
          <button
            className="btn btn-primary reset-button search js-filter-projects"
            type="button"
            onClick={this.handleSearchProjectsReset}
          >
            Reset filter
          </button>
        </div>
      </div>
    );
  }
}

export default (ProjectsSearch: React$ComponentType<Props>);
