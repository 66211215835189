// @flow
import React, { type Node } from 'react';

type Props = {
  className: string,
  children: Node,
};

const TableWrapper = (props: Props): Node => {
  const { className, children } = props;

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default TableWrapper;
