// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import SpecialNumberInput from '../../elements/SpecialNumberInput';
import type { User, SingleUserVacationState } from '../../../types';

type Props = {
  user: User,
  onEditUser: Function,
  year: number,
  vacations: SingleUserVacationState,
};

type State = {
  vacationDays: number,
};

class VacationDaysEditInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { year, vacations } = this.props;
    this.state = {
      vacationDays: vacations.allotedDays[year],
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { year, vacations } = this.props;
    if (prevProps.year !== year) {
      let vacationDays = vacations.allotedDays[year];
      if (typeof vacationDays === 'undefined') {
        vacationDays = 20;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ vacationDays });
    }
  }

  handleVacationDaysChange = (vacationDays: number) => {
    this.setState({ vacationDays });
  };

  handleAcceptVacationDaysChange = () => {
    const { vacationDays } = this.state;
    const { onEditUser, user, year } = this.props;
    onEditUser({
      id: user.id,
      user: {
        total_vacation_days: {
          count: vacationDays,
          year,
        },
      },
    });
  };

  render() {
    const { vacationDays } = this.state;

    return (
      <SpecialNumberInput
        step={1}
        disableAccept={false}
        showRemoveBtn={false}
        value={_.toInteger(vacationDays)}
        onChangeInput={this.handleVacationDaysChange}
        onAcceptChange={this.handleAcceptVacationDaysChange}
      />
    );
  }
}

export default (VacationDaysEditInput: React$ComponentType<Props>);
