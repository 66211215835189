// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
// $FlowFixMe
import Logo from '../../../../assets/images/timesheets_logo_border.svg';

type Props = {
  navigate: Object,
}

class NavSmallScreen extends Component<Props, any> {
  render(): Node {
    return (
      <div className="container-fluid device-small main-nav">
        <div className="row">
          <nav className="navbar navbar-default nav-flex-small">
            <div className="image-wrapper" onClick={this.handleLogoClick}>
              <img src={Logo} alt="logo" className="nav-logo" />
            </div>
            <div className="invisible">
              <button type="button" className="btn btn-default arrow-left">{'<'}</button>
              <p className="navbar-text calendar-date" />
              <button type="button" className="btn btn-default arrow-right">{'>'}</button>
            </div>
            <button
              className="btn menu-bars"
              type="button"
              data-toggle="collapse"
              data-target="#menu"
              aria-controls="menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-bars fa-2x" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    );
  }

  handleLogoClick = () => {
    const { navigate } = this.props;
    navigate('/');
  };
}

export default (NavSmallScreen: React$ComponentType<Props>);
