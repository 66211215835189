// @flow
import * as API from '../../api';
import { ADD_PROJECT_MONTH_REPORT_STATISTIC, BULK_SET_VACATIONS, ADD_OVERTIME_REPORT } from './types';
import type { Action, VacationsPayload } from '../../types';

export {
  addLog, updateLog, removeLog,
  requestLogs, requestDayLogs, requestDatesWithlogs, changeSelectedUser,
  changeDates, addDraggedLog, removeDraggedLog,
} from './logs';

export {
  addProject, editProject, removeProject, projectSearch, datesChanged,
  getProjectMonthStatistic,
} from './projects';

export {
  addUser, editUser, removeUser,
} from './users';

export {
  getNotifications, clearNotifications, addNotification, checkForNotifications,
} from './notifications';

export {
  addHoliday, editHoliday, removeHoliday, fetchHolidaysFromApi,
} from './holidays';

export {
  openModal, closeModal, updateModal,
} from './modal';

export {
  updateEmailSubscriptions,
} from './email-subscriptions';

export const getProjectMonthReportStatistic = (year: number, month: number, projectId: number): Action => ({
  type: ADD_PROJECT_MONTH_REPORT_STATISTIC,
  payload: API.getProjectMonthReportStatistic(year, month, projectId),
});

export const bulkSetVacations = (userId: number, year: number, vacations: VacationsPayload): Action => ({
  type: BULK_SET_VACATIONS,
  payload: API.bulkSetVacations(userId, year, vacations),
});

export const addOvertimeReportData = (): Action => ({
  type: ADD_OVERTIME_REPORT,
  payload: API.getOvertimeReportData(),
});
