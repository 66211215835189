// @flow
import { CHECK_FOR_NOTIFICATIONS_PENDING } from '../actions/types';
import type { Action } from '../../types';

export function loaderReducer(state: boolean = false, action: Action): boolean {
  const { type } = action;
  if (type.includes('_PENDING') && type !== CHECK_FOR_NOTIFICATIONS_PENDING) {
    return true;
  }
  if (type.includes('_FULFILLED') || type.includes('_REJECTED')) {
    return false;
  }

  return state;
}
