// @flow
import _ from 'lodash';
import {
  ADD_HOLIDAY_FULFILLED, EDIT_HOLIDAY_FULFILLED, REMOVE_HOLIDAY_FULFILLED, FETCH_HOLIDAYS_API_FULFILLED,
} from '../actions/types';
import type { ActionWithPayload, Holiday } from '../../types';

function addHoliday(holidays: Array<Holiday>, newHoliday: Holiday): Array<Holiday> {
  return [...holidays, newHoliday];
}

function editProject(holidays: Array<Holiday>, editedHoliday: Holiday): Array<Holiday> {
  return [..._.filter(holidays, h => h.id !== editedHoliday.id), editedHoliday];
}

function removeProject(holidays: Array<Holiday>, removedHolidayId: number): Array<Holiday> {
  return _.filter(holidays, h => h.id !== removedHolidayId);
}

export function holidaysReducer(state: Array<Holiday>, action: ActionWithPayload): Array<Holiday> {
  switch (action.type) {
    case ADD_HOLIDAY_FULFILLED:
      return addHoliday(state, action.payload);
    case EDIT_HOLIDAY_FULFILLED:
      return editProject(state, action.payload);
    case REMOVE_HOLIDAY_FULFILLED:
      return removeProject(state, action.payload.id);
    case FETCH_HOLIDAYS_API_FULFILLED:
      return [..._.filter(state, h => !h.date.startsWith(action.payload.year)), ...action.payload.holidays];
    default:
      return state || [];
  }
}
