// @flow
import React, { Component } from 'react';
import Input from '../../elements/Input';
import type { User } from '../../../types';

type Props = {
  currentUser: User,
  onEditUser: Function,
  onCloseButtonClick: Function,
  onFormSaved: Function,
};

type State = {
  form: {
    name: string,
    surname: string,
    email: string,
    password: string,
    passwordConfirmation: string,
  },
};

class ProfileForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: this.getFormDefaults(props.currentUser),
    };
  }

  handleUserNameChange = (e: Object) => {
    this.changeFormField('name', e.target.value);
  };

  handleUserSurnameChange = (e: Object) => {
    this.changeFormField('surname', e.target.value);
  };

  handleUserEmailChange = (e: Object) => {
    this.changeFormField('email', e.target.value);
  };

  handleUserPasswordChange = (e: Object) => {
    this.changeFormField('password', e.target.value);
  };

  handleUserPasswordConfirmationChange = (e: Object) => {
    this.changeFormField('passwordConfirmation', e.target.value);
  };

  getFormDefaults = (user: User) => ({
    name: user.name,
    surname: user.surname,
    email: user.email,
    password: '',
    passwordConfirmation: '',
  });

  changeFormField = (field: string, value: any) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  };

  handleSubmitButtonClick = () => {
    const { onEditUser, currentUser: { id } } = this.props;
    const { form } = this.state;
    const data = {
      id,
      user: {
        name: form.name,
        surname: form.surname,
        email: form.email,
      },
    };
    if (form.password !== '') {
      data.user = {
        ...data.user,
        password: form.password,
        password_confirmation: form.passwordConfirmation,
      };
    }
    onEditUser(data).then(() => {
      const {
        currentUser, onFormSaved, onCloseButtonClick,
      } = this.props;
      this.setState({
        form: this.getFormDefaults(currentUser),
      });
      onFormSaved();
      onCloseButtonClick();
    });
  };

  render() {
    const { form } = this.state;
    const { onCloseButtonClick } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <form className="">
            <Input
              id="name"
              name="name"
              type="text"
              label="Name"
              value={form.name}
              onChange={this.handleUserNameChange}
            />
            <Input
              id="surname"
              name="surname"
              type="text"
              label="Surname"
              value={form.surname}
              onChange={this.handleUserSurnameChange}
            />
            <Input
              id="email"
              name="email"
              type="email"
              label="Email"
              value={form.email}
              onChange={this.handleUserEmailChange}
            />
            <Input
              id="password"
              name="password"
              type="password"
              label="Password"
              autocompleteOff
              value={form.password}
              onChange={this.handleUserPasswordChange}
            />
            <Input
              id="password-confirmation"
              name="password-confirmation"
              type="password"
              label="Password Confirmation"
              value={form.passwordConfirmation}
              onChange={this.handleUserPasswordConfirmationChange}
            />
            <button
              type="button"
              className="btn btn-primary primary-color float-right"
              onClick={this.handleSubmitButtonClick}
            >
              Update
            </button>
            <button
              type="button"
              className="btn btn-danger float-right mr-2"
              onClick={onCloseButtonClick}
            >
              Close
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default (ProfileForm: React$ComponentType<Props>);
