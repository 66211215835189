// @flow
import React, { type Node } from 'react';

type Props = {
  children: Node,
  groupClasses?: string,
}

const FormGroupWrapper = (props: Props): Node => {
  const { children, groupClasses } = props;
  let wrapperClass = 'form-group';
  if (groupClasses) {
    wrapperClass += ` ${groupClasses}`;
  }

  return (
    <div className={wrapperClass}>
      {children}
    </div>
  );
};

export default FormGroupWrapper;
