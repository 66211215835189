// @flow
import axios from './axios';

export {
  addLog, removeLog, updateLog, requestLogs, requestDayLogs, requestDatesWithlogs,
} from './logs';

export {
  addProject, editProject, removeProject, projectSearch,
  getProjectMonthStatistic,
} from './projects';

export {
  addUser, editUser, removeUser,
} from './users';

export { getNotifications, addNotification } from './notifications';

export {
  addHoliday, editHoliday, removeHoliday, bulkSetVacations, fetchHolidaysFromApi,
} from './holidays';

export {
  updateEmailSubscriptions,
} from './email-subscriptions';

export function getInitialState(year: number, weekNum: string): Promise<any> {
  let route = '/initial_state';
  if (year && weekNum) {
    route += `/${year}/week/${weekNum}`;
  }
  route += '.json';

  return axios.get(route);
}

export function getProjectMonthReportStatistic(year: number, month: number, id: number): Promise<any> {
  return axios.get('/report/generate.json', { params: { year, months: [month], projectIds: [id] } })
    .then(response => ({ stats: response.data, year, month }));
}

export function getOvertimeReportData(): Promise<any> {
  return axios.get('/overtime_report.json').then(response => response.data);
}
