// @flow
import camelCaseKeysDeep from 'camelcase-keys-deep';
import axios from './axios';
import type { User } from '../types';

const prepareResponse = (response: any): Object => ({ ...response.data, vacations: camelCaseKeysDeep(response.data.vacations) });

export function addUser(user: User): Promise<any> {
  return axios.post('/admin/users.json', user)
    .then(prepareResponse);
}

export function editUser(user: User): Promise<any> {
  return axios.put(`/admin/users/${user.id}.json`, user)
    .then(prepareResponse);
}

export function removeUser(userId: number): Promise<any> {
  return axios.delete(`/admin/users/${userId}.json`)
    .then(() => userId);
}
