// @flow
import React from 'react';
import type { Node } from 'react';
import NavLinks from './NavLinks';
import type { User } from '../../../types';

type Props = {
  isAdmin?: boolean,
  isOwner?: boolean,
  isClient?: boolean,
  currentUser?: User,
}

const NavCollapse = (props: Props): Node => {
  const { currentUser } = props;
  let { isAdmin, isOwner, isClient } = props;

  if (currentUser) {
    isClient = currentUser.role === 'client';
    isOwner = currentUser.role === 'owner';
    isAdmin = currentUser.role === 'admin';
  }

  return (
    <div className="text-center main-nav">
      <div className="collapse navbar-collapse" id="menu">
        <ul className="navbar-nav">
          <NavLinks isAdmin={isAdmin} isOwner={isOwner} isClient={isClient} />
        </ul>
      </div>
    </div>
  );
};

export default NavCollapse;
