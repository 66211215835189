// @flow
import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL } from './types';
import type { Action, SingleModalState } from '../../types';

export const openModal = (payload: SingleModalState): Action => ({
  type: OPEN_MODAL,
  payload,
});

export const closeModal = (payload: SingleModalState): Action => ({
  type: CLOSE_MODAL,
  payload,
});

export const updateModal = (payload: SingleModalState): Action => ({
  type: UPDATE_MODAL,
  payload,
});
