// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import TableWrapper from '../TableWrapper';
import ProjectTableContent from './table/ProjectTableContent';
import ProjectTableHeader from './table/ProjectTableHeader';
import type { Project } from '../../../types';

type Props = {
  projects: Array<Project>,
  selectedYear: number,
  selectedMonth: number,
  onOpenAddProjectModal: Function,
  onOpenEditProjectModal: Function,
  onSelectMonthChange: Function,
  onSelectYearChange: Function,
  onSearchProjectsSubmit: Function,
  onSearchProjectsReset: Function,
  onRowClick: Function,
  searchTriggered: boolean,
};

type State = {
  triggeredMonth: number,
  triggeredYear: number,
};

class ProjectsContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      triggeredMonth: props.selectedMonth,
      triggeredYear: props.selectedYear,
    };
  }

  triggerSearch = () => {
    const { selectedMonth, selectedYear } = this.props;
    this.setState({
      triggeredMonth: selectedMonth,
      triggeredYear: selectedYear,
    });
  };

  render(): Node {
    const {
      projects,
      selectedMonth,
      selectedYear,
      onOpenAddProjectModal,
      onOpenEditProjectModal,
      onSelectMonthChange,
      onSelectYearChange,
      onSearchProjectsSubmit,
      onSearchProjectsReset,
      onRowClick,
    } = this.props;

    const { searchTriggered } = this.props;
    const { triggeredMonth, triggeredYear } = this.state;

    return (
      <TableWrapper
        className="small-device-project has-custom-table"
      >
        <ProjectTableHeader
          onSelectMonthChange={onSelectMonthChange}
          onSelectYearChange={onSelectYearChange}
          onSearchProjectsSubmit={onSearchProjectsSubmit}
          onSearchProjectsReset={onSearchProjectsReset}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          searchTriggered={searchTriggered}
          projects={projects}
          triggerSearch={this.triggerSearch}
          triggeredMonth={triggeredMonth}
          triggeredYear={triggeredYear}
        />
        <ProjectTableContent
          onOpenAddProjectModal={onOpenAddProjectModal}
          onOpenEditProjectModal={onOpenEditProjectModal}
          onRowClick={onRowClick}
          projects={projects}
          searchTriggered={searchTriggered}
        />
      </TableWrapper>
    );
  }
}

export default (ProjectsContent: React$ComponentType<Props>);
