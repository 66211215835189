// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { camelCase } from 'lodash';
import { subDays, format } from 'date-fns';
import ReactSelect from '../../elements/ReactSelect';
import RangedReportForm from './RangedReportForm';
import type { Project } from '../../../types';

type State = {
  form: {
    projectIds: Array<string>,
    fromDate: string,
    toDate: string,
  },
}

type Props = {
  projects: Array<Project>,
};

class RangedReportsContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        projectIds: [],
        fromDate: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
      },
    };
  }

  changeFormState = (field: string, value: any) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  };

  handleSelectProjectChange = (data: Object|null) => {
    data = data || [];
    this.changeFormState('projectIds', data.map(p => p.value));
  };

  handleInputChange = (event: Object) => {
    this.changeFormState(camelCase(event.target.name), event.target.value);
  };

  render(): Node {
    const { projects } = this.props;
    const { form } = this.state;
    const projectsForReactSelect = projects.map((p) => ({ value: `${p.id}`, label: `${p.title}` }));

    return (
      <div className="container container-header mt-4">
        <div className="upper-header-wrapper">
          <div className="left-column">
            <h2>Ranged Reports</h2>
            <hr className="float-left" />
            <p className="clearfix mb-4">Generate report based on date range:</p>
          </div>
        </div>
        <RangedReportForm fromDate={form.fromDate} toDate={form.toDate} projectId={form.projectIds}>
          <ReactSelect
            id="projectIds"
            name="projectIds[]"
            label="Projects"
            defaultValue={[]}
            options={projectsForReactSelect}
            onChange={this.handleSelectProjectChange}
          />
          <br />

          <div className="container">
            <div className="left-column row">
              <div className='float-left'>
                <label className='col' htmlFor="from-date">Start date: </label>
                <input
                  type="date"
                  id="from-date"
                  name="from_date"
                  value={form.fromDate}
                  min="2018-01-01"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className='float-left'>
                <label className='col' htmlFor="to-date">End date: </label>
                <input
                  type="date"
                  id="to-date"
                  name="to_date"
                  value={form.toDate}
                  min="2018-01-01"
                  onChange={this.handleInputChange}
                />
              </div>

            </div>
          </div>

          <br />

        </RangedReportForm>
      </div>
    );
  }
}

export default (RangedReportsContent: React$ComponentType<Props>);
