// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import NavBigScreen from './NavBigScreen';
import NavSmallScreen from './NavSmallScreen';
import NavCollapse from './NavCollapse';
import type { User } from '../../../types';

type Props = {
  navigate: Object,
  currentUser: User,
}

class MainNav extends Component<Props, any> {
  render(): Node {
    const { currentUser, navigate } = this.props;

    return (
      <>
        <NavBigScreen
          currentUser={currentUser}
          navigate={navigate}
        />
        <NavSmallScreen
          navigate={navigate}
        />
        <NavCollapse
          currentUser={currentUser}
        />
      </>
    );
  }
}

export default (MainNav: React$ComponentType<Props>);
