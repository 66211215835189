// @flow
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logsReducers } from './reducers/logs';
import { projectsReducer } from './reducers/projects';
import { usersReducer } from './reducers/users';
import { notificationsReducer } from './reducers/notifications';
import { holidaysReducer } from './reducers/holidays';
import { loaderReducer } from './reducers/loader';
import { errorReducer } from './reducers/error';
import { modalReducer } from './reducers/modal';
import { emailSubscriptionsReducer } from './reducers/email-subscriptions';
import {
  datesReducer,
  daysInWeekReducer,
  currentUserReducers,
  selectedUserReducer,
  rolesReducer,
  requestsReducer,
  projectsDropdownReducer,
  projectStatisticReducer,
  categoriesReducer,
  projectsWeeklyHoursReducers,
  vacationsReducer,
  overtimeReducer,
} from './reducers';
import type { InitialStateConfigure } from '../types';

export default (initialState: InitialStateConfigure, disableLogger: boolean): any => {
  const middleware = [thunk, createPromise()];
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (isDevelopment && !disableLogger) {
    middleware.push(logger);
  }

  const middlewareApplyer = isDevelopment ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

  return createStore(
    combineReducers({
      logs: logsReducers,
      dates: datesReducer,
      daysInWeek: daysInWeekReducer,
      users: usersReducer,
      projects: projectsReducer,
      currentUser: currentUserReducers,
      roles: rolesReducer,
      selectedUser: selectedUserReducer,
      requests: requestsReducer,
      displayLoader: loaderReducer,
      error: errorReducer,
      projectsDropdown: projectsDropdownReducer,
      projectStatistics: projectStatisticReducer,
      categories: categoriesReducer,
      notifications: notificationsReducer,
      projectsWeeklyHours: projectsWeeklyHoursReducers,
      holidays: holidaysReducer,
      modal: modalReducer,
      vacations: vacationsReducer,
      overtime: overtimeReducer,
      subscriptions: emailSubscriptionsReducer,
    }),
    initialState,
    middlewareApplyer,
  );
};
