// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  id: string,
  label: string,
  name: string,
  onChange: Function,
  checked: boolean,
  required?: boolean,
}

const Input = (props: Props): Node => {
  const {
    id, label, onChange, checked, name, required,
  } = props;

  return (
    <>
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        name={name}
        required={required}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="col-form-label">{label}</label>
    </>
  );
};

export default Input;
