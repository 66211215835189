// @flow
import React, { type Node } from 'react';

type Props = {
  children: Node,
}

const ModalFooter = (props: Props): Node => {
  const { children } = props;

  return (
    <div className="modal-footer">
      {children}
    </div>
  );
};

export default ModalFooter;
