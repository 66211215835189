// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import {
  first, keyBy, keys, values, map, uniqueId, isEmpty,
} from 'lodash';
import { displayNumber, initPopElements, removePopElements } from '../../../helpers';
import OvertimeLogsPopover from './OvertimeLogsPopover';

class OvertimeReportContent extends Component<any, any> {
  componentDidMount() {
    initPopElements();
  }

  componentWillUnmount() {
    removePopElements();
  }

  render(): Node {
    const {
      overtime, overtimeLogs, users, projects,
    } = this.props;

    return (
      <>
        <div className="container container-header mt-4">
          <div className="upper-header-wrapper">
            <div className="left-column">
              <h2>Overtime</h2>
            </div>
          </div>
        </div>
        {!isEmpty(overtimeLogs) && (
          <div className="container container-overflow">
            <div className="col-md-12">
              <table className="table mt-2">
                <thead className="thead-light">
                  <tr className="table-project-header">
                    <th>Developers</th>
                    {keys(first(values(overtime))).map((date) => {
                      const parsedDate = new Date(date);

                      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
                      const year = parsedDate.getFullYear().toString();

                      const formattedDate = `${month}/${year}`;

                      return (
                        <th key={date}>{formattedDate}</th>
                      );
                    }).reverse()}
                  </tr>
                </thead>
                <tbody>
                  {keys(users).map((userId) => ({
                    userId,
                    name: `${users[userId].name} ${users[userId].surname}`,
                  })).sort((a, b) => a.name.localeCompare(b.name)).map(({ userId, name }) => (
                    overtimeLogs[userId] && (
                    <tr key={userId}>
                      <td>
                        {name}
                      </td>
                      {map(keys(overtime[userId]), (date) => (
                        <td key={uniqueId()} className={`${overtime[userId][date] > 0 ? 'text-danger' : ''}`}>
                          <span className="mr-1">
                            {`${displayNumber(overtime[userId][date])}h`}
                          </span>
                          {overtimeLogs[userId][date] && <OvertimeLogsPopover logs={overtimeLogs[userId][date]} projects={projects} />}
                        </td>
                      )).reverse()}
                    </tr>
                    )
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  overtime: state.overtime.info,
  overtimeLogs: state.overtime.logs,
  users: keyBy(state.users, 'id'),
  projects: state.projects.projectsById,
});

export default (connect(mapStateToProps)((OvertimeReportContent: React$ComponentType<any>)): any);
