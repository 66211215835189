// @flow
import { isEmpty } from 'lodash';
import React, { type Node } from 'react';

type Props = {
  children?: Node,
  fromDate?: string,
  toDate?: string,
  projectId?: string[],
}

const RangedReportForm = (props: Props): Node => {
  const {
    children, fromDate, toDate, projectId,
  } = props;
  const formInputs = children || (
    <>
      <input name="fromDate" type="hidden" value={fromDate} />
      <input name="toDate" type="hidden" value={toDate} />
      <input name="projectIds[]" type="hidden" value={projectId} />
    </>
  );
  const setAction = (action: string) => {
    const form = document.querySelector('#ranged_reports_form');
    if (form) {
      form.setAttribute('action', `/report/generate_range.${action}`);
    }
  };

  return (
    <form id='ranged_reports_form' acceptCharset="UTF-8" method="get">
      <input name="utf8" type="hidden" value="✓" />
      {formInputs}
      <input
        type="submit"
        name="commit"
        value="Download as PDF"
        className="btn btn-default btn-primary secondary-color float-right mb-3 ml-3"
        formTarget="_blank"
        disabled={isEmpty(fromDate) || isEmpty(toDate) || isEmpty(projectId)}
        onClick={() => setAction('pdf')}
      />
      <input
        type="submit"
        name="commit"
        value="Download as XLS"
        className="btn btn-default btn-primary secondary-color float-right"
        formTarget="_blank"
        disabled={isEmpty(fromDate) || isEmpty(toDate) || isEmpty(projectId)}
        onClick={() => setAction('xlsx')}
      />
    </form>
  );
};

export default RangedReportForm;
