// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import TimesheetsView from '../components/views/Timesheets';
import type {
  Log,
  Logs,
  User,
  Project,
  State,
  RequestLogs,
  LogRequest,
  SingleModalState,
  Holiday,
} from '../types';
import {
  addLog,
  updateLog,
  removeLog,
  requestLogs,
  changeSelectedUser,
  changeDates,
  addDraggedLog,
  removeDraggedLog,
  openModal,
  closeModal,
  updateModal,
} from '../redux/actions';
import { withRouter } from '../helpers/withRouter';

type Props = {
  logs: Logs,
  currentUser: User,
  users: Array<User>,
  daysInWeek: Array<Object>,
  projects: Array<Project>,
  categories: any,
  dates: Array<string>,
  selectedUser: User,
  requests: Object,
  routeParams: Object,
  onRemoveLog: Function,
  onAddLog: Function,
  onUpdateLog: Function,
  onLogsChange: Function,
  onSelectedUserChange: Function,
  onChangeDates: Function,
  onAddDraggedLog: Function,
  onRemoveDraggedLog: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  navigate: Object,
  location: Object,
  draggedLog: Log,
  projectsWeeklyHours: Object,
  holidays: Array<Holiday>,
  vacations: Object,
  router: Object,
}

class Timesheets extends Component<Props> {
  render(): Node {
    return (
      <TimesheetsView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  logs: state.logs,
  currentUser: state.currentUser,
  users: state.users,
  daysInWeek: state.daysInWeek,
  projects: state.projectsDropdown,
  dates: state.dates,
  selectedUser: state.selectedUser,
  requests: state.requests.calendar,
  routeParams: ownProps.router.params,
  location: ownProps.router.location,
  categories: state.categories,
  draggedLog: state.logs.draggedLog,
  projectsWeeklyHours: state.projectsWeeklyHours,
  holidays: state.holidays,
  vacations: state.vacations,
});

const mapDispatchToProps = dispatch => ({
  onRemoveLog: (projectId: number, logId: number) => dispatch(removeLog(projectId, logId)),
  onAddLog: (projectId: number, log: LogRequest) => dispatch(addLog(projectId, log)),
  onUpdateLog: (projectId: number, logId: number, log: LogRequest) => dispatch(updateLog(projectId, logId, log)),
  onLogsChange: (logInfo: RequestLogs) => dispatch(requestLogs(logInfo)),
  onSelectedUserChange: (selectedUserId: number) => dispatch(changeSelectedUser(selectedUserId)),
  onChangeDates: (dateInfo: Array<string>) => dispatch(changeDates(dateInfo)),
  onAddDraggedLog: (log: Log) => dispatch(addDraggedLog(log)),
  onRemoveDraggedLog: () => dispatch(removeDraggedLog()),
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  onUpdateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
});

export default ((withRouter(connect(mapStateToProps, mapDispatchToProps)((Timesheets: React$ComponentType<Props>))): any));
