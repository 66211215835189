// @flow
import React from 'react';
import type { Node } from 'react';
import type { Project } from '../../../../types';

type Props = {
  project: Project,
  onRowClick: Function,
  onOpenEditProjectModal: Function,
  searchTriggered: boolean,
}

const ProjectRow = (props: Props): Node => {
  const {
    project, onRowClick, searchTriggered, onOpenEditProjectModal,
  } = props;
  const totalHours = project.total_hours || 0;
  const monthlyLimit = project.monthly_limit ? `${parseFloat(project.monthly_limit).toFixed(2)} h` : '/';
  const monthlyLimitMobile = project.monthly_limit
    ? <em className="device-small text-muted">{`(${monthlyLimit})`}</em>
    : <em className="device-small text-muted">(no limit)</em>;

  const onEditClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onOpenEditProjectModal(project);
  };

  return (
    <>
      {((totalHours !== 0.00 && searchTriggered) || !searchTriggered) && (
        <div className="table-row with-cursor" onClick={() => onRowClick(project)}>
          <div className="d-flex table-column">
            <h4 className="project-title p-0 m-0 align-self-center" id="project-title-1">{project.title}</h4>
          </div>
          <div className="table-column-end">
            <h4 className="project-total-hours p-0 m-0 align-self-center" id={`project-total-hours-${project.id}`}>
              {`${parseFloat(totalHours).toFixed(2)} h`}
              {monthlyLimitMobile}
            </h4>
          </div>
          <div className="table-column-end">
            <h4 className="project-monthly-limit p-0 m-0 align-self-center" id={`project-monthly-limit-${project.id}`}>{monthlyLimit}</h4>
          </div>
          <div className="table-column-end">
            <button type="button" className="btn btn-primary primary-color" onClick={onEditClick}>Edit</button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectRow;
