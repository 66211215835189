// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import MainNav from '../blocks/navigation/MainNav';
import ReportsContent from '../blocks/reports/ReportsContent';
import RangedReportsContent from '../blocks/reports/RangedReportsContent';
import TabsSwitcher from '../blocks/TabsSwitcher';
import OvertimeReportContent from '../blocks/reports/OvertimeReportContent';
import type { Project, User, Router } from '../../types';

type State = {
  activeTab: string,
};

type Props = {
  projects: Array<Project>,
  currentUser: User,
  onGetReportData: Function,
  router: Router,
};

class Reports extends Component<Props, State> {
  state = {
    activeTab: 'reports',
  };

  componentDidMount() {
    this.props.onGetReportData();
  }

  handleTabChange = (activeTab: string) => {
    this.setState({ activeTab });
  };

  render(): Node {
    const { projects, currentUser, router } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="reports-view container-view view-wrapper">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        <div className="m-5">
          <TabsSwitcher
            tabs={{ reports: 'Monthly Reports', ranged_reports: 'Ranged reports', overtime: 'Overtime' }}
            onTabCange={this.handleTabChange}
          />
        </div>
        {activeTab === 'reports' && (
          <ReportsContent
            projects={projects}
          />
        )}
        {activeTab === 'ranged_reports' && (
          <RangedReportsContent
            projects={projects}
          />
        )}
        {activeTab === 'overtime' && (
          <OvertimeReportContent />
        )}
      </div>
    );
  }
}

export default (Reports: React$ComponentType<Props>);
