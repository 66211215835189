// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import VacationModal from '../calendar/VacationModal';

type Props = {
  monthReport: { stats: {
    users: Array<Object>,
    categories: Object,
    logs: Array<Object>,
    project_total_hours: number,
    category_colors: Object,
    project_users: Array<any>,
    vacations: Array<any>,
  } },
  year: string
};

type State = {
  modalUserIdOpened: ?number,
}

class VacationTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { modalUserIdOpened: null };
  }

  openModal: ((userId: ?number) => void) = (userId: ?number) => {
    this.setState({ modalUserIdOpened: userId });
  };

  closeModal: (() => void) = () => {
    this.setState({ modalUserIdOpened: null });
  };

  render(): Node {
    const { monthReport, year } = this.props;
    const { modalUserIdOpened } = this.state;
    const userStats = _.sortBy(monthReport.stats.project_users, 'surname');
    const selectedUser = _.find(monthReport.stats.project_users, { id: modalUserIdOpened });
    const vacations = _.find(monthReport.stats.vacations, { id: selectedUser && selectedUser.id });
    const usersToDisplay = userStats.filter(user => user.active);

    return (
      <>
        <div className="col-md-6">
          <h4>Vacations for the current year</h4>
          <table className="table mt-2">
            <thead className="thead-light">
              <tr className="table-project-header">
                <th>Developers on project</th>
                <th className="text-center">Calendar</th>
              </tr>
            </thead>
            <tbody>
              {usersToDisplay.map(user => (
                <tr key={user.id}>
                  <td>
                    {`${user.name} ${user.surname}`}
                  </td>
                  <td className="text-center user-name-vacations" key={user.id}>
                    <i className="fa fa-calendar-check-o" aria-hidden="true" onClick={() => this.openModal(user.id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        { selectedUser && (
          <VacationModal vacations={vacations} year={year} onCloseModal={this.closeModal} userId={modalUserIdOpened} opened={!!modalUserIdOpened} />
        )}
      </>
    );
  }
}

export default (VacationTable: React$ComponentType<Props>);
