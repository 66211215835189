// @flow
import React from 'react';
import type { Node } from 'react';
import NavLink from './NavLink';
import NavLogoutLink from './NavLogoutLink';

type Props = {
  isAdmin?: boolean,
  isOwner?: boolean,
  isClient?: boolean,
}

const NavLinks = (props: Props): Node => {
  const { isAdmin, isOwner, isClient } = props;

  return (
    <>
      {!isClient && <NavLink label="Calendar" link="/" />}
      {isOwner || isAdmin ? <NavLink label="Projects" link="/projects" /> : null}
      {isOwner || isAdmin ? <NavLink label="Reports" link="/reports" /> : null}
      {isOwner || isAdmin ? <NavLink label="Users" link="/admin/users" /> : null}
      {isOwner || isAdmin ? <NavLink label="Notifications" link="/notifications" /> : null}
      {isOwner || isAdmin ? <NavLink label="Dates" link="/date-management" /> : null}
      {isOwner || isAdmin ? <NavLink label="Subscriptions" link="/email-subscriptions" /> : null}
      {isClient && <NavLink label="Dashboard" link="/client" />}
      <NavLink label="My profile" link="/profile" />
      <NavLogoutLink />
    </>
  );
};

export default NavLinks;
