// @flow
import _ from 'lodash';
import {
  ADD_USER_FULFILLED,
  EDIT_USER_FULFILLED,
  REMOVE_USER_FULFILLED,
} from '../actions/types';
import type { ActionWithPayload, User } from '../../types';

function removeUser(users: Array<User>, removedUserId: number) {
  return _.filter(users, user => user.id !== removedUserId);
}

function addUser(users: Array<User>, newUser: User) {
  return _.concat(users, newUser);
}

function editUser(users: Array<User>, editedUser: User) {
  return _.concat(removeUser(users, editedUser.id), editedUser);
}

export function usersReducer(state: Array<User> = [], action: ActionWithPayload): Array<User> {
  switch (action.type) {
    case ADD_USER_FULFILLED:
      return addUser(state, action.payload.user);
    case EDIT_USER_FULFILLED:
      return editUser(state, action.payload.user);
    case REMOVE_USER_FULFILLED:
      return removeUser(state, action.payload);
    default:
      return state;
  }
}
