// @flow
/* eslint camelcase: ["off"] */
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import ReactSelect from '../../elements/ReactSelect';
import Input from '../../elements/Input';
import Checkbox from '../../elements/Checkbox';
import Select from '../../elements/Select';
import {
  getUserRolesForSelect, isEmailValid, projectsAsReactSelectData, getVacationYearsForSelect,
} from '../../../helpers';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import ModalControls from '../modal/ModalControls';
import type { User, Project, SingleUserVacationState } from '../../../types';

type State = {
  form: {
    name: string,
    surname: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    role: string,
    projectIds: Array<number|string>,
    vacationDays: number,
    vacationYear: number,
    vacationDaysNextYear: number,
    active: boolean,
    student: boolean,
  }
};

type Props = {
  onUserSubmit: Function,
  onUserDelete: Function,
  onCloseModal: Function,
  saveButtonText: string,
  title: string,
  edit: boolean,
  disableButtons?: boolean,
  roles: Array<any>,
  user: ?User,
  projects?: Array<Project>,
  userProjects?: Array<Project>,
  vacations: SingleUserVacationState | {},
};

class UsersModal extends Component<Props, State> {
  static defaultProps = {
    disableButtons: false,
  };

  state = {
    form: {
      name: '',
      surname: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      role: 'member',
      vacationYear: new Date().getFullYear(),
      projectIds: [],
      vacationDays: 0,
      vacationDaysNextYear: 0,
      active: false,
      student: false,
    },
  };

  componentDidUpdate = (prevProps: Props) => {
    const {
      edit, user, title, userProjects, vacations,
    } = this.props;
    if (prevProps.edit !== edit || prevProps.title !== title) {
      if (edit && user) {
        const {
          name, surname, email, role, active, student,
        } = user;
        const projectIds = userProjects && userProjects.length > 0
          ? userProjects.map(p => p.id) : [];
        this.setState({
          form: {
            name,
            surname,
            email,
            password: '',
            passwordConfirmation: '',
            role,
            projectIds,
            vacationYear: new Date().getFullYear(),
            vacationDays: _.get(vacations, `allotedDays.${new Date().getFullYear()}`, 0),
            vacationDaysNextYear: _.get(vacations, `allotedDays.${(new Date().getFullYear()) + 1}`, 0),
            active,
            student,
          },
        });
      } else {
        this.setState({
          form: {
            name: '',
            surname: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            role: 'member',
            projectIds: [],
            vacationYear: new Date().getFullYear(),
            vacationDays: 0,
            vacationDaysNextYear: 0,
            active: false,
            student: false,
          },
        });
      }
    }
  };

  changeFormField = (field: string, value: any) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  };

  handleUserNameChange = (event: Object) => {
    this.changeFormField('name', event.target.value);
  };

  handleUserSurnameChange = (event: Object) => {
    this.changeFormField('surname', event.target.value);
  };

  handleUserEmailChange = (event: Object) => {
    this.changeFormField('email', event.target.value);
  };

  handleUserPasswordChange = (event: Object) => {
    this.changeFormField('password', event.target.value);
  };

  handleUserPasswordConfirmationChange = (event: Object) => {
    this.changeFormField('passwordConfirmation', event.target.value);
  };

  handleUserRoleChange = (event: Object) => {
    this.changeFormField('role', event.target.value);
  };

  handleYearChange = (event: Object) => {
    const value = parseInt(event.target.value, 10);
    this.changeFormField('vacationYear', value);
  };

  handleActiveChange = (event: Object) => {
    this.changeFormField('active', event.target.checked);
  };

  handleStudentChange = (event: Object) => {
    this.changeFormField('student', event.target.checked);
  };

  handleAddProjectToUser = (data: Array<{ value: number }>) => {
    const { form } = this.state;
    data = data || [];
    this.setState({
      form: {
        ...form,
        projectIds: data.map(p => p.value),
      },
    });
  };

  handleVacationDaysChange = (event: Object) => {
    this.changeFormField('vacationDays', event.target.value);
  };

  handleVacationDaysNextYearChange = (event: Object) => {
    this.changeFormField('vacationDaysNextYear', event.target.value);
  };

  isFormValid = () => {
    const { roles, edit } = this.props;
    const {
      form:
      {
        name, surname, email,
        password, passwordConfirmation,
        role, projectIds,
      },
    } = this.state;

    return name !== '' && surname !== '' && isEmailValid(email) && _.includes(roles.map(r => r[0]), role)
      && (edit || (password !== '' && password === passwordConfirmation)) && !_.isEmpty(projectIds);
  };

  handleSubmit = () => {
    const { onUserSubmit, edit, user } = this.props;
    const {
      form: {
        name, surname, email, password, passwordConfirmation, role, projectIds, active, student, vacationDays, vacationDaysNextYear,
      },
    } = this.state;
    const currentYear = new Date().getFullYear();
    const userData = {
      name,
      surname,
      email,
      role,
      active,
      student,
      project_ids: [],
      total_vacation_days: [{
        year: currentYear,
        count: vacationDays,
      },
      {
        year: currentYear + 1,
        count: vacationDaysNextYear,
      }],
    };
    userData.project_ids = projectIds;
    if (edit && user) {
      onUserSubmit({
        id: user.id,
        user: userData,
      });
    } else {
      onUserSubmit({
        user: {
          ...userData,
          password,
          password_confirmation: passwordConfirmation,
        },
      });
    }
  };

  handelDeleteClick = () => {
    const { user, onUserDelete } = this.props;
    if (user) {
      onUserDelete(user.id);
    }
  };

  render(): Node {
    const {
      saveButtonText, title, edit, disableButtons, roles, onCloseModal,
      projects, userProjects,
    } = this.props;
    const { form } = this.state;
    const selectRoles = getUserRolesForSelect(roles);
    const userProjectsData = userProjects ? projectsAsReactSelectData(userProjects) : [];
    const projectsData = projects ? projectsAsReactSelectData(projects) : [];
    const selectedProjects = _.filter(projectsData, (el) => form.projectIds.includes(el.value));

    return (
      <>
        <ModalHeader
          title={title}
          onCloseClick={onCloseModal}
        />
        <ModalBody>
          <form>
            <Input
              id="name"
              name="name"
              type="text"
              label="Name"
              value={form.name}
              onChange={this.handleUserNameChange}
            />
            <Input
              id="surname"
              name="surname"
              type="text"
              label="Surname"
              value={form.surname}
              onChange={this.handleUserSurnameChange}
            />
            <Input
              id="email"
              name="email"
              type="email"
              label="Email"
              value={form.email}
              onChange={this.handleUserEmailChange}
            />
            {!edit ? (
              <Input
                id="password"
                name="password"
                type="password"
                label="Password"
                value={form.password}
                onChange={this.handleUserPasswordChange}
              />
            ) : null}
            {!edit ? (
              <Input
                id="password-confirmation"
                name="password-confirmation"
                type="password"
                label="Password Confirmation"
                value={form.passwordConfirmation}
                onChange={this.handleUserPasswordConfirmationChange}
              />
            ) : null}
            <Select
              groupClass="user-role"
              name="role"
              id="role"
              selectClass="form-control select-role"
              label="Role"
              items={selectRoles}
              value={form.role}
              onSelectChange={this.handleUserRoleChange}
            />
            <ReactSelect
              id="project_ids"
              name="project_ids"
              label="Assigned projects"
              defaultValue={userProjectsData}
              options={projectsData}
              onChange={this.handleAddProjectToUser}
              value={selectedProjects}
            />
            <Select
              groupClass="year"
              name="year"
              id="year"
              selectClass="form-control select-year"
              label="Year"
              items={getVacationYearsForSelect()}
              value={form.vacationYear}
              onSelectChange={this.handleYearChange}
            />
            {form.vacationYear === (new Date().getFullYear()) ? (
              <Input
                id="vacationDays"
                type="number"
                name="vacationDays"
                label="Vacation Days (for current year)"
                required
                value={form.vacationDays}
                onChange={this.handleVacationDaysChange}
              />
            ) : (
              <Input
                id="vacationDaysNextYear"
                type="number"
                name="vacationDaysNextYear"
                label="Vacation Days (for next year)"
                required
                value={form.vacationDaysNextYear}
                onChange={this.handleVacationDaysNextYearChange}
              />
            )}
            <div className="form-check">
              <Checkbox
                name="active"
                label="Is active?"
                id="active"
                checked={form.active}
                onChange={this.handleActiveChange}
              />
            </div>
            <div className="form-check">
              <Checkbox
                name="student"
                label="Is student?"
                id="student"
                checked={form.student}
                onChange={this.handleStudentChange}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <ModalControls
            showEditButton={edit}
            disableButtons={disableButtons || false}
            isFormValid={!this.isFormValid()}
            onSubmitButtonClick={this.handleSubmit}
            onDeleteButtonClick={this.handelDeleteClick}
            onCloseButtonClick={onCloseModal}
            submitButtonText={saveButtonText}
            deleteButtonText="Delete user"
          />
        </ModalFooter>
      </>
    );
  }
}

export default (UsersModal: React$ComponentType<Props>);
