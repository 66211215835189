// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  title: string,
  onCloseClick: Function,
}

const ModalHeader = (props: Props): Node => {
  const { title, onCloseClick } = props;

  return (
    <div className="modal-header">
      <h5 className="modal-title">{title}</h5>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onCloseClick}
      >
        <span aria-hidden="true">{'\u00d7'}</span>
      </button>
    </div>
  );
};

export default ModalHeader;
