// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import ReportsView from '../components/views/Reports';
import { addOvertimeReportData } from '../redux/actions';
import { withRouter } from '../helpers/withRouter';
import type {
  State, User, Project, Router,
} from '../types';

type Props = {
  projects: Array<Project>,
  currentUser: User,
  navigate: Object,
  onGetReportData: Function,
  router: Router,
};

class Reports extends Component<Props> {
  render(): Node {
    return (
      <ReportsView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  projects: state.projectsDropdown,
  currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
  onGetReportData: () => dispatch(addOvertimeReportData()),
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)((Reports: React$ComponentType<Props>))): any);
