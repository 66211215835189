// @flow
import React, { Component, Fragment } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import camelCaseKeysDeep from 'camelcase-keys-deep';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import Timesheets from '../containers/Timesheets';
import Projects from '../containers/Projects';
import Reports from '../containers/Reports';
import Users from '../containers/Users';
import DateManagement from '../containers/DateManagement';
import ClientDashboard from '../containers/ClientDashboard';
import Notifications from '../containers/Notifications';
import Profile from '../containers/Profile';
import YearCalendar from '../containers/YearCalendar';
import EmailSubscriptions from '../containers/EmailSubscriptions';
import Loader from './elements/Loader';
import StaticLoader from './elements/StaticLoader';
import Error from './elements/Error';
import configureStore from '../redux/configureStore';
import {
  getNormalizedData,
  getCSRFToken,
  isUserAdmin,
  isUserOwner,
  isUserClient,
  getUsersProjects,
  mapEnumJsontoArray,
  arrangeById,
  isUserMember,
} from '../helpers';
import { getNavToday } from '../helpers/dates';
import { getInitialState } from '../api';
import type { InitialState } from '../types';

type Props = {
  disableLogger: boolean,
};
type State = {
  initialStoreState: ?InitialState,
};

class App extends Component<Props, State> {
  state: State = {
    initialStoreState: null,
  };

  componentDidMount() {
    const { pathname } = window.location;
    let year = 0;
    let weekNum = '';
    if (_.includes(pathname, 'week')) {
      const params = _.words(pathname);
      weekNum = _.last(params);
      year = _.first(params);
    } else {
      const today = getNavToday();
      weekNum = _.toString(today.week);
      year = today.year;
    }
    const { initialStoreState } = this.state;
    if (_.isNull(initialStoreState)) {
      getInitialState(year, weekNum)
        .then(({ data }) => {
          this.setState({
            initialStoreState: {
              dates: data.dates,
              projects: data.projects,
              roles: data.roles,
              users: data.user_list,
              daysInWeek: data.days_in_week,
              currentUser: {
                ...data.current_user,
                projects: getUsersProjects(data.current_user, data.projects),
              },
              projectsDropdown: data.projects_dropdown,
              categories: mapEnumJsontoArray(data.categories),
              projectStatistics: data.projects_stats,
              notifications: [],
              notificationsCount: 0,
              projectsWeeklyHours: data.projects_weekly_hours,
              holidays: data.holidays,
              vacations: camelCaseKeysDeep(arrangeById(data.vacations)),
              overtime: data.overtime,
              subscriptions: camelCaseKeysDeep(data.subscriptions),
            },
          });
        });
    }
    this.resize(document.querySelector('.view-wrapper'));
    window.addEventListener('resize', this.handleResize);
  }

  handleResize: (() => void) = () => {
    const wrapper = document.querySelector('.view-wrapper');
    this.resize(wrapper);
  };

  resize(el: any) {
    if (el) {
      const adjustedWindowHeight = window.innerHeight * 0.01;
      el.style.setProperty('--vh', `${adjustedWindowHeight}px`);
    }
  }

  render(): Node {
    const { initialStoreState } = this.state;
    const { disableLogger } = this.props;
    if (!initialStoreState) {
      return <StaticLoader display />;
    }

    const store = configureStore(getNormalizedData(initialStoreState, getCSRFToken(), window.location.pathname), disableLogger);
    const isAdmin = isUserAdmin(initialStoreState.currentUser);
    const isOwner = isUserOwner(initialStoreState.currentUser);
    const isClient = isUserClient(initialStoreState.currentUser);
    const isMember = isUserMember(initialStoreState.currentUser);

    const ClientApp = (
      <Routes>
        <Route path="/profile" element={<Profile />} />
        <Route path="/client" element={<ClientDashboard />} />
        <Route path='*' element={<Navigate to="/client" />} />
      </Routes>
    );

    const GeneralApp = (
      <Routes>
        {isAdmin || isOwner || isMember ? <Route path="/projects/:id" element={<ClientDashboard />} /> : null}
        {isAdmin || isOwner ? <Route path="/projects" element={<Projects />} /> : null}
        {isAdmin || isOwner ? <Route path="/admin/users" element={<Users />} /> : null}
        {isAdmin || isOwner ? <Route path="/notifications" element={<Notifications />} /> : null}
        {isAdmin || isOwner ? <Route path="/date-management" element={<DateManagement />} /> : null}
        {isAdmin || isOwner ? <Route path="/reports" element={<Reports />} /> : null}
        {isAdmin || isOwner ? <Route path="/email-subscriptions" element={<EmailSubscriptions />} /> : null}
        <Route path="/:year/week/:weekNum" element={<Timesheets />} />
        <Route path="/year-calendar/:date" element={<YearCalendar />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/year-calendar" element={<YearCalendar />} />
        <Route path="/" element={<Timesheets />} />
      </Routes>
    );

    return (
      <>
        <Loader store={store} />
        <Error store={store} />
        <Provider store={store}>
          <BrowserRouter>
            {isClient ? ClientApp : GeneralApp}
          </BrowserRouter>
        </Provider>
      </>
    );
  }
}

export default App;
