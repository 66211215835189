// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import ReduxModal from './ReduxModal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalControls from './ModalControls';
import ModalFooter from './ModalFooter';
import { CONFIRM_MODAL } from '../../../redux/reducers/modal';
import { openModal, closeModal } from '../../../redux/actions';
import type { SingleModalState } from '../../../types';

type Props = {
  confirmationDescription: string,
  onOpenModal: Function,
  onCloseModal: Function,
  onConfirm: Function,
};

class ConfirmModal extends Component<Props> {
  static defaultProps = {
    confirmationDescription: 'Are you sure?',
  };

  modalId = { id: CONFIRM_MODAL };

  handleConfirm = () => {
    const { onConfirm, onCloseModal } = this.props;
    onCloseModal(this.modalId);
    onConfirm();
  };

  render(): Node {
    const {
      confirmationDescription, onOpenModal, onCloseModal,
    } = this.props;
    const { modalId } = this;

    return (
      <>
        <button
          className="btn btn-primary primary-color"
          type="button"
          onClick={() => onOpenModal(modalId)}
        >
          Fetch holidays from API
        </button>
        <ReduxModal id={CONFIRM_MODAL}>
          <ModalHeader title="Confirm action" onCloseClick={() => onCloseModal(modalId)} />
          <ModalBody>
            {confirmationDescription}
          </ModalBody>
          <ModalFooter>
            <ModalControls
              showEditButton={false}
              disableButtons={false}
              onCloseButtonClick={() => onCloseModal(modalId)}
              onSubmitButtonClick={this.handleConfirm}
              submitButtonText="Yes"
            />
          </ModalFooter>
        </ReduxModal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
});

export default (connect(null, mapDispatchToProps)((ConfirmModal: React$ComponentType<Props>)): any);
