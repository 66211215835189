// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import {
  XYPlot, XAxis, YAxis, VerticalBarSeries,
  HorizontalGridLines, VerticalGridLines,
  LineSeries, DiscreteColorLegend, Hint,
} from 'react-vis';
import { PieChart } from 'react-minimal-pie-chart';
import _ from 'lodash';
import {
  getMonthsForSelect,
  getYearsForSelect,
  isUserClient,
  isOwnerOrAdmin,
  displayNumber,
  isUserMember,
  getMemberRelevantProjects,
} from '../../../helpers';
import Select from '../../elements/Select';
import Alert from '../../elements/Alert';
import ReportTable from './ReportTable';
import TabsSwitcher from '../TabsSwitcher';
import BreakdownTable from './BreakdownTable';
import VacationTable from './VacationTable';
import ReportForm from '../reports/ReportForm';
import type { User, Project, ProjectStatistics } from '../../../types';

declare var $: any;

type Props = {
  projects: Array<Project>,
  projectStatistics: ProjectStatistics,
  onGetProjectMonthStatistic: Function,
  onGetProjectMonthReportStatistic: Function,
  preSelectedProjectId: number,
  currentUser: User,
  router: Object,
};

type State = {
  selectedMonth: number,
  selectedYear: number,
  selectedProject: number,
  monthStatistics: any,
  selectionOnDisplay: string,
  monthlyLimit: number,
  hoveredBar: any,
  monthlyLimitHint: any,
  selectedProjectObject: ?Project,
  showTaskId: boolean
};

class ClientDashboardContent extends Component<Props, State> {
  originalSearch: string;

  constructor(props: Props) {
    super(props);
    const { projects } = this.props;
    const { location: { search } } = this.props.router;
    const monthIndex = _.words(search).indexOf('month');
    const yearIndex = _.words(search).indexOf('year');
    const projectIndex = _.words(search).indexOf('project');
    const month = monthIndex >= 0 ? _.words(search)[monthIndex + 1] : (new Date().getMonth() + 1);
    const year = yearIndex >= 0 ? _.words(search)[yearIndex + 1] : new Date().getFullYear();
    const preSelectedProjectId = _.toInteger(props.preSelectedProjectId);
    const selectedProjectId: number = projectIndex >= 0 ? _.words(search)[projectIndex + 1] : (preSelectedProjectId || _.toInteger(projects[0].id));
    this.state = {
      selectedMonth: month,
      selectedYear: year,
      selectedProject: selectedProjectId,
      monthStatistics: null,
      selectionOnDisplay: `${selectedProjectId}-${month}-${year}`,
      monthlyLimit: 0,
      hoveredBar: null,
      monthlyLimitHint: null,
      selectedProjectObject: this.getSelectedProject(selectedProjectId),
      showTaskId: this.showTaskId(projects, selectedProjectId),
    };
    this.originalSearch = search;
  }

  componentDidMount() {
    const { projectStatistics, onGetProjectMonthReportStatistic } = this.props;
    const {
      selectionOnDisplay, selectedProjectObject, selectedMonth, selectedYear, selectedProject,
    } = this.state;
    const monthlyLimit = (selectedProjectObject && selectedProjectObject.monthly_limit) || 0;
    this.setState({
      monthStatistics: projectStatistics[selectionOnDisplay],
      monthlyLimit,
    });
    this.handleSearchFormSubmit();
    onGetProjectMonthReportStatistic(selectedYear, selectedMonth, selectedProject);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      projectStatistics,
      onGetProjectMonthReportStatistic,
      router: { location, params },
      projects,
    } = this.props;
    const {
      selectionOnDisplay,
      selectedProject,
      selectedYear,
      selectedMonth,
    } = this.state;
    const searchChanged = prevProps.router.location.search !== location.search || prevProps.router.params.id !== params.id;

    if (selectionOnDisplay !== '' && prevState.selectionOnDisplay !== selectionOnDisplay) {
      const project = this.getSelectedProject(selectedProject);
      const monthlyLimit = (project && project.monthly_limit) || 0;
      this.setState({
        monthStatistics: projectStatistics[selectionOnDisplay],
        selectedProjectObject: project,
        monthlyLimit,
      });
    }

    if (searchChanged) {
      this.handleSearchFormSubmit();
      this.resetTabs();
      onGetProjectMonthReportStatistic(selectedYear, selectedMonth, selectedProject);
      this.setState({
        showTaskId: this.showTaskId(projects, selectedProject),
      });
    }
  }

  handleSelectMonthChange = (event: Object) => {
    this.setState({ selectedMonth: event.target.value });
  };

  handleSelectYearChange = (event: Object) => {
    this.setState({ selectedYear: event.target.value });
  };

  handleSelectProjectChange = (event: Object) => {
    this.setState({ selectedProject: event.target.value });
  };

  handleSearchFormSubmit = () => {
    const { onGetProjectMonthStatistic } = this.props;
    const { selectedMonth, selectedYear, selectedProject } = this.state;
    this.setState({
      selectionOnDisplay: '',
    });
    onGetProjectMonthStatistic(selectedYear, selectedMonth, selectedProject).then(() => {
      this.setState({
        selectionOnDisplay: `${selectedProject}-${selectedMonth}-${selectedYear}`,
      });
    });
  };

  handleApplyClick = () => {
    const { currentUser } = this.props;
    const { navigate, location } = this.props.router;
    const { selectedProject, selectedYear, selectedMonth } = this.state;
    if (!isUserClient(currentUser)) {
      navigate(`/projects/${selectedProject}?month=${selectedMonth}&year=${selectedYear}`);
    } else {
      navigate(`${location.pathname}?project=${selectedProject}&month=${selectedMonth}&year=${selectedYear}`);
    }
  };

  getParentWidth = (elId: string) => $(elId).width();

  handleTabChange = (tab: string) => {
    $('.vacation-row').hide();
    $('.summary-row').hide();
    $('.breakdown-row').hide();
    $(`.${tab}-row`).show();
  };

  resetTabs = () => {
    $('.summary-row').show();
    $('.breakdown-row').hide();
    $('.vacation-row').hide();
  };

  getSelectedProject = (selectedProjectId: number) => {
    const { projects } = this.props;

    return projects.find(p => p.id === _.toInteger(selectedProjectId));
  };

  getMonthlyTotal = (data: Array<any>) => _.maxBy(data, 'y').y;

  getYMaxiumum = (data: Array<any>) => {
    const { monthlyLimit } = this.state;
    const max = this.getMonthlyTotal(data);

    return (monthlyLimit > max ? monthlyLimit : max) + 10;
  };

  getRadialData = (data: Array<any>) => {
    const test = _.sortBy(_.map(_.keys(data), (type) => ({
      value: data[type],
      title: _.startCase(type) + ` (${data[type]}h - ${Math.round((data[type] / _.sum(_.values(data))) * 100) + '%'})`,
      color: this.legendColors()[type],
      type,
    })), 'value');

    return test;
  };

  handleBackToProjectsClick = () => {
    const { navigate } = this.props.router;
    if (this.originalSearch) {
      navigate(`/projects${this.originalSearch}`);
    } else {
      navigate(-1);
    }
  };

  legendColors = () => {
    const { projectStatistics } = this.props;
    if (projectStatistics && projectStatistics.monthReport && projectStatistics.monthReport.stats) {
      return projectStatistics.monthReport.stats.category_colors;
    }

    return [];
  };

  showTaskId = (projects, selectedProjectId) => _.get(_.keyBy(projects, 'id')[selectedProjectId], 'show_task_id');

  render(): Node {
    const { projects, projectStatistics, currentUser } = this.props;
    const {
      selectedYear, selectedMonth, selectedProject, monthStatistics,
      monthlyLimit, hoveredBar, selectedProjectObject, selectionOnDisplay,
      monthlyLimitHint, showTaskId,
    } = this.state;
    const isMember = isUserMember(currentUser);
    const currentMonth = (new Date().getMonth() + 1);
    const currentYear = new Date().getFullYear();
    const selectMonths = getMonthsForSelect();
    const selectYears = getYearsForSelect();
    const monthlyLegends = [
      { title: 'Day-by-day incremental hours total', color: 'lightblue' },
    ];
    if (monthlyLimit && monthlyLimit > 0) {
      monthlyLegends.push({ title: `Monthly limit - ${displayNumber(monthlyLimit)}h`, color: 'orange' });
    }

    const limitData = [{ x: 1, y: monthlyLimit }, { x: monthStatistics && monthStatistics.monthlyView.length, y: monthlyLimit }];
    const showChart = monthStatistics && monthStatistics.monthlyView && _.sumBy(monthStatistics.monthlyView, 'y') > 0;
    const showRadial = monthStatistics && !_.isEmpty(monthStatistics.radialView) && _.keys(monthStatistics.radialView).length > 1;
    let projectsToSelect = [...projects];
    if (isMember) projectsToSelect = getMemberRelevantProjects(projectsToSelect, currentUser);
    let tabs = { summary: 'Summary', breakdown: 'Breakdown' };
    if (!isMember) tabs = { ...tabs, vacation: 'Vacations' };

    return (
      <>
        <div className="container container-header mt-4">
          <div className="upper-header-wrapper">
            <div className="left-column">
              <h2>Project Dashboard</h2>
              <hr className="float-left" />
              <p className="clearfix mb-4">
                {!isUserClient(currentUser) && (
                  <button type="button" className="btn btn-link pl-0" onClick={this.handleBackToProjectsClick}>
                    <i className="fa fa-arrow-left mr-2" />
                    {isMember ? 'Back' : 'Back to Projects'}
                  </button>
                )}
                {!isUserClient(currentUser) && <br />}
                Monthly statistics for project:&nbsp;
                {selectionOnDisplay && selectedProjectObject && <b>{selectedProjectObject.title}</b>}
              </p>
            </div>
            <div className="right-column">
              <div className="selects-wrapper">
                <Select
                  groupClass="month js-month"
                  selectClass="form-control select-project"
                  labelClass=""
                  name="month"
                  label="Select project:"
                  id="month"
                  items={projectsToSelect}
                  value={selectedProject}
                  onSelectChange={this.handleSelectProjectChange}
                />
                <Select
                  groupClass="month js-month"
                  selectClass="form-control select-month"
                  labelClass=""
                  name="month"
                  label="Select month:"
                  id="month"
                  items={selectMonths}
                  value={selectedMonth || currentMonth}
                  onSelectChange={this.handleSelectMonthChange}
                />
                <Select
                  groupClass="year js-year"
                  selectClass="form-control select-year"
                  labelClass=""
                  name="year"
                  label="Select year:"
                  id="year"
                  items={selectYears}
                  value={selectedYear || currentYear}
                  onSelectChange={this.handleSelectYearChange}
                />
              </div>
              <div className="buttons-wrapper">
                <button
                  className="btn btn-primary search js-filter-projects"
                  type="button"
                  onClick={this.handleApplyClick}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>

        {selectionOnDisplay && (
          <TabsSwitcher
            tabs={tabs}
            onTabCange={this.handleTabChange}
          />
        )}

        {isOwnerOrAdmin(currentUser) && showChart && (
          <div className="container">
            <div className="row">
              <div className="col-12 mt-3 download-report">
                <ReportForm year={selectedYear} months={[selectedMonth.toString()]} projectId={[selectedProject.toString()]} />
              </div>
            </div>
          </div>
        )}

        <div className="container mt-3">
          <div className="row summary-row">

            {!showChart && (
              <div className="col-12">
                <Alert display message="No statistics available." />
              </div>
            )}
            <div className="col-md-4 report-tables p-0">
              <div className="col-12">
                {showChart && selectedProjectObject && (
                  <ReportTable
                    project={selectedProjectObject}
                    statistics={monthStatistics.radialView}
                    totalSum={this.getMonthlyTotal(monthStatistics.monthlyView)}
                    colors={this.legendColors()}
                  />
                )}
              </div>
              <div className="col-12 mt-5">
                {showRadial && (
                <PieChart
                  animate={false}
                  data={this.getRadialData(monthStatistics.radialView)}
                  lineWidth={30}
                  label={(labelRenderProps) => {
                    const { dataEntry, dataIndex } = labelRenderProps;
                    let { dy } = labelRenderProps;
                    if (dataEntry.degrees < 8) {
                      if (dataIndex === 0) dy -= 7;
                      if (dataIndex === 2) dy += 7;
                    }

                    return (
                      <text
                        key={dataIndex}
                        {..._.omit(labelRenderProps, 'dataIndex', 'dataEntry')}
                        dy={dy}
                        fill={dataEntry.color}
                      >
                        {Math.round(dataEntry.percentage) + '%'}
                      </text>
                    );
                  }}
                  labelPosition={55}
                  labelStyle={{ fontSize: '6px' }}
                />
                )}
              </div>

            </div>
            <div className="col-lg-8 p-0" id="chart-parent">
              {showChart && (
                <XYPlot
                  width={this.getParentWidth('#chart-parent')}
                  height={600}
                  yDomain={[0, this.getYMaxiumum(monthStatistics.monthlyView)]}
                  onMouseEnter={() => {
                    if (monthlyLimit > 0) {
                      this.setState({ monthlyLimitHint: { x: 1, y: (monthlyLimit - 0.3) } });
                    }
                  }}
                  onMouseLeave={() => this.setState({ monthlyLimitHint: null })}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <VerticalBarSeries
                    color="lightblue"
                    data={monthStatistics.monthlyView}
                    onValueMouseOver={bar => this.setState({ hoveredBar: bar })}
                    onValueMouseOut={() => this.setState({ hoveredBar: null })}
                  />
                  <XAxis tickTotal={monthStatistics.monthlyView.length} />
                  <YAxis />
                  {monthlyLimit > 0 && <LineSeries color="orange" data={limitData} strokeWidth={2} strokeStyle="dashed" />}
                  <DiscreteColorLegend height={200} width={300} items={monthlyLegends} />
                  {hoveredBar && (
                    <Hint value={hoveredBar}>
                      <span className="badge badge-secondary hint-badge">
                        {displayNumber(hoveredBar.y)}
                      </span>
                    </Hint>
                  )}
                  {monthlyLimitHint && (
                    <Hint value={monthlyLimitHint}>
                      <span className="badge badge-secondary hint-badge monthly-limit">
                        {displayNumber(monthlyLimit)}
                      </span>
                    </Hint>
                  )}
                </XYPlot>
              )}
            </div>
          </div>
          <div className="row breakdown-row" style={{ display: 'none' }}>
            {selectionOnDisplay && projectStatistics && projectStatistics.monthReport && (
              <BreakdownTable
                monthReport={projectStatistics.monthReport}
                showTaskId={showTaskId}
              />
            )}
          </div>
          <div className="row vacation-row" style={{ display: 'none' }}>
            {selectionOnDisplay && projectStatistics && projectStatistics.monthReport && (
              <VacationTable monthReport={projectStatistics.monthReport} year={_.toInteger(selectedYear)} />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default (ClientDashboardContent: React$ComponentType<Props>);
