// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  onOpenAddModal: Function,
  id?: string,
  small?: boolean,
}

const AddLogButton = (props: Props): Node => {
  const { id, small } = props;
  let classes = 'btn add-log';
  if (small) {
    classes += ' btn-circle btn-lg';
  }

  return (
    <button
      type="button"
      className={classes}
      id={id}
      onClick={() => props.onOpenAddModal(id)}
    >
      <i className="fa fa-plus" aria-hidden="true" />
    </button>
  );
};

export default AddLogButton;
