// @flow
export const ADD_LOG = 'ADD_LOG';
export const ADD_LOG_FULFILLED = 'ADD_LOG_FULFILLED';
export const UPDATE_LOG = 'UPDATE_LOG';
export const UPDATE_LOG_FULFILLED = 'UPDATE_LOG_FULFILLED';
export const REMOVE_LOG = 'REMOVE_LOG';
export const REMOVE_LOG_FULFILLED = 'REMOVE_LOG_FULFILLED';
export const ADD_DRAGGED_LOG = 'ADD_DRAGGED_LOG';
export const REMOVE_DRAGGED_LOG = 'REMOVE_DRAGGED_LOG';
export const CHANGE_DATES = 'CHANGE_DATES';
export const REQUEST_LOGS = 'REQUEST_LOGS';
export const REQUEST_LOGS_FULFILLED = 'REQUEST_LOGS_FULFILLED';
export const REQUEST_DAY_LOGS = 'REQUEST_DAY_LOGS';
export const REQUEST_DAY_LOGS_FULFILLED = 'REQUEST_DAY_LOGS_FULFILLED';
export const REQUEST_DATES_WITH_LOGS = 'REQUEST_DATES_WITH_LOGS';
export const REQUEST_DATES_WITH_LOGS_FULFILLED = 'REQUEST_DATES_WITH_LOGS_FULFILLED';
export const CHANGE_SELECTED_USER = 'CHANGE_SELECTED_USER';
export const CHANGE_SELECTED_USER_FULFILLED = 'CHANGE_SELECTED_USER_FULFILLED';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_FULFILLED = 'ADD_PROJECT_FULFILLED';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_NOTIFICATION_FULFILLED = 'ADD_NOTIFICATION_FULFILLED';
export const ADD_NOTIFICATION_REJECTED = 'ADD_NOTIFICATION_REJECTED';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const ADD_NOTIFICATIONS_FULFILLED = 'ADD_NOTIFICATIONS_FULFILLED';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const CHECK_FOR_NOTIFICATIONS = 'CHECK_FOR_NOTIFICATIONS';
export const CHECK_FOR_NOTIFICATIONS_FULFILLED = 'CHECK_FOR_NOTIFICATIONS_FULFILLED';
export const CHECK_FOR_NOTIFICATIONS_PENDING = 'CHECK_FOR_NOTIFICATIONS_PENDING';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const REMOVE_PROJECT_FULFILLED = 'REMOVE_PROJECT_FULFILLED';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const EDIT_PROJECT_FULFILLED = 'EDIT_PROJECT_FULFILLED';
export const PROJECT_SEARCH = 'PROJECT_SEARCH';
export const PROJECT_SEARCH_FULFILLED = 'PROJECT_SEARCH_FULFILLED';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FULFILLED = 'ADD_USER_FULFILLED';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_FULFILLED = 'EDIT_USER_FULFILLED';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_FULFILLED = 'REMOVE_USER_FULFILLED';
export const CLOSE_ERROR = 'CLOSE_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const PROJECTS_DATE_CHANGED = 'PROJECTS_DATE_CHANGED';
export const ADD_PROJECT_MONTH_STATISTIC = 'ADD_PROJECT_MONTH_STATISTIC';
export const ADD_PROJECT_MONTH_STATISTIC_FULFILLED = 'ADD_PROJECT_MONTH_STATISTIC_FULFILLED';
export const ADD_PROJECT_MONTH_REPORT_STATISTIC = 'ADD_PROJECT_MONTH_REPORT_STATISTIC';
export const ADD_PROJECT_MONTH_REPORT_STATISTIC_FULFILLED = 'ADD_PROJECT_MONTH_REPORT_STATISTIC_FULFILLED';
export const ADD_HOLIDAY = 'ADD_HOLIDAY';
export const ADD_HOLIDAY_FULFILLED = 'ADD_HOLIDAY_FULFILLED';
export const EDIT_HOLIDAY = 'EDIT_HOLIDAY';
export const EDIT_HOLIDAY_FULFILLED = 'EDIT_HOLIDAY_FULFILLED';
export const REMOVE_HOLIDAY = 'REMOVE_HOLIDAY';
export const REMOVE_HOLIDAY_FULFILLED = 'REMOVE_HOLIDAY_FULFILLED';
export const FETCH_HOLIDAYS_API = 'FETCH_HOLIDAYS_API';
export const FETCH_HOLIDAYS_API_FULFILLED = 'FETCH_HOLIDAYS_API_FULFILLED';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const BULK_SET_VACATIONS = 'BULK_SET_VACATIONS';
export const BULK_SET_VACATIONS_FULFILLED = 'BULK_SET_VACATIONS_FULFILLED';
export const ADD_OVERTIME_REPORT = 'ADD_OVERTIME_REPORT';
export const ADD_OVERTIME_REPORT_FULFILLED = 'ADD_OVERTIME_REPORT_FULFILLED';
export const UPDATE_EMAIL_SUBSCRIPTIONS = 'UPDATE_EMAIL_SUBSCRIPTIONS';
export const UPDATE_EMAIL_SUBSCRIPTIONS_FULFILLED = 'UPDATE_EMAIL_SUBSCRIPTIONS_FULFILLED';
