// @flow

import * as API from '../../api';
import {
  ADD_USER,
  EDIT_USER,
  REMOVE_USER,
} from './types';
import type { Action, User } from '../../types';

export const addUser = (user: User): Action => ({
  type: ADD_USER,
  payload: API.addUser(user),
});

export const editUser = (user: User): Action => ({
  type: EDIT_USER,
  payload: API.editUser(user),
});

export const removeUser = (userId: number): Action => ({
  type: REMOVE_USER,
  payload: API.removeUser(userId),
});
