// @flow
/* eslint camelcase: ["off"] */
import React, { Component } from 'react';
import type { Node } from 'react';
import Calendar from 'rc-year-calendar';
import { map } from 'lodash';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';

type Props = {
  opened: ?boolean,
  userId: ?number,
  onCloseModal: Function,
  vacations: {
    used_vacation_dates: Array<any>,
  },
  year: string
};

class VacationModal extends Component<Props> {
  getDatesAsDateSourse = (dates: Array<any>) => map(dates, (e => ({
    startDate: new Date(e.date), endDate: new Date(e.date), color: '#28a745', date: e.date,
  })));

  render(): Node {
    const {
      onCloseModal, userId, opened, vacations, year,
    } = this.props;
    const classesModal = opened ? 'modal fade show d-block client-vacations-modal' : 'modal client-vacations-modal';
    const classesBackdrop = opened ? 'modal-backdrop show' : '';

    return (
      <>
        <div className={classesModal} id={userId} tabIndex="-1" role="dialog" aria-labelledby={userId} aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <ModalHeader onCloseClick={onCloseModal} title="Vacation days" />
              <ModalBody>
                <Calendar
                  dataSource={this.getDatesAsDateSourse(vacations.used_vacation_dates)}
                  year={year}
                  weekStart={1}
                  // eslint-disable-next-line react/style-prop-object
                  style="background"
                  displayDisabledDataSource
                  displayHeader={false}
                />
              </ModalBody>
            </div>
          </div>
        </div>
        <div className={classesBackdrop} />
      </>
    );
  }
}

export default (VacationModal: React$ComponentType<Props>);
