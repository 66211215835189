import {
  UPDATE_EMAIL_SUBSCRIPTIONS_FULFILLED,
} from '../actions/types';
import { Subscription } from '../../types';

function updateEmailSubscriptions(subscriptions: Array<Subscription>) {
  return subscriptions;
}

export function emailSubscriptionsReducer(state: Array<> = [], action: ActionWithPayload): Array<> {
  switch (action.type) {
    case UPDATE_EMAIL_SUBSCRIPTIONS_FULFILLED:
      return updateEmailSubscriptions(state, action.payload);
    default:
      return state;
  }
}
