// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import SpecialNumberInput from '../../elements/SpecialNumberInput';
import type { Project } from '../../../types';

type Props = {
  projects: Array<Project>,
  onEditProject: Function,
};

type State = {
  forms: {
    [number|string]: {
      monthlyLimit: number,
      changed: boolean,
    }
  }
};

class ProfileProjects extends Component<Props, State> {
  initialState: State;

  constructor(props: Props) {
    super(props);
    const { projects } = props;
    const forms = _.reduce(projects, (state: any, project: Project) => ({
      ...state,
      [project.id]: {
        monthlyLimit: project.monthly_limit,
        changed: false,
      },
    }), {});
    this.initialState = { forms };
    this.state = { ...this.initialState };
  }

  handleChange = (projectId: number|string, val: string) => {
    const { forms } = this.state;
    // if 'remove', a string is passes, then toInteger will turn the number to 0
    let change = _.toInteger(val);
    if (change < 0) change = 0;

    const hasChanged = this.initialState.forms[projectId].monthlyLimit !== change;
    this.setState({
      forms: {
        ...forms,
        [projectId.toString()]: {
          monthlyLimit: change,
          changed: hasChanged,
        },
      },
    });
  };

  isFormEdited = (projectId: number|string) => {
    const { forms } = this.state;
    const form = forms[projectId];

    return form.changed;
  };

  handleAcceptChange = (projectId: number|string) => {
    const { forms } = this.state;
    const { onEditProject } = this.props;
    const form = forms[projectId];

    onEditProject({
      id: projectId,
      monthly_limit: form.monthlyLimit,
    }).then(() => {
      const newState = {
        forms: {
          ...forms,
          [projectId.toString()]: {
            monthlyLimit: form.monthlyLimit,
            changed: false,
          },
        },
      };
      this.setState({ ...newState });
      this.initialState = { ...newState };
    });
  };

  render() {
    const { projects } = this.props;
    const { forms } = this.state;
    const projectCount = projects.length;

    return projects.map(p => (
      <div className={projectCount > 1 ? 'col-lg-4' : 'col-lg-5'} key={p.id}>
        <div className="card mb-2">
          <div className="card-body">
            <h5 className="card-title">
              {p.title}
            </h5>
            <ul className="list-group list-group-flush">
              <li className="list-group-item px-1">
                <span className="text-muted">Monthly limit:&nbsp;</span>
                {p.monthly_limit ? `${p.monthly_limit}h` : 'N/A'}
              </li>
              <li className="list-group-item px-1">
                <span className="text-muted">Change Monthly Limit:</span>
                <br />
                <SpecialNumberInput
                  value={forms[p.id].monthlyLimit}
                  onChangeInput={(val: string) => this.handleChange(p.id, val)}
                  disableAccept={!this.isFormEdited(p.id)}
                  disableRemove={forms[p.id].monthlyLimit === 0}
                  onAcceptChange={() => this.handleAcceptChange(p.id)}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    ));
  }
}

export default (ProfileProjects: React$ComponentType<Props>);
