// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import NavDropdown from './NavDropdown';
// $FlowFixMe
import Logo from '../../../../assets/images/timesheets_logo_border.svg';
import type { User } from '../../../types';

type Props = {
  isOwner?: boolean,
  isAdmin?: boolean,
  isClient?: boolean,
  navigate: Object,
  currentUser?: ?User,
}

class NavBigScreen extends Component<Props, any> {
  render(): Node {
    const { currentUser } = this.props;
    let { isAdmin, isOwner, isClient } = this.props;

    if (currentUser) {
      isClient = currentUser.role === 'client';
      isOwner = currentUser.role === 'owner';
      isAdmin = currentUser.role === 'admin';
    }

    return (
      <div className="device-big main-nav">
        <div className="row">
          <nav className="navbar navbar-default nav-flex">
            <div className="container-fluid">
              <div className="image-wrapper" onClick={this.handleLogoClick}>
                <img src={Logo} alt="logo" className="nav-logo" />
              </div>
              <div className="invisible" />
              <div className="nav-right">
                <NavDropdown isAdmin={isAdmin} isOwner={isOwner} isClient={isClient} />
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  handleLogoClick = () => {
    const { navigate } = this.props;
    navigate('/');
  };
}

export default (NavBigScreen: React$ComponentType<Props>);
