// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import MainNav from '../blocks/navigation/MainNav';
import UsersContent from '../blocks/users/UsersContent';
import ReduxModal from '../blocks/modal/ReduxModal';
import UsersModal from '../blocks/users/UsersModal';
import { isUserAdmin, isUserOwner, getUsersProjects } from '../../helpers';
import { USER_MODAL } from '../../redux/reducers/modal';
import type { User, UserRequest, UsersProps } from '../../types';

type State = {
  modal: {
    title: string,
    edit: boolean,
    saveButtonText: string,
    user: ?User,
  },
  searchForm: {
    role: string,
    projectId: number,
    inactive: boolean,
    student: boolean,
  },
  filteredUsers: Array<User>,
  searchTriggered: boolean,
  triggeredRole: string,
  triggeredProjectId: any,
};

class Users extends Component<UsersProps, State> {
  state: State = {
    modal: {
      title: '',
      edit: false,
      saveButtonText: '',
      user: null,
    },
    searchForm: {
      role: 'member',
      projectId: 0,
      inactive: false,
      student: false,
    },
    // eslint-disable-next-line react/destructuring-assignment
    filteredUsers: this.props.users.filter(user => user.active),
    searchTriggered: false,
    triggeredRole: '',
    triggeredProjectId: null,
  };

  handleEditModalOpen = (user: User) => {
    const { openModal } = this.props;
    openModal({ id: USER_MODAL });
    this.setState({
      modal: {
        title: 'Edit user',
        edit: true,
        saveButtonText: 'Save',
        user,
      },
    });
  };

  handleModalClose = () => {
    const { closeModal } = this.props;
    closeModal({ id: USER_MODAL });
    this.setState({
      modal: {
        display: false,
        title: '',
        edit: false,
        saveButtonText: '',
        user: null,
      },
    });
  };

  handleAddModalOpen = () => {
    const { openModal } = this.props;
    openModal({ id: USER_MODAL });
    this.setState({
      modal: {
        title: 'Add user',
        edit: false,
        saveButtonText: 'Save new user',
        user: null,
      },
    });
  };

  handleUserSubmit = (formData: UserRequest) => {
    const { modal: { edit }, searchTriggered } = this.state;
    const { onAddUser, onEditUser } = this.props;
    this.disableUsersModalButtons();
    const afterEditActions = () => {
      if (searchTriggered) this.setState({ filteredUsers: this.getFilteredUsers() });
      this.handleModalClose();
    };
    const afterAddActions = () => {
      this.handleSearchUsersReset();
      this.handleModalClose();
    };
    if (edit) {
      onEditUser(formData).then(afterEditActions);
    } else {
      onAddUser(formData).then(afterAddActions);
    }
  };

  handleUserDelete = (userId: number) => {
    const { searchTriggered } = this.state;
    const { onRemoveUser } = this.props;
    this.disableUsersModalButtons();
    const afterRemoveActions = () => {
      if (searchTriggered) this.setState({ filteredUsers: this.getFilteredUsers() });
      this.handleModalClose();
    };
    onRemoveUser(userId).then(afterRemoveActions);
  };

  disableUsersModalButtons = () => {
    const { updateModal } = this.props;
    updateModal({
      id: USER_MODAL,
      disableButtons: true,
    });
  };

  handleSelectRoleChange = (role: string) => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, role },
    });
  };

  handleSelectProjectChange = (projectId: number) => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, projectId },
    });
  };

  handleSearchUsersSubmit = () => {
    const { searchForm: { role, projectId } } = this.state;
    this.setState({
      filteredUsers: this.getFilteredUsers(),
      searchTriggered: true,
      triggeredRole: role,
      triggeredProjectId: projectId,
    });
  };

  handleSearchUsersReset = () => {
    this.setState({
      filteredUsers: [],
      searchTriggered: false,
      triggeredRole: '',
      triggeredProjectId: null,
    });
  };

  handleInactiveChange = () => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, inactive: !searchForm.inactive },
    });
  };

  handleStudentChange = () => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, student: !searchForm.student },
    });
  };

  getFilteredUsers = () => {
    const {
      searchForm: {
        role, projectId, inactive, student,
      },
    } = this.state;
    let { users } = this.props;
    users = users.filter(user => inactive || user.active);
    if (student) {
      users = users.filter(user => user.student);
    }
    if (role === 'any' && projectId === 0) {
      return users;
    }
    if (role !== 'any' && projectId === 0) {
      return users.filter(user => user.role === role);
    }
    if (role === 'any' && projectId !== 0) {
      return users.filter(user => user.project_ids.includes(projectId));
    }

    return users.filter(user => user.role === role && user.project_ids.includes(projectId));
  };

  render(): Node {
    const {
      currentUser, roles, vacations,
      router, projects,
    } = this.props;
    const {
      modal, searchForm, filteredUsers, searchTriggered, triggeredRole, triggeredProjectId,
    } = this.state;
    const isAdmin = isUserAdmin(currentUser);
    const isOwner = isUserOwner(currentUser);
    const userProjects = modal.user
      ? getUsersProjects(modal.user, projects)
      : [];
    const userVacations = modal.user ? vacations[modal.user.id] : {};

    return (
      <div className="users-view container-view view-wrapper">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        <UsersContent
          isAdmin={isAdmin}
          isOwner={isOwner}
          users={filteredUsers}
          selectedRole={searchForm.role}
          selectedProjectId={searchForm.projectId}
          onEditModalOpen={this.handleEditModalOpen}
          onAddModalOpen={this.handleAddModalOpen}
          onSelectRoleChange={this.handleSelectRoleChange}
          onSelectProjectChange={this.handleSelectProjectChange}
          onSearchUsersSubmit={this.handleSearchUsersSubmit}
          onSearchUsersReset={this.handleSearchUsersReset}
          onInactiveChange={this.handleInactiveChange}
          onStudentChange={this.handleStudentChange}
          inactive={searchForm.inactive}
          student={searchForm.student}
          roles={roles}
          projects={projects}
          searchTriggered={searchTriggered}
          triggeredRole={triggeredRole}
          triggeredProjectId={triggeredProjectId}
        />
        <ReduxModal id={USER_MODAL}>
          <UsersModal
            title={modal.title}
            edit={modal.edit}
            saveButtonText={modal.saveButtonText}
            user={modal.user}
            vacations={userVacations}
            projects={projects}
            userProjects={userProjects}
            roles={roles}
            onUserSubmit={this.handleUserSubmit}
            onUserDelete={this.handleUserDelete}
            onCloseModal={this.handleModalClose}
          />
        </ReduxModal>
      </div>
    );
  }
}

export default (Users: React$ComponentType<UsersProps>);
