// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import MainNav from '../blocks/navigation/MainNav';
import EmailSubscriptionsContent from '../blocks/email_subscriptions/EmailSubscriptionsContent';
import type { EmailSubscriptionsProps } from '../../types';

class EmailSubscriptions extends Component<EmailSubscriptionsProps> {
  render(): Node {
    const { currentUser, router, onUpdateEmailSubscriptions } = this.props;

    return (
      <div className="container-view vacation-management-view view-wrapper has-custom-table">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        <EmailSubscriptionsContent
          {...this.props}
          onUpdateEmailSubscriptions={onUpdateEmailSubscriptions}
        />
      </div>
    );
  }
}

export default (EmailSubscriptions: React$ComponentType<EmailSubscriptionsProps>);
