// @flow
import React, { useEffect } from 'react';
import type { Node } from 'react';

declare var $: Function;

type Props = {
  content: string,
  trigger?: string,
};

const NotificationMailContentPopover = (props: Props): Node => {
  const { content, trigger } = props;
  const popoverTrigger = trigger || 'click';
  let popoverContent = content;
  if (popoverTrigger === 'click') {
    popoverContent = `
      <div>
        <div style="height: 30px">
          <div class="float-right">
            <i class="fa fa-close text-muted" style="font-size: 18px" />
          </div>
        </div>
        ${content}
      </div>
    `;
  }

  useEffect(() => {
    if (popoverTrigger === 'focus') {
      $(document).on('mouseenter', 'button[data-toggle="popover"]', (e) => {
        $(e.target).focus();
      });

      $(document).on('mouseleave', 'button[data-toggle="popover"]', (e) => {
        setTimeout(() => {
          if ($('.popover:hover').length > 0) return;
          $(e.target).blur();
        }, 150);
      });

      $(document).on('mouseleave', '.popover', () => {
        $('button[data-toggle="popover"]').blur();
      });
    }

    return () => {
      $(document).off('mouseenter', 'button[data-toggle="popover"]');
      $(document).off('mouseleave', 'button[data-toggle="popover"]');
      $(document).off('mouseleave', '.popover');
    };
  }, [popoverTrigger]);

  return (
    <button
      className="btn btn-link p-0"
      type="button"
      data-toggle="popover"
      data-content={popoverContent}
      data-trigger={popoverTrigger}
      data-placement="auto"
      data-html
    >
      Mail content
    </button>
  );
};

export default NotificationMailContentPopover;
