// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import AddLogButton from './AddLogButton';
import TimeLog from './TimeLog';
// $FlowFixMe
import WatermarkHoliday from '../../../../assets/images/watermark_holiday.svg';
// $FlowFixMe
import WatermarkVacation from '../../../../assets/images/watermark_vacation.svg';
import type { Project, Holiday } from '../../../types';

type Props = {
  day: Object,
  logsById: Object,
  logIds: Object,
  onOpenAddModal: Function,
  onOpenEditModal: Function,
  dayNamesByDate: Object,
  projects: Array<Project>,
  showTab: boolean,
  holidays: Array<Holiday>,
  selectedUserVacationDates: Array<string>,
}

const DayColumnSmall = (props: Props): Node => {
  const {
    day, logsById, logIds, showTab,
    onOpenAddModal, onOpenEditModal, dayNamesByDate,
    projects, holidays, selectedUserVacationDates,
  } = props;
  const dailyTotal = _.sum(logIds.map(logId => _.toNumber(logsById[logId].time_spent)));
  const isHoliday = holidays.filter(holiday => holiday.date === day.date).length > 0;
  const isVacation = selectedUserVacationDates.filter(vacation => vacation === day.date).length > 0;
  const isWorkday = day.dayName !== 'Sat' && day.dayName !== 'Sun' && !isHoliday && !isVacation;
  const hoursMissing = dailyTotal < 8;
  let classes = 'tab-pane col-12 mt-3 text-center';
  if (showTab) {
    classes += ' fade show active';
  }

  const dailyTotalParsed = parseFloat(dailyTotal).toFixed(2);

  return (
    <div role="tabpanel" className={classes} id={dayNamesByDate[day.date]} data-date={day.date}>
      <div className="container-fluid day-column">
        <div className={`total-hours text-center ${isWorkday && hoursMissing ? 'hours-missing' : ''}`}>
          <h5>{`Total ${dailyTotal.toString().length >= 4 ? dailyTotalParsed : dailyTotal}h`}</h5>
        </div>
        <div className={`billable ${isHoliday ? 'holiday' : ''} ${isVacation ? 'vacation' : ''}`}>
          {isHoliday && (
            <div style={{ backgroundImage: `url(${WatermarkHoliday})` }} className="watermark" />
          )}
          {isVacation && (
            <div style={{ backgroundImage: `url(${WatermarkVacation})` }} className="watermark" />
          )}
          {logIds.map(logId => (
            <div key={logId}>
              {logsById[logId].project_billable && (
                <TimeLog
                  day={day}
                  onOpenEditModal={onOpenEditModal}
                  projects={projects}
                  log={logsById[logId]}
                  key={logId}
                />
              )}
            </div>
          ))}
        </div>
        <div className="non-billable">
          {logIds.map(logId => (
            <div key={logId}>
              {logsById[logId].project_billable === false && (
                <TimeLog
                  day={day}
                  onOpenEditModal={onOpenEditModal}
                  projects={projects}
                  log={logsById[logId]}
                  key={logId}
                />
              )}
            </div>
          ))}
        </div>
        <AddLogButton onOpenAddModal={onOpenAddModal} id={day.date} small />
      </div>
    </div>
  );
};

export default DayColumnSmall;
