// @flow
import _ from 'lodash';
import type { State, Project } from '../../types';

export const selectors = {
  getDashboardProjects: (state: State): Project[] => {
    if (!state.currentUser) return [];

    if (state.currentUser.role === 'client') return state.currentUser.projects;

    return state.projects.items;
  },
  getProjectsWithMonthyLimit: (state: State|Array<Project>): Project[] => {
    let projects = [];
    if (_.isArray(state)) {
      projects = state;
    } else if (state.projects && state.projects.items) {
      projects = state.projects.items;
    }

    return _.filter(projects, 'monthly_limit');
  },
  getProjectsWithEnabledReportSending: (state: State|Array<Project>): Project[] => {
    let projects = [];
    if (_.isArray(state)) {
      projects = state;
    } else if (state.projects && state.projects.items) {
      projects = state.projects.items;
    }

    return _.filter(projects, 'weekly_report_enabled');
  },
};
