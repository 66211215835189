// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  onChangeInput: Function,
  onAcceptChange: Function,
  value: number,
  disableAccept?: boolean,
  disableRemove?: boolean,
  showRemoveBtn?: boolean,
  step?: number,
};

const SpecialNumberInput = (props: Props): Node => {
  const {
    onChangeInput, value, disableAccept, onAcceptChange,
    disableRemove, showRemoveBtn,
  } = props;

  const displayRemoveBtn = typeof showRemoveBtn === 'undefined' || showRemoveBtn;

  const handleChange = (meta: string) => {
    const { value: currentValue, step } = props;
    let change = 0;
    const stepValue = step || 5;

    const current = currentValue || 0;
    if (meta === 'remove') {
      change = 0;
    } else {
      const addition = meta === 'add' ? stepValue : (-stepValue);
      change = current + addition;
    }
    if (change < 0) change = 0;

    onChangeInput(change);
  };

  return (
    <div>
      {displayRemoveBtn && (
        <button
          className="btn btn-primary primary-color mr-2"
          type="button"
          disabled={disableRemove}
          onClick={() => handleChange('remove')}
        >
          Remove
        </button>
      )}
      <div className="btn-group mr-2" role="group">
        <button
          className="btn btn-primary primary-color"
          type="button"
          onClick={() => handleChange('sub')}
        >
          -
        </button>
        <input
          className="text-center"
          style={{ width: 40 }}
          value={value}
          onChange={(e: any) => onChangeInput(e.target.value)}
        />
        <button
          className="btn btn-primary primary-color"
          type="button"
          onClick={() => handleChange('add')}
        >
          +
        </button>
      </div>
      <button
        className="btn btn-success"
        type="button"
        disabled={disableAccept}
        onClick={onAcceptChange}
      >
        Accept
      </button>
    </div>
  );
};

export default SpecialNumberInput;
