// @flow
import * as API from '../../api';
import {
  ADD_LOG,
  REMOVE_LOG,
  UPDATE_LOG,
  REQUEST_LOGS,
  REQUEST_DAY_LOGS,
  REQUEST_DATES_WITH_LOGS,
  CHANGE_DATES,
  CHANGE_SELECTED_USER,
  ADD_DRAGGED_LOG,
  REMOVE_DRAGGED_LOG,
} from './types';
import type { Action, LogRequest, RequestLogs } from '../../types';

export const addLog = (projectId: number, payload: LogRequest): Action => ({
  type: ADD_LOG,
  payload: API.addLog(projectId, payload),
});

export const updateLog = (projectId: number, logId: number, payload: LogRequest): Action => ({
  type: UPDATE_LOG,
  payload: API.updateLog(projectId, logId, payload),
});

export const removeLog = (projectId: number, logId: number): Action => ({
  type: REMOVE_LOG,
  payload: API.removeLog(projectId, logId),
});

export const requestLogs = ({ year, weekNum, selectedUserId }: RequestLogs): Action => ({
  type: REQUEST_LOGS,
  payload: API.requestLogs(year, weekNum, selectedUserId),
});

export const requestDayLogs = (date: string, selectedUserId: number): Action => ({
  type: REQUEST_DAY_LOGS,
  payload: API.requestDayLogs(date, selectedUserId),
});

export const requestDatesWithlogs = (year: number, selectedUserId: number): Action => ({
  type: REQUEST_DATES_WITH_LOGS,
  payload: API.requestDatesWithlogs(year, selectedUserId),
});

export const changeSelectedUser = (selectedUserId: number): Action => ({
  type: CHANGE_SELECTED_USER,
  payload: Promise.resolve(selectedUserId),
});

export const changeDates = (payload: any): Action => ({
  type: CHANGE_DATES,
  payload,
});

export const addDraggedLog = (payload: any): Action => ({
  type: ADD_DRAGGED_LOG,
  payload,
});

export const removeDraggedLog = (payload: any): Action => ({
  type: REMOVE_DRAGGED_LOG,
  payload,
});
