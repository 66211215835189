// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import UsersView from '../components/views/Users';
import {
  removeUser, addUser, editUser, openModal, closeModal,
  updateModal,
} from '../redux/actions';
import { withRouter } from '../helpers/withRouter';
import type {
  State, User, SingleModalState, UsersProps,
} from '../types';

class Users extends Component<UsersProps> {
  render(): Node {
    return (
      <UsersView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  currentUser: state.currentUser,
  users: state.users,
  roles: state.roles,
  projects: state.projects.items,
  modal: state.modal,
  vacations: state.vacations,
});

const mapDispatchToProps = dispatch => ({
  onAddUser: (user: User) => dispatch(addUser(user)),
  onEditUser: (user: User) => dispatch(editUser(user)),
  onRemoveUser: (userId: number) => dispatch(removeUser(userId)),
  openModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  closeModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  updateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)((Users: React$ComponentType<UsersProps>))): any);
