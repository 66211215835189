// @flow
import React from 'react';
import type { Node } from 'react';
import FormGroupWrapper from '../blocks/FormGroupWrapper';

type Props = {
  id: string,
  label: string,
  name: string,
  onChange: Function,
  value: number | string,
  type?: string,
  groupClass?: string,
  required?: boolean,
  autocompleteOff?: boolean,
}

const Input = (props: Props): Node => {
  const {
    id, label, type, onChange, value, name, required, groupClass,
    autocompleteOff,
  } = props;

  return (
    <FormGroupWrapper className={groupClass}>
      <label htmlFor={id} className="col-form-label">{label}</label>
      <input
        type={type || 'text'}
        className="form-control"
        id={id}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        autoComplete={autocompleteOff ? 'new-password' : ''}
      />
    </FormGroupWrapper>
  );
};

export default Input;
