// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  month: number,
  day: number,
  dayName: string,
  isToday: boolean,
}

const DayHeading = (props: Props): Node => {
  const {
    month, day, dayName,
    isToday,
  } = props;
  let dayHeadingClasses = 'col text-center individual-day js-day-navigation';
  if (isToday) {
    dayHeadingClasses += ' highlight-today';
  }

  return (
    <div className={dayHeadingClasses} data-day={`${month}-${day}`}>
      {`${dayName} ${day}/${month}`}
    </div>
  );
};

export default DayHeading;
