// @flow
import React from 'react';
import type { Node } from 'react';
import type { Holiday } from '../../../../types';

type Props = {
  holiday: Holiday,
  onEditModalOpen: Function,
}

const HolidaysTableRow = (props: Props): Node => {
  const { holiday, onEditModalOpen } = props;

  return (
    <div
      className="table-row with-cursor"
      onClick={() => onEditModalOpen(holiday)}
    >
      <div className="single-column">
        {holiday.date}
      </div>
      <div className="single-column">
        {holiday.description}
      </div>
    </div>
  );
};

export default HolidaysTableRow;
