// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import DayHeadingSmall from './DayHeadingSmall';
import DayColumnSmall from './DayColumnSmall';
import type { CalendarContentProps } from '../../../types';

type State = {
  activeTab: string,
}

class CalendarContentSmall extends Component<CalendarContentProps, State> {
  state: State = {
    activeTab: 'Mon',
  };

  componentDidMount = () => {
    const { dates } = this.props;
    const { activeTab } = this.state;
    const today = dates.find(date => date.isToday);
    if (today && today.dayName !== activeTab) {
      this.setState({
        activeTab: today.dayName,
      });
    }
  };

  handleChangeTab = (dayName: string) => {
    this.setState({
      activeTab: dayName,
    });
  };

  render(): Node {
    const {
      dates,
      logsById,
      onOpenAddModal,
      onOpenEditModal,
      projects,
      getLogIdsForWeekday,
      holidays,
      selectedUserVacationDates,
    } = this.props;
    const { activeTab } = this.state;
    const dayNamesByDate = {};
    dates.forEach(date => {
      dayNamesByDate[date.date] = date.dayName;
    });

    return (
      <div className="device-small">
        <div className="row nav-tab-container text-container text-center no-gutters">
          <ul className="nav nav-tabs hidden-md-up text-center" role="tablist">
            {dates.map(date => (
              <DayHeadingSmall
                day={date.day}
                month={date.month}
                dayName={date.dayName}
                isToday={date.isToday}
                key={date.dayName}
                onTabLinkClick={this.handleChangeTab}
                activeTab={activeTab}
              />
            ))}
          </ul>
        </div>
        <div className="calendar-main-content">
          <div className="tab-content row">
            {dates.map(day => (
              <DayColumnSmall
                onOpenEditModal={onOpenEditModal}
                onOpenAddModal={onOpenAddModal}
                dayNamesByDate={dayNamesByDate}
                projects={projects}
                logsById={logsById}
                logIds={getLogIdsForWeekday(day.date)}
                day={day}
                key={day.date}
                showTab={day.dayName === activeTab}
                holidays={holidays}
                selectedUserVacationDates={selectedUserVacationDates}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default (CalendarContentSmall: React$ComponentType<CalendarContentProps>);
