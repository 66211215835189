// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  activeUserId: number,
}

const CurrentUserInput = (props: Props): Node => {
  const { activeUserId } = props;

  return <input type="hidden" id="current-user-id" value={activeUserId} />;
};

export default CurrentUserInput;
