// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import ProfileView from './ProfileView';
import ProfileForm from './ProfileForm';
import ProfileVacations from './ProfileVacations';
import ProfileProjectTable from './ProfileProjects';
import Select from '../../elements/Select';
import Calendar from '../management/Calendar';
import VacationColors from './VacationColors';
import {
  getPastDaysForYear,
  getAsDateArray,
  canManageOwnVacations,
  getYearsForSelect,
  getMemberRelevantProjects,
} from '../../../helpers';
import type { VacationsPayload, ProfileProps } from '../../../types';

type State = {
  isEditing: boolean,
  savedForm: boolean,
  calendarOpened: boolean,
  selectedYear: number,
};

class ProfileContent extends Component<ProfileProps, State> {
  state = {
    isEditing: false,
    savedForm: false,
    calendarOpened: false,
    selectedYear: _.toString(new Date().getFullYear()),
  };

  handleYearChange = (e: Object) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  turnEditingOn = () => this.setState({ isEditing: true });

  turnEditingOff = () => this.setState({ isEditing: false });

  handleFormSave = () => {
    this.setState({ savedForm: true });
    setTimeout(() => {
      this.setState({ savedForm: false });
    }, 4000);
  };

  handleVacationAccept = (dates: VacationsPayload) => {
    const { currentUser, onBulkSetVacations, holidays } = this.props;
    onBulkSetVacations(currentUser.id, Number(this.state.selectedYear), {
      deleted: dates.deleted, added: dates.added.filter(a => !_.map(holidays, 'date').includes(a.date)), locked: [],
    });
  };

  handleProjectDashboardClick = (e, link) => {
    const { router } = this.props;
    e.preventDefault();
    router.navigate(link);
  };

  render() {
    const {
      isEditing, savedForm, calendarOpened, selectedYear,
    } = this.state;
    const {
      currentUser, onEditUser, onEditProject, projects, holidays, vacations,
    } = this.props;
    const currentYear = new Date().getFullYear();
    const dates = _.filter(vacations.usedVacationDates, { year: Number(selectedYear) });
    const lockedVacationDays = _.filter(dates, 'locked');
    // $FlowFixMe
    const disabledDates = [...getPastDaysForYear(currentYear), ...getAsDateArray(lockedVacationDays), ...getAsDateArray(holidays)];

    return (
      <>
        <div className="container mt-4">
          <h2>My Profile</h2>
          <hr className="float-left" />
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col col-lg-4 mb-2">
              {!isEditing && (
                <ProfileView
                  currentUser={currentUser}
                  onEditButtonClick={this.turnEditingOn}
                  savedForm={savedForm}
                />
              )}
              {isEditing && (
                <ProfileForm
                  currentUser={currentUser}
                  onEditUser={onEditUser}
                  onCloseButtonClick={this.turnEditingOff}
                  onFormSaved={this.handleFormSave}
                />
              )}
            </div>
            {currentUser.isUserClient && (
              <ProfileProjectTable
                projects={projects}
                onEditProject={onEditProject}
              />
            )}
            {canManageOwnVacations(currentUser) && (
              <>
                <div className="col col-lg-4 mb-2">
                  <ProfileVacations
                    vacationsStats={vacations.uiVacationStats[selectedYear]}
                    calendarOpened={calendarOpened}
                    onAddVacationsDays={() => this.setState({ calendarOpened: !calendarOpened })}
                    selectedYear={selectedYear}
                  />
                </div>
                <div className="col col-lg-2 selects-wrapper">
                  <Select
                    groupClass="month js-month"
                    selectClass="form-control select-year"
                    labelClass=""
                    name="year"
                    label="Select year:"
                    id="year"
                    items={getYearsForSelect()}
                    value={selectedYear}
                    onSelectChange={this.handleYearChange}
                  />
                </div>
              </>
            )}
          </div>
          {calendarOpened && canManageOwnVacations(currentUser) && (
            <div className="row">
              <div className="col-12">
                <h3>Manage vacations:</h3>
                <hr className="float-left" />
                <VacationColors className='active' />
              </div>
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <Calendar
                      selectedYear={selectedYear}
                      dates={dates}
                      onAcceptChange={this.handleVacationAccept}
                      disabledDays={disabledDates}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!currentUser.isUserClient && (
            <div className="row">
              <div className="col-12">
                <h3>My projects:</h3>
                <hr className="float-left" />
              </div>
              <div className="col-4">
                <div className="card">
                  <div className="card-body pt-1">
                    <ul className="list-group list-group-flush">
                      {getMemberRelevantProjects(projects, currentUser).map(project => (
                        <li key={project.id} className="list-group-item px-1">
                          <a href={`/projects/${project.id}`} onClick={(e) => this.handleProjectDashboardClick(e, `/projects/${project.id}`)}>{project.title}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default (ProfileContent: React$ComponentType<ProfileProps>);
