// @flow
/* eslint-disable camelcase */
import type { LogRequest } from '../types';
import axios from './axios';

function returnLogFromResponse(response: any): Object {
  const {
    log, user, project_billable, category_color, projects_weekly_hours,
  } = response.data;

  return {
    ...log,
    project_billable,
    user,
    category_color,
    projects_weekly_hours,
  };
}

function returnDeleteLogResponse(response: any): Object {
  const { date } = response.data;

  return {
    ...returnLogFromResponse(response),
    date,
  };
}

function returnRequestedLogs(response: any, selectedUserId: number): Object {
  const { logs } = response.data;
  const projectsWeeklyHours = response.data.projects_weekly_hours;

  return {
    logs,
    selectedUserId,
    projectsWeeklyHours,
  };
}

export function addLog(projectId: number, data: LogRequest): Promise<any> {
  return axios.post(`/projects/${projectId}/logs.json`, data)
    .then(returnLogFromResponse);
}

export function updateLog(projectId: number, logId: number, data: LogRequest): Promise<any> {
  return axios.put(`/projects/${projectId}/logs/${logId}.json`, data)
    .then(returnLogFromResponse);
}

export function removeLog(projectId: number, logId: number): Promise<any> {
  return axios.delete(`/projects/${projectId}/logs/${logId}.json`)
    .then(returnDeleteLogResponse);
}

export function requestLogs(year: number, weekNum: number, selectedUserId: number): Promise<any> {
  return axios.get(`/${year}/week/${weekNum}.json?user_id=${selectedUserId}`)
    .then(response => returnRequestedLogs(response, selectedUserId));
}

export function requestDayLogs(date: string, selectedUserId: number): Promise<any> {
  return axios.get(`/year-calendar/${date}.json`, { params: { selectedUserId } })
    .then(response => response.data);
}

export function requestDatesWithlogs(year: number, selectedUserId: number): Promise<any> {
  return axios.get('/year_dates_with_logs.json', { params: { year, selectedUserId } })
    .then(response => response.data);
}
