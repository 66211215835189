// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import HolidaysTableRow from './HolidaysTableRow';
import type { Holiday } from '../../../../types';

type Props = {
  holidays: Array<Holiday>,
  onEditModalOpen: Function,
  onAddModalOpen: Function,
}

const HolidaysTableContent = (props: Props): Node => {
  const { holidays, onEditModalOpen, onAddModalOpen } = props;

  return (
    <div className="container main-content">
      <div className="table-navbar">
        <div className="table-column">
          <h2>Holiday Date</h2>
        </div>
        <div className="table-column">
          <h2>Description</h2>
        </div>
      </div>
      <div className="table-rows full-height">
        {_.sortBy(holidays, ['date']).map(holiday => (
          <HolidaysTableRow
            key={holiday.id}
            holiday={holiday}
            onEditModalOpen={onEditModalOpen}
          />
        ))}
      </div>
      <button
        type="button"
        className="add-btn"
        id=""
        onClick={onAddModalOpen}
      >
        <i className="fa fa-plus" aria-hidden="true" />
      </button>
    </div>
  );
};

export default HolidaysTableContent;
