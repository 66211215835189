// @flow
import axios from 'axios';
import { getCSRFToken } from '../helpers';

const axiosInstance: any = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': getCSRFToken(),
    },
  },
});

export default axiosInstance;
