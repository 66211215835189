// @flow
import React, { Component } from 'react';
import type { Node } from 'react';

class EmailSubscriptionsTableHeader extends Component<any, any> {
  render(): Node {
    return (
      <div className="container container-header mt-4">
        <div className="upper-header-wrapper">
          <div className="left-column">
            <h2>Email Subscriptions</h2>
            <hr className="float-left" />
          </div>
        </div>
      </div>
    );
  }
}

export default (EmailSubscriptionsTableHeader: React$ComponentType<any>);
