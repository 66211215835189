// @flow
import React from 'react';
import type { Node } from 'react';
import Select from 'react-select';
import FormGroupWrapper from '../blocks/FormGroupWrapper';
import type { ReactSelectData } from '../../types';

type Props = {
  id: string,
  label: string,
  name: string,
  defaultValue: ReactSelectData,
  options: ReactSelectData,
  onChange: Function,
  value?: any,
};

const ReactSelect = (props: Props): Node => {
  const {
    id, label, defaultValue, options, onChange, name, value,
  } = props;
  const receivedProps = {
    isMulti: true,
    className: 'basic-multi-select',
    classNamePrefix: 'select',
    defaultValue,
    name,
    options,
    onChange,
  };
  if (value) {
    // $FlowFixMe
    receivedProps.value = value;
  }

  return (
    <FormGroupWrapper>
      <label htmlFor={id} className="col-form-label">{label}</label>
      <Select {...receivedProps} />
    </FormGroupWrapper>
  );
};

export default ReactSelect;
