// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import ProfileContent from '../blocks/profile/ProfileContent';
import MainNav from '../blocks/navigation/MainNav';
import type { ProfileProps } from '../../types';

class Profile extends Component<ProfileProps> {
  render(): Node {
    const { currentUser, router } = this.props;

    return (
      <div className="container-view view-wrapper">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        <ProfileContent
          {...this.props}
        />
      </div>
    );
  }
}

export default (Profile: React$ComponentType<ProfileProps>);
