// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

type Props = {
  onTabCange: Function,
  tabs: { [string]: string }
};

type State = {
  activeTab: string,
};

class TabsSwitcher extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { tabs } = props;
    this.state = {
      activeTab: _.first(_.keys(tabs)),
    };
  }

  isActive = (currentTab: string) => {
    const { activeTab } = this.state;

    return activeTab === currentTab;
  };

  setActiveTab = (e: any, tab: string) => {
    e.preventDefault();
    const { onTabCange } = this.props;
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
      onTabCange(tab);
    }
  };

  render(): Node {
    const { tabs } = this.props;

    return (
      <div className="container tabs-switcher">
        <div className="row">
          <div className="col">
            <ul className="nav nav-pills nav-fill">
              {_.keys(tabs).map(tab => (
                <li className="nav-item" key={tab}>
                  <a
                    className={classNames('nav-link', { active: this.isActive(tab) })}
                    href="#"
                    onClick={(e) => this.setActiveTab(e, tab)}
                  >
                    {tabs[tab]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default (TabsSwitcher: React$ComponentType<Props>);
