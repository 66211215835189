// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import TableWrapper from '../TableWrapper';
import EmailSubscriptionsTableHeader from './table/EmailSubscriptionsTableHeader';
import EmailSubscriptionsTableContent from './table/EmailSubscriptionsTableContent';
import type { Subscription } from '../../../types';

type Props = {
  subscriptions: Array<Subscription>,
  onUpdateEmailSubscriptions: Function,
};

class EmailSubscriptionsContent extends Component<Props> {
  render(): Node {
    const { subscriptions, onUpdateEmailSubscriptions } = this.props;

    return (
      <TableWrapper
        className="small-device-project has-custom-table"
      >
        <EmailSubscriptionsTableHeader />
        <EmailSubscriptionsTableContent
          subscriptions={subscriptions}
          onUpdateEmailSubscriptions={onUpdateEmailSubscriptions}
        />
      </TableWrapper>
    );
  }
}

export default (EmailSubscriptionsContent: React$ComponentType<Props>);
