// @flow
import React from 'react';
import type { Node } from 'react';
import HolidaysTableHeader from './table/HolidaysTableHeader';
import HolidaysTableContent from './table/HolidaysTableContent';
import type { Holiday } from '../../../types';

type Props = {
  holidays: Array<Holiday>,
  onEditModalOpen: Function,
  onAddModalOpen: Function,
  onFetchHolidaysFromApi: Function,
};

const HolidaysContent = (props: Props): Node => {
  const {
    holidays,
    onEditModalOpen,
    onAddModalOpen,
    onFetchHolidaysFromApi,
  } = props;

  return (
    <>
      <HolidaysTableHeader
        onFetchHolidaysFromApi={onFetchHolidaysFromApi}
      />
      <HolidaysTableContent
        holidays={holidays}
        onEditModalOpen={onEditModalOpen}
        onAddModalOpen={onAddModalOpen}
      />
    </>
  );
};

export default HolidaysContent;
