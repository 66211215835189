import React from 'react';

const colors = [
  { key: 1, label: 'User requested', color: '#4253af80' },
  { key: 2, label: 'Admin approved ', color: '#007bff' },

];

function VacationColors() {
  return (
    <div className='row d-flex justify-content-center'>{colors.map((color) => (
      <div key={color.key} className='row mx-2 d-flex align-items-center'>{color.label}<span style={{ backgroundColor: color.color }} className='circle' />
      </div>
    ))}
    </div>
  );
}

export default VacationColors;
