// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import EmailSubscriptionsTableRow from './EmailSubscriptionTableRow';
import type { Subscription } from '../../../../types';

type Props = {
  subscriptions: Array<Subscription>,
  onUpdateEmailSubscriptions: Function
};

const EmailSubscriptionsTableContent = ({ subscriptions, onUpdateEmailSubscriptions }: Props): Node => {
  const [emailSubscriptions, setEmailSubscriptions] = useState([]);

  const handleSubscriptionChange = (subs) => {
    setEmailSubscriptions([...emailSubscriptions, subs]);
  };

  return (
    <div className='container main-content subscriptions-table'>
      <div className='table-navbar' style={{ fontSize: '14px' }}>
        <div className="table-column">User name</div>
        <div className="table-column">New Client Registered</div>
        <div className="table-column">Reaching Monthly Limit</div>
        <div className="table-column">Monthly Limit Changed</div>
        <div className="table-column">New Vacation Added</div>
        <div className="table-column">Weekly Projects Report</div>
      </div>
      <div className='table-rows'>
        { subscriptions.map(subscription => (
          <EmailSubscriptionsTableRow key={subscription.id} id={subscription.id} emailSubscription={subscription} onSubscriptionChange={(subs) => handleSubscriptionChange(subs)} />
        ))}
      </div>
      <button
        className="add-btn"
        type="button"
        onClick={() => onUpdateEmailSubscriptions(emailSubscriptions)}
      >
        Submit
      </button>
    </div>
  );
};

export default EmailSubscriptionsTableContent;
