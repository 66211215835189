// @flow
import React from 'react';
import type { Node } from 'react';
import ProjectRow from './ProjectRow';
import type { Project } from '../../../../types';

type Props = {
  projects: Array<Project>,
  onOpenAddProjectModal: Function,
  onOpenEditProjectModal: Function,
  searchTriggered: boolean,
  onRowClick: Function,
};

const ProjectTableContent = (props: Props): Node => {
  const {
    projects,
    onOpenAddProjectModal,
    searchTriggered,
    onRowClick,
    onOpenEditProjectModal,
  } = props;
  const keySort = (a: any, b: any) => {
    if (a > b) return +1;
    if (a < b) return -1;

    return 0;
  };
  projects.sort((a, b) => keySort(b.billable, a.billable) || keySort(a.title, b.title));

  return (
    <div className="container main-content">
      <div className="table-navbar">
        <div className="table-column">
          <h4>Project</h4>
        </div>
        <div className="table-column-end">
          <h4>Hours</h4>
        </div>
        <div className="table-column-end">
          <h4>Monthly Limit</h4>
        </div>
        <div className="table-column-end">
          <h4>Actions</h4>
        </div>
      </div>
      <div className="table-rows full-height">
        {projects.map(project => (
          <ProjectRow
            onRowClick={onRowClick}
            key={project.id}
            project={project}
            searchTriggered={searchTriggered}
            onOpenEditProjectModal={onOpenEditProjectModal}
          />
        ))}
      </div>
      <button
        className="add-btn"
        type="button"
        onClick={onOpenAddProjectModal}
      >
        <i className="fa fa-plus" aria-hidden="true" />
      </button>
    </div>
  );
};

export default ProjectTableContent;
