// @flow
import _ from 'lodash';
import {
  ADD_LOG_FULFILLED,
  UPDATE_LOG_FULFILLED,
  REMOVE_LOG_FULFILLED,
  REQUEST_LOGS_FULFILLED,
  REQUEST_DAY_LOGS_FULFILLED,
  REQUEST_DATES_WITH_LOGS_FULFILLED,
  ADD_DRAGGED_LOG,
  REMOVE_DRAGGED_LOG,
} from '../actions/types';
import type { ActionWithPayload, Logs, Log } from '../../types';
import { getNormalizedLogs } from '../../helpers';

function addNewLog(logs: Logs, date: string, newLog: Log) {
  const {
    items, logsById, logsByUserId, logsByWeekday,
  } = logs;
  const userId = newLog.user.id;
  const newLogId = newLog.id;

  return {
    items: _.concat(items, newLog),
    logsById: { ...logsById, [newLogId]: newLog },
    logsByUserId: { ...logsByUserId, [userId]: _.concat(logsByUserId[userId], newLogId) },
    logsByWeekday: { ...logsByWeekday, [date]: _.concat(logsByWeekday[date] || [], { id: newLogId, userId }) },
    draggedLog: null,
    datesWithLogs: [...logs.datesWithLogs],
    dateTotals: { ...logs.dateTotals },
  };
}

function editLog(logs: Logs, editedLog: Log) {
  const {
    items, logsById, logsByUserId, logsByWeekday,
  } = logs;

  return {
    items: _.concat(_.filter(items, i => i.id !== editedLog.id), editedLog),
    logsById: { ...logsById, [editedLog.id]: editedLog },
    logsByUserId: { ...logsByUserId },
    logsByWeekday: { ...logsByWeekday },
    draggedLog: null,
    datesWithLogs: [...logs.datesWithLogs],
    dateTotals: { ...logs.dateTotals },
  };
}

function removeLog(logs: Logs, date: string, removedLog: Log) {
  const {
    items, logsById, logsByUserId, logsByWeekday,
  } = logs;
  const userId = removedLog.user.id;
  const removedLogId = removedLog.id;

  return {
    items: _.filter(items, i => i.id !== removedLogId),
    logsById: _.omit(logsById, removedLogId),
    logsByUserId: { ...logsByUserId, [userId]: _.filter(logsByUserId[userId], logId => logId !== removedLogId) },
    logsByWeekday: { ...logsByWeekday, [date]: _.filter(logsByWeekday[date], log => log.id !== removedLogId) },
    draggedLog: null,
    datesWithLogs: [...logs.datesWithLogs],
    dateTotals: { ...logs.dateTotals },
  };
}

function addAdditionalLogs(logs: Logs, additionalLogs: Logs, userId: number) {
  if (!additionalLogs.items.length) {
    return logs;
  }

  const addLogIds = _.flatMap(additionalLogs.items, 'id');
  const items = _.filter(logs.items, log => !_.includes(addLogIds, log.id));
  const userLogIds = _.uniq(_.concat(logs.logsByUserId[userId] || [], ...additionalLogs.logsByUserId[userId]));
  const addLogsByWeekday = { ...additionalLogs.logsByWeekday };
  const logsByWeekday = { ...logs.logsByWeekday };
  _.forIn(addLogsByWeekday, (addLogs, date) => {
    logsByWeekday[date] = _.uniqBy(_.concat(logsByWeekday[date] || [], ...addLogs), 'id');
  });

  return {
    items: _.concat(items, ...additionalLogs.items),
    logsById: { ...logs.logsById, ...additionalLogs.logsById },
    logsByUserId: { ...logs.logsByUserId, [userId]: userLogIds },
    draggedLog: null,
    datesWithLogs: [...logs.datesWithLogs],
    logsByWeekday,
    dateTotals: { ...logs.dateTotals },
  };
}

const defaultLogs = {
  items: [],
  logsById: {},
  logsByWeekday: {},
  logsByUserId: {},
  draggedLog: null,
  datesWithLogs: [],
  dateTotals: {},
};

export function logsReducers(state: Logs = defaultLogs, action: ActionWithPayload): Logs {
  const { payload } = action;

  switch (action.type) {
    case ADD_LOG_FULFILLED:
      return addNewLog(state, payload.date, payload);
    case UPDATE_LOG_FULFILLED:
      return editLog(state, payload);
    case REMOVE_LOG_FULFILLED:
      return removeLog(state, payload.date, payload);
    case REQUEST_LOGS_FULFILLED:
      return addAdditionalLogs(state, getNormalizedLogs(payload.logs), payload.selectedUserId);
    case REQUEST_DAY_LOGS_FULFILLED:
      let newState = { ...state };
      _.each(payload.logs, log => {
        if (!state.logsById[log.id]) {
          newState = addNewLog(newState, log.date, log);
        }
      });

      return newState;
      // return addNewLogs(state, payload.date, payload.logs || []);
    case REQUEST_DATES_WITH_LOGS_FULFILLED:
      return { ...state, datesWithLogs: _.keys(payload), dateTotals: payload };
    case ADD_DRAGGED_LOG:
      return { ...state, draggedLog: action.payload };
    case REMOVE_DRAGGED_LOG:
      return { ...state, draggedLog: null };
    default:
      return state;
  }
}
