import axios from './axios';

export function getNotifications(page = null, perPage = 10): Promise<any> {
  const query = page ? `?per_page=${perPage}&page=${page}` : '';

  return axios.get(`/notifications.json${query}`)
    .then(response => response.data);
}

export function addNotification(target: string, content: string, sendTo: string|Array<string>): Promise<any> {
  return axios.post('/notifications.json', { target, content, send_to: sendTo })
    .then(response => response.data);
}
