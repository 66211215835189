// @flow
import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  formatISO,
  getDate,
  getMonth,
  getISOWeek,
  getYear,
  isToday,
  setISOWeek,
  startOfWeek,
  subWeeks,
  isBefore,
  isSameDay,
} from 'date-fns';
import { map } from 'lodash';

type DateForWeek = {
  date: string,
  day: number,
  month: number,
  dayName: string,
  isToday: boolean,
}

export type WeekdayLabels = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export const formatDateToISOString = (date: Date): string => formatISO(date, { representation: 'date' });

export const formatDate = (date: Date, selectedFormat: string): string => format(date, selectedFormat);

export const isSameOrBefore = (dateStart: Date, dateEnd: Date): boolean => isBefore(dateStart, dateEnd) || isSameDay(dateStart, dateEnd);

export const getWeekBeginningMon = (date: Date): number => getISOWeek(date);

export const getNextWeekdayDate = (date: Date, day: WeekdayLabels): string => {
  const dateOptions = { weekStartsOn: 1 };
  const currentWeekdays = eachDayOfInterval({
    start: startOfWeek(date, dateOptions),
    end: endOfWeek(date, dateOptions),
  });

  const nextDate = () => {
    switch (day) {
      case 'tue':
        return currentWeekdays[1];
      case 'wed':
        return currentWeekdays[2];
      case 'thu':
        return currentWeekdays[3];
      case 'fri':
        return currentWeekdays[4];
      case 'sat':
        return currentWeekdays[5];
      case 'sun':
        return currentWeekdays[6];
      default:
        return currentWeekdays[0];
    }
  };

  return formatISO(addWeeks(nextDate(), 1), { representation: 'date' });
};

export const getDatesForWeek = (dates: string[]): DateForWeek[] => dates.map(day => ({
  date: day,
  day: getDate(new Date(day)),
  month: getMonth(new Date(day)) + 1,
  dayName: format(new Date(day), 'EEE'),
  isToday: isToday(new Date(day)),
}));

export const changeNavWeek = (dates: string[], subWeek: boolean): {year: number, week: number} => {
  const start: Date = startOfWeek(new Date(dates[0]), { weekStartsOn: 1 });
  const newDate: Date = subWeek ? subWeeks(start, 1) : addWeeks(start, 1);

  return {
    year: getYear(newDate),
    week: getISOWeek(newDate),
  };
};

export const getSelectedWeekDates = (year: number, weekNum: number): Date[] => {
  const dateOptions = {
    weekStartsOn: 1,
  };
  const selectedDate = setISOWeek(new Date(year, 1, 1), weekNum);
  let dates = eachDayOfInterval({
    start: startOfWeek(selectedDate, dateOptions),
    end: endOfWeek(selectedDate, dateOptions),
  });
  dates = map(dates, (date) => formatISO(date, { representation: 'date' }));

  return dates;
};

export const getNavDates = (datesInWeek: Date[]): {startOfWeek: string, endOfWeek: string, year: number} => ({
  startOfWeek: format(startOfWeek(datesInWeek[0], { weekStartsOn: 1 }), 'MMM d'),
  endOfWeek: format(endOfWeek(datesInWeek[0], { weekStartsOn: 1 }), 'MMM d'),
  year: getYear(datesInWeek[0]),
});

export const getNavToday = (): {year: number, week: number} => {
  const today = new Date();

  return {
    year: getYear(today),
    week: getWeekBeginningMon(today),
  };
};
