// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import MainNav from '../blocks/navigation/MainNav';
import ProjectsContent from '../blocks/projects/ProjectsContent';
import ReduxModal from '../blocks/modal/ReduxModal';
import ProjectsModal from '../blocks/projects/ProjectsModal';
import { isUserAdmin, isUserOwner } from '../../helpers';
import { PROJECT_MODAL } from '../../redux/reducers/modal';
import type {
  Projects as ProjectsType, Project, User, Router,
} from '../../types';
import { withRouter } from '../../helpers/withRouter';

type State = {
  modal: {
    edit: boolean,
    saveButtonText: string,
    title: string,
    project: ?Project,
  },
  searchForm: {
    year: number,
    month: number,
  },
  searchTriggered: boolean,
}

type Props = {
  currentUser: User,
  projects: ProjectsType,
  onAddProject: Function,
  onRemoveProject: Function,
  onEditProject: Function,
  onProjectSearch: Function,
  onDatesChanged: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  router: Router,
};

class Projects extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { location } = this.props.router;
    const params = new URLSearchParams(location.search);
    const monthIndex = _.toNumber(params.get('month'));
    const yearIndex = _.toNumber(params.get('year'));

    const month = monthIndex > 0 ? monthIndex : (new Date().getMonth() + 1);
    const year = yearIndex > 0 ? yearIndex : new Date().getFullYear();

    this.state = {
      modal: {
        edit: false,
        saveButtonText: '',
        title: '',
        project: null,
      },
      searchForm: { year, month },
      searchTriggered: true,
    };
  }

  componentDidMount() {
    if (this.state.searchForm.year && this.state.searchForm.month) {
      this.searchProjects();
    } else {
      this.fetchProjectsDataForCurrentMonth();
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    const {
      location: { search },
    } = this.props.router;
    const searchChanged = prevProps.router.location.search !== search;

    if (searchChanged) {
      if (search.length > 0) {
        this.searchProjects();
      } else {
        this.fetchProjectsDataForCurrentMonth();
      }
    }
  };

  handleModalClose = () => {
    const { onCloseModal } = this.props;
    onCloseModal({ id: PROJECT_MODAL });
    this.setState({
      modal: {
        edit: false,
        saveButtonText: '',
        title: '',
        project: null,
      },
    });
  };

  handleSelectMonthChange = (month: number) => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, month },
    });
  };

  handleSelectYearChange = (year: number) => {
    const { searchForm } = this.state;
    this.setState({
      searchForm: { ...searchForm, year },
    });
  };

  searchProjects = () => {
    const {
      onProjectSearch, onDatesChanged,
    } = this.props;
    const { searchForm: { year, month } } = this.state;
    this.setState({ searchTriggered: true });

    if (onProjectSearch) {
      onProjectSearch(year, month);
    } else {
      onDatesChanged(year, month);
    }
  };

  handleSearchProjectsSubmit = () => {
    const { navigate } = this.props.router;
    const { searchForm: { year, month } } = this.state;
    navigate(`/projects?year=${year}&month=${month}`);
  };

  handleSearchProjectsReset = () => {
    const { navigate } = this.props.router;
    this.setState({
      searchTriggered: false,
      searchForm: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      },
    });
    navigate('/projects');
  };

  handleOpenAddProjectModal = () => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: PROJECT_MODAL });
    this.setState({
      modal: {
        edit: false,
        saveButtonText: 'Save new project',
        title: 'Add project',
        project: null,
      },
    });
  };

  handleOpenEditProjectModal = (project: Project) => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: PROJECT_MODAL });
    this.setState({
      modal: {
        edit: true,
        saveButtonText: 'Save',
        title: 'Save project',
        project,
      },
    });
  };

  handleProjectDelete = (projectId: number) => {
    const { onRemoveProject } = this.props;
    const { searchTriggered } = this.state;
    const afterSubmitActions = () => {
      if (searchTriggered) this.searchProjects();
      this.handleModalClose();
    };
    this.disableProjectsModalButtons();
    onRemoveProject(projectId)
      .then(afterSubmitActions)
      .catch(this.handleModalClose);
  };

  handleProjectSubmit = (formData: any) => {
    const { onAddProject, onEditProject } = this.props;
    const { modal: { edit }, searchTriggered } = this.state;
    this.disableProjectsModalButtons();
    const afterEditSubmitActions = () => {
      if (searchTriggered) this.searchProjects();
      this.handleModalClose();
    };
    const afterAddSubmitActions = () => {
      this.handleModalClose();
      this.handleSearchProjectsReset();
    };

    if (edit) {
      onEditProject(formData).then(afterEditSubmitActions);
    } else {
      onAddProject(formData).then(afterAddSubmitActions);
    }
  };

  handleRowClick = (project: Project) => {
    const { navigate, location } = this.props.router;
    navigate(`/projects/${project.id}${location.search}`);
  };

  disableProjectsModalButtons = () => {
    const { onUpdateModal } = this.props;
    onUpdateModal({ id: PROJECT_MODAL, disableButtons: true });
  };

  fetchProjectsDataForCurrentMonth = () => {
    const { onProjectSearch } = this.props;
    const searchDate = new Date();
    onProjectSearch(format(searchDate, 'yyyy'), format(searchDate, 'MM'));
  };

  render(): Node {
    const { modal, searchForm, searchTriggered } = this.state;
    const { currentUser, projects } = this.props;
    const { navigate } = this.props.router;
    const isAdmin = isUserAdmin(currentUser);
    const isOwner = isUserOwner(currentUser);

    return (
      <div className="container-view view-wrapper projects-view">
        <MainNav
          currentUser={currentUser}
          navigate={navigate}
        />
        <ProjectsContent
          isAdmin={isAdmin}
          isOwner={isOwner}
          projects={projects.projectsToDisplay}
          selectedYear={searchForm.year}
          selectedMonth={searchForm.month}
          onOpenAddProjectModal={this.handleOpenAddProjectModal}
          onOpenEditProjectModal={this.handleOpenEditProjectModal}
          onSelectMonthChange={this.handleSelectMonthChange}
          onSelectYearChange={this.handleSelectYearChange}
          onSearchProjectsSubmit={this.handleSearchProjectsSubmit}
          onSearchProjectsReset={this.handleSearchProjectsReset}
          onRowClick={this.handleRowClick}
          searchTriggered={searchTriggered}
        />
        <ReduxModal id={PROJECT_MODAL}>
          <ProjectsModal
            title={modal.title}
            edit={modal.edit}
            project={modal.project}
            saveButtonText={modal.saveButtonText}
            onProjectSubmit={this.handleProjectSubmit}
            onProjectDelete={this.handleProjectDelete}
            onCloseClick={this.handleModalClose}
          />
        </ReduxModal>
      </div>
    );
  }
}

export default (withRouter((Projects: React$ComponentType<Props>)): any);
