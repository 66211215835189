// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import AddLogButton from './AddLogButton';
import TimeLog from './TimeLog';
import TimeLogGhost from './TimeLogGhost';
// $FlowFixMe
import WatermarkHoliday from '../../../../assets/images/watermark_holiday.svg';
// $FlowFixMe
import WatermarkVacation from '../../../../assets/images/watermark_vacation.svg';
import type { Project, Log, Holiday } from '../../../types';

type Props = {
  onOpenAddModal: Function,
  onOpenEditModal: Function,
  onAddDraggedLog: Function,
  onAddLog: Function,
  draggedLog: Log,
  day: Object,
  logsById: Object,
  logIds: Object,
  projects: Array<Project>,
  onDragEnded: Function,
  onDragEntered: Function,
  dragTargetDate: string,
  holidays: Array<Holiday>,
  selectedUserVacationDates: Array<string>,
}

const DayColumn = (props: Props): Node => {
  const {
    onOpenAddModal,
    onOpenEditModal,
    day,
    logsById,
    logIds,
    projects,
    onAddDraggedLog,
    draggedLog,
    onAddLog,
    onDragEnded,
    onDragEntered,
    dragTargetDate,
    holidays,
    selectedUserVacationDates,
  } = props;
  const dailyTotal = _.sum(logIds.map(logId => _.toNumber(logsById[logId].time_spent)));
  const dailyTotalParsed = parseFloat(dailyTotal).toFixed(2);
  const isHoliday = holidays.filter(holiday => holiday.date === day.date).length > 0;
  const isVacation = selectedUserVacationDates.filter(vacation => vacation === day.date).length > 0;
  const isWorkday = day.dayName !== 'Sat' && day.dayName !== 'Sun' && !isHoliday && !isVacation;
  const hoursMissing = dailyTotal < 8;

  const droppingOnSelf = (el: Object) => {
    const logItem: HTMLElement = el.closest('.edit-log');
    if (logItem && logItem.dataset && _.toInteger(logItem.dataset.logId) === draggedLog.id) {
      return true;
    }

    return false;
  };

  const handleDropLog = (event: Object) => {
    event.persist();
    if (droppingOnSelf(event.target)) return;

    const formData = {
      project_id: draggedLog.project_id,
      category: draggedLog.category,
      time_spent: draggedLog.time_spent,
      description: draggedLog.description,
      date: props.day.date,
      user_id: draggedLog.user.id,
      overtime: draggedLog.overtime,
      task_id: draggedLog.task_id,
    };
    onAddLog(draggedLog.project_id, formData);
  };

  const showGost = dragTargetDate === day.date && draggedLog;

  return (
    <div
      data-day={day.date}
      className="col table-bordered text-center seven-cols height-limiter"
      onDrop={handleDropLog}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={onDragEntered}
      onDragEnd={onDragEnded}
    >
      <div className={`total-hours text-center ${isWorkday && hoursMissing ? 'hours-missing' : ''}`}>
        <h5>{`Hours: ${dailyTotal.toString().length >= 4 ? dailyTotalParsed : dailyTotal}h`}</h5>
      </div>
      <div className={`billable ${isHoliday ? 'holiday' : ''} ${isVacation ? 'vacation' : ''}`}>
        {isHoliday && (
          <div style={{ backgroundImage: `url(${WatermarkHoliday})` }} className="watermark" />
        )}
        {isVacation && (
          <div style={{ backgroundImage: `url(${WatermarkVacation})` }} className="watermark" />
        )}
        {logIds.map(logId => (
          <div key={logId}>
            {logsById[logId].project_billable && (
              <TimeLog
                day={day}
                onOpenEditModal={onOpenEditModal}
                onAddDraggedLog={onAddDraggedLog}
                projects={projects}
                log={logsById[logId]}
                key={logId}
              />
            )}
          </div>
        ))}
        {showGost && draggedLog.project_billable && (
          <TimeLogGhost
            projects={projects}
            log={draggedLog}
          />
        )}
      </div>
      <div className="non-billable">
        {showGost && !draggedLog.project_billable && (
          <TimeLogGhost
            projects={projects}
            log={draggedLog}
          />
        )}
        {logIds.map(logId => (
          <div key={logId}>
            {logsById[logId].project_billable === false && (
              <TimeLog
                day={day}
                onOpenEditModal={onOpenEditModal}
                projects={projects}
                log={logsById[logId]}
                key={logId}
                onAddDraggedLog={onAddDraggedLog}
              />
            )}
          </div>
        ))}
      </div>
      <AddLogButton onOpenAddModal={onOpenAddModal} id={day.date} />
    </div>
  );
};

export default DayColumn;
