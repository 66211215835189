// @flow
import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

// HOC COMPONENT
export const withRouter = (Component: any): any => {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        router={{
          location, navigate, params,
        }}
      />
    );
  }

  return (ComponentWithRouterProp: any);
};
