// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import Select from '../../../elements/Select';
import { getUserRolesForSelect, getProjectsForSelect } from '../../../../helpers';
import type { Project } from '../../../../types';
import Checkbox from '../../../elements/Checkbox';

type Props = {
  selectedRole: string,
  selectedProjectId: number,
  onSelectRoleChange: Function,
  onSelectProjectChange: Function,
  onSearchUsersSubmit: Function,
  onSearchUsersReset: Function,
  onInactiveChange: Function,
  onStudentChange: Function,
  inactive: boolean,
  student: boolean,
  roles: Array<any>,
  projects: Array<Project>,
};

class UsersTableSearch extends Component<Props, any> {
  handleSelectRoleChange = (event: Object) => {
    const { onSelectRoleChange } = this.props;
    onSelectRoleChange(event.target.value);
  };

  handleSelectProjectChange = (event: Object) => {
    const { onSelectProjectChange } = this.props;
    onSelectProjectChange(parseInt(event.target.value, 10));
  };

  handleSearchUsersSubmit = () => {
    const { onSearchUsersSubmit } = this.props;
    onSearchUsersSubmit();
  };

  handleSearchUsersReset = () => {
    const { onSearchUsersReset } = this.props;
    onSearchUsersReset();
  };

  handleInactiveShowChange = () => {
    const { onInactiveChange } = this.props;
    onInactiveChange();
  };

  handleStudentShowChange = () => {
    const { onStudentChange } = this.props;
    onStudentChange();
  };

  render(): Node {
    const {
      selectedRole, roles, selectedProjectId, projects, inactive, student,
    } = this.props;
    const selectRoles = _.sortBy(getUserRolesForSelect(roles), 'title');
    selectRoles.splice(0, 0, { id: 'any', title: 'any' });
    const selectProjects = getProjectsForSelect(projects);
    selectProjects.splice(0, 0, { id: 0, title: 'Any' });

    return (
      <div className="right-column">
        <div className="selects-wrapper">
          <div className="form-check">
            <Checkbox
              name="student"
              label="Show students only"
              id="only-students"
              checked={student}
              onChange={this.handleStudentShowChange}
            />
            <Checkbox
              name="inactive"
              label="Show inactive"
              id="add-inactive"
              checked={inactive}
              onChange={this.handleInactiveShowChange}
            />
          </div>
          <Select
            groupClass="project"
            selectClass="form-control"
            labelClass=""
            name="project"
            label="Select project:"
            id="project"
            items={selectProjects}
            value={selectedProjectId || 0}
            onSelectChange={this.handleSelectProjectChange}
          />
          <Select
            groupClass="role js-role"
            selectClass="form-control"
            labelClass=""
            name="role"
            label="Select role:"
            id="role"
            items={selectRoles}
            value={selectedRole || 'member'}
            onSelectChange={this.handleSelectRoleChange}
          />
        </div>
        <div className="buttons-wrapper">
          <button
            className="btn btn-primary search"
            type="button"
            onClick={this.handleSearchUsersSubmit}
          >
            Apply filter
          </button>
          <button
            className="btn btn-primary reset-button search"
            type="button"
            onClick={this.handleSearchUsersReset}
          >
            Reset filter
          </button>
        </div>
      </div>
    );
  }
}

export default (UsersTableSearch: React$ComponentType<Props>);
