// @flow
/* eslint camelcase: ["off"] */
import React, { Component } from 'react';
import Input from '../../elements/Input';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import ModalControls from '../modal/ModalControls';
import type { Holiday } from '../../../types';

type State = {
  form: {
    date: string,
    description: string,
  }
};

type Props = {
  onHolidaySubmit: Function,
  onHolidayDelete: Function,
  onCloseModal: Function,
  saveButtonText: string,
  title: string,
  edit: boolean,
  disableButtons: boolean,
  holiday: ?Holiday,
};

class HolidaysModal extends Component<Props, State> {
  static defaultProps = {
    disableButtons: false,
  };

  state = {
    form: {
      date: '',
      description: '',
    },
  };

  componentDidUpdate = (prevProps: Props) => {
    const { edit, title, holiday } = this.props;
    if (prevProps.edit !== edit || prevProps.title !== title) {
      if (edit && holiday) {
        const { date, description } = holiday;
        this.setState({
          form: {
            date,
            description,
          },
        });
      } else {
        this.setState({
          form: {
            date: '',
            description: '',
          },
        });
      }
    }
  };

  changeFormField = (field: string, value: any) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  };

  handleHolidayDateChange = (event: Object) => {
    this.changeFormField('date', event.target.value);
  };

  handleHolidayDescriptionChange = (event: Object) => {
    this.changeFormField('description', event.target.value);
  };

  isFormValid = () => {
    const { form: { date, description } } = this.state;

    return date !== '' && description !== '';
  };

  handleSubmit = () => {
    const { onHolidaySubmit, edit, holiday } = this.props;
    const { form: { date, description } } = this.state;
    const holidayData = { date, description };
    if (edit && holiday) {
      onHolidaySubmit({
        id: holiday.id,
        holiday: holidayData,
      });
    } else {
      onHolidaySubmit({
        holiday: { ...holidayData },
      });
    }
  };

  handelDeleteClick = () => {
    const { holiday, onHolidayDelete } = this.props;
    if (holiday) {
      onHolidayDelete(holiday.id);
    }
  };

  render() {
    const {
      saveButtonText, title, edit, disableButtons, onCloseModal,
    } = this.props;
    const { form } = this.state;

    return (
      <>
        <ModalHeader
          title={title}
          onCloseClick={onCloseModal}
        />
        <ModalBody>
          <form>
            <Input
              id="date"
              name="date"
              type="date"
              label="Date"
              value={form.date}
              onChange={this.handleHolidayDateChange}
            />
            <Input
              id="description"
              name="description"
              type="text"
              label="Description"
              value={form.description}
              onChange={this.handleHolidayDescriptionChange}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <ModalControls
            showEditButton={edit}
            disableButtons={disableButtons}
            isFormValid={!this.isFormValid()}
            onSubmitButtonClick={this.handleSubmit}
            onDeleteButtonClick={this.handelDeleteClick}
            onCloseButtonClick={onCloseModal}
            submitButtonText={saveButtonText}
            deleteButtonText="Delete holiday"
          />
        </ModalFooter>
      </>
    );
  }
}

export default (HolidaysModal: React$ComponentType<Props>);
