// @flow
import * as API from '../../api';
import {
  UPDATE_EMAIL_SUBSCRIPTIONS,
} from './types';
import type { Action, Subscription } from '../../types';

export const updateEmailSubscriptions = (subscriptions: Array<Subscription>): Action => ({
  type: UPDATE_EMAIL_SUBSCRIPTIONS,
  payload: API.updateEmailSubscriptions(subscriptions),
});
