// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  isCalendar: boolean,
  navDates: Object,
  onNavPrevWeekClick: Function,
  onNavNextWeekClick: Function,
}

const NavDatesSelector = (props: Props): Node => {
  const {
    isCalendar,
    navDates,
    onNavPrevWeekClick,
    onNavNextWeekClick,
  } = props;
  if (!isCalendar) {
    return <div className="invisible" />;
  }
  const { startOfWeek, endOfWeek, year } = navDates;

  return (
    <div className="date-nav-container">
      <button type="button" className="btn arrow-left js-prev-week" onClick={onNavPrevWeekClick}>
        <i className="fa fa-angle-left fa-2x" aria-hidden="true" />
      </button>
      <p className="navbar-text calendar-date">
        {`${startOfWeek} - ${endOfWeek}, ${year}`}
      </p>
      <button type="button" className="btn arrow-right js-next-week" onClick={onNavNextWeekClick}>
        <i className="fa fa-angle-right fa-2x" aria-hidden="true" />
      </button>
    </div>
  );
};

export default NavDatesSelector;
