// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import MainNav from '../blocks/navigation/MainNav';
import DateManagementContent from '../blocks/management/DateManagementContent';
import type { DateManagementProps } from '../../types';

class DateManagement extends Component<DateManagementProps> {
  render(): Node {
    const { currentUser, router } = this.props;

    return (
      <div className="container-view vacation-management-view view-wrapper has-custom-table">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        <DateManagementContent
          {...this.props}
        />
      </div>
    );
  }
}

export default (DateManagement: React$ComponentType<DateManagementProps>);
