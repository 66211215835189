// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { closeError, clearError } from '../../redux/actions/error';
import type { State } from '../../types';

type Props = {
  display: boolean,
  message: string,
  onCloseError: Function,
  onClearError: Function,
}

type InternalState = {
  display: boolean
};

class Error extends Component<Props, InternalState> {
  state = {
    display: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { display, onClearError } = this.props;
    if (prevProps.display !== display) {
      this.setDisplay(display);
      if (!display) setTimeout(onClearError, 200);
    }
  }

  setDisplay = (display: boolean) => this.setState({ display });

  render(): Node {
    const { message, onCloseError, display: globalDisplay } = this.props;
    const { display } = this.state;
    let classes = 'warning-alert fade';
    if (display) {
      classes += ' show';
    }
    if (!display && !globalDisplay && !message) {
      classes += ' d-none';
    }
    const messages = _.isArray(message) ? message : [message];

    return (
      <div className={classes}>
        <div className="alert alert-danger m-0" role="alert">
          <button onClick={onCloseError} type="button" className="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="alert-heading">Error has occured!</h4>
          {_.map(messages, (msg, i) => (
            <span key={msg}>
              {msg}
              {(++i) < messages.length && <br />}
            </span>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  display: state.error.display,
  message: state.error.message,
});

const mapDispatchToProps = (dispatch) => ({
  onCloseError: () => dispatch(closeError()),
  onClearError: () => dispatch(clearError()),
});

export default (connect(mapStateToProps, mapDispatchToProps)((Error: React$ComponentType<Props>)): any);
