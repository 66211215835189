// @flow
import camelCaseKeysDeep from 'camelcase-keys-deep';
import axios from './axios';
import type { Holiday, VacationsPayload } from '../types';

export function addHoliday(holiday: Holiday): Promise<any> {
  return axios.post('/holidays.json', holiday)
    .then(response => response.data);
}

export function editHoliday(holiday: Holiday): Promise<any> {
  return axios.put(`/holidays/${holiday.id}.json`, holiday)
    .then(response => response.data);
}

export function removeHoliday(holidayId: number): Promise<any> {
  return axios.delete(`/holidays/${holidayId}.json`)
    .then(response => response.data);
}

export function bulkSetVacations(userId: number, year: number, vacations: VacationsPayload): Promise<any> {
  return axios.post('/vacations/bulk_change.json', { user_id: userId, year, vacations })
    .then(response => camelCaseKeysDeep(response.data.user));
}

export function fetchHolidaysFromApi(year: number): Promise<Object> {
  return axios.post('/holidays/api_fetch.json', { year })
    .then(response => ({ holidays: response.data, year }));
}
