// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import HolidaysContent from './HolidaysContent';
import ReduxModal from '../modal/ReduxModal';
import HolidaysModal from './HolidaysModal';
import { HOLIDAY_MODAL } from '../../../redux/reducers/modal';
import type { Holiday } from '../../../types';

type State = {
  modal: {
    title: string,
    edit: boolean,
    saveButtonText: string,
    holiday: ?Holiday,
  },
};

type Props = {
  holidays: Array<Holiday>,
  onAddHoliday: Function,
  onEditHoliday: Function,
  onRemoveHoliday: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  onFetchHolidaysFromApi: Function,
};

class HolidayManagement extends Component<Props, State> {
  state: State = {
    modal: {
      title: '',
      edit: false,
      saveButtonText: '',
      holiday: null,
    },
  };

  handleEditModalOpen = (holiday: Holiday) => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: HOLIDAY_MODAL });
    this.setState({
      modal: {
        title: 'Edit holiday',
        edit: true,
        saveButtonText: 'Save',
        holiday,
      },
    });
  };

  handleModalClose = () => {
    const { onCloseModal } = this.props;
    onCloseModal({ id: HOLIDAY_MODAL });
    this.setState({
      modal: {
        display: false,
        title: '',
        edit: false,
        saveButtonText: '',
        holiday: null,
      },
    });
  };

  handleAddModalOpen = () => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: HOLIDAY_MODAL });
    this.setState({
      modal: {
        title: 'Add holiday',
        edit: false,
        saveButtonText: 'Save new holiday',
        holiday: null,
      },
    });
  };

  handleHolidaySubmit = (formData: Object) => {
    const { modal: { edit } } = this.state;
    const { onAddHoliday, onEditHoliday } = this.props;
    this.disableHolidaysModalButtons();
    const afterEditActions = () => {
      this.handleModalClose();
    };
    const afterAddActions = () => {
      this.handleModalClose();
    };
    if (edit) {
      onEditHoliday(formData).then(afterEditActions);
    } else {
      onAddHoliday(formData).then(afterAddActions);
    }
  };

  handleHolidayDelete = (holidayId: number) => {
    const { onRemoveHoliday } = this.props;
    this.disableHolidaysModalButtons();
    const afterRemoveActions = () => {
      this.handleModalClose();
    };
    onRemoveHoliday(holidayId).then(afterRemoveActions);
  };

  disableHolidaysModalButtons = () => {
    const { onUpdateModal } = this.props;
    onUpdateModal({
      id: HOLIDAY_MODAL,
      disableButtons: true,
    });
  };

  render(): Node {
    const { holidays, onFetchHolidaysFromApi } = this.props;
    const { modal } = this.state;

    return (
      <>
        <HolidaysContent
          holidays={holidays}
          onEditModalOpen={this.handleEditModalOpen}
          onAddModalOpen={this.handleAddModalOpen}
          onFetchHolidaysFromApi={onFetchHolidaysFromApi}
        />
        <ReduxModal id={HOLIDAY_MODAL}>
          <HolidaysModal
            title={modal.title}
            edit={modal.edit}
            saveButtonText={modal.saveButtonText}
            disableButtons={false}
            holiday={modal.holiday}
            onHolidaySubmit={this.handleHolidaySubmit}
            onHolidayDelete={this.handleHolidayDelete}
            onCloseModal={this.handleModalClose}
          />
        </ReduxModal>
      </>
    );
  }
}

export default (HolidayManagement: React$ComponentType<Props>);
