// @flow
import * as API from '../../api';
import {
  ADD_NOTIFICATIONS, CLEAR_NOTIFICATIONS, ADD_NOTIFICATION, CHECK_FOR_NOTIFICATIONS,
} from './types';
import type { Action } from '../../types';

export const getNotifications = (page: ?number = null, perPage: number = 10): Action => ({
  type: ADD_NOTIFICATIONS,
  payload: API.getNotifications(page, perPage),
});

export const addNotification = (target: string, content: string, sendTo: string|Array<string>): Action => ({
  type: ADD_NOTIFICATION,
  payload: API.addNotification(target, content, sendTo),
});

export const checkForNotifications = (): Action => ({
  type: CHECK_FOR_NOTIFICATIONS,
  payload: API.getNotifications(1, 10),
});

export const clearNotifications = (): Action => ({
  type: CLEAR_NOTIFICATIONS,
});
