// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import {
  filter, groupBy, isEmpty, map, reject, sumBy,
} from 'lodash';
import { displayNumber, isUserAdmin, isUserOwner } from '../../../helpers';
import type { Log, Project, User } from '../../../types';

type Props = {
  projects: Array<Project>,
  projectsHours: Object,
  onCloseClick: Function,
  logs: Log[],
  currentUser: User,
}

class MiniWeeklyStatistics extends Component<Props> {
  render(): Node {
    const {
      currentUser, onCloseClick, projects, projectsHours, logs,
    } = this.props;
    const activeProjects = filter(projects, 'active').filter((p) => projectsHours && projectsHours[p.id] > 0);
    const logsByTaskId = groupBy(reject(logs, (log) => isEmpty(log.task_id)), 'task_id');
    const isAdmin = isUserAdmin(currentUser);
    const isOwner = isUserOwner(currentUser);
    const isUser = !isOwner && !isAdmin;

    return (
      <div className="mini-stats-flex text-white d-none d-md-flex">
        <div className="mini-stats-wrapper">
          {isUser ? (
            <div className="flex-nowrap font-weight-bold mr-2">
              Weekly Hours <span className="font-weight-light">(by Task ID)</span>:
            </div>
          ) : (
            <div className="flex-nowrap font-weight-bold mr-2">
              Weekly Total Hours:
            </div>
          )}
          <div className="mini-stats-body">
            {isUser ? (
              map(logsByTaskId, (logGroup, taskId) => (
                <div key={taskId} className="ml-2">
                  <span className="project-title">{taskId}</span>
                  {`: ${sumBy(logGroup, 'time_spent')}h`}
                </div>
              ))
            ) : (
              map(activeProjects, (project) => (
                <div className="ml-2" key={project.id}>
                  <Link to={`/projects/${project.id}`}>
                    <span className="project-title">{project.title}</span>
                    {`: ${displayNumber(projectsHours[project.id])}h`}
                  </Link>
                </div>
              )))}
          </div>
          <div>
            <button
              type="button"
              className="close text-right text-white"
              aria-label="Close"
              onClick={onCloseClick}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default (MiniWeeklyStatistics: React$ComponentType<Props>);
