// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import DateManagementView from '../components/views/DateManagement';
import {
  editUser, bulkSetVacations, addHoliday, editHoliday, removeHoliday,
  openModal, closeModal, updateModal, fetchHolidaysFromApi,
} from '../redux/actions';
import { isUserClient } from '../helpers';
import { withRouter } from '../helpers/withRouter';
import type {
  State, User, VacationsPayload, Holiday, SingleModalState, DateManagementProps,
} from '../types';

class DateManagement extends Component<DateManagementProps> {
  render(): Node {
    return (
      <DateManagementView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  currentUser: state.currentUser,
  users: _.filter(state.users, user => !isUserClient(user)),
  roles: _.filter(state.roles, role => role[0] !== 'client'),
  holidays: state.holidays,
  modal: state.modal,
  vacations: state.vacations,
});

const mapDispatchToProps = dispatch => ({
  onEditUser: (user: User) => dispatch(editUser(user)),
  onBulkSetVacations: (userId: number, year: number, vacations: VacationsPayload) => dispatch(bulkSetVacations(userId, year, vacations)),
  onAddHoliday: (holiday: Holiday) => dispatch(addHoliday(holiday)),
  onEditHoliday: (holiday: Holiday) => dispatch(editHoliday(holiday)),
  onRemoveHoliday: (holidayId: number) => dispatch(removeHoliday(holidayId)),
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  onUpdateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
  onFetchHolidaysFromApi: (year: number) => dispatch(fetchHolidaysFromApi(year)),
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)((DateManagement: React$ComponentType<DateManagementProps>))): any);
