// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import ClientDashboardContent from '../blocks/client/ClientDashboardContent';
import MainNav from '../blocks/navigation/MainNav';
import Alert from '../elements/Alert';
import type {
  User, Project, ProjectStatistics, Router,
} from '../../types';
import { withRouter } from '../../helpers/withRouter';

type Props = {
  currentUser: User,
  navigate: Object,
  projectStatistics: ProjectStatistics,
  onGetProjectMonthStatistic: Function,
  onGetProjectMonthReportStatistic: Function,
  projects: Array<Project>,
  router: Router,
};

class ClientDashboard extends Component<Props> {
  render(): Node {
    const {
      onGetProjectMonthStatistic, currentUser,
      projectStatistics, projects, router, onGetProjectMonthReportStatistic,
    } = this.props;

    return (
      <div className="client-dashboard-view container-view">
        <MainNav
          currentUser={currentUser}
          navigate={router.navigate}
        />
        {projects && projects.length > 0
          ? (
            <ClientDashboardContent
              projectStatistics={projectStatistics}
              projects={projects}
              onGetProjectMonthStatistic={onGetProjectMonthStatistic}
              onGetProjectMonthReportStatistic={onGetProjectMonthReportStatistic}
              preSelectedProjectId={router?.params.id}
              navigate={router.navigate}
              currentUser={currentUser}
              router={router}
            />
          ) : (
            <div className="container mt-4">
              <Alert display message="You have not been added to any projects yet." />
            </div>
          )
        }
      </div>
    );
  }
}

export default (withRouter((ClientDashboard: React$ComponentType<Props>)): any);
