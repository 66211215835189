import type { ProjectRequest, Project } from '../types';
import axios from './axios';

export function addProject(project: ProjectRequest): Promise<any> {
  return axios.post('/projects.json', project)
    .then(response => response.data);
}

export function editProject(project: Project): Promise<any> {
  return axios.put(`/projects/${project.id}.json`, project, project)
    .then(response => response.data);
}

export function removeProject(projectId: number): Promise<any> {
  return axios.delete(`/projects/${projectId}.json`)
    .then(() => projectId);
}

export function projectSearch(year: number, month: number): Promise<any> {
  return axios.get(`/projects.json?month=${month}&year=${year}`)
    .then(response => ({ projects: response.data, year, month }));
}

export function getProjectMonthStatistic(year: number, month: number, projectId: number): Promise<any> {
  return axios.get(`/projects/${projectId}/monthly_stats.json`, { params: { year, month } })
    .then(response => ({ stats: response.data, year, month }));
}
