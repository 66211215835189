// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import type { Subscription } from '../../../../types';

type Props = {
  emailSubscription: Subscription,
  onSubscriptionChange: Function,
}

const EmailSubscriptionTableRow = (props : Props): Node => {
  const { emailSubscription, onSubscriptionChange } = props;
  const [subscription, setSubscription] = useState(emailSubscription);

  const handleChange = (key: string) => {
    const updatedSubscription = { ...subscription, [key]: !subscription[key] };
    setSubscription(updatedSubscription);
    onSubscriptionChange(updatedSubscription);
  };

  return (
    <div className="table-row">
      <div className='table-column'>
        <h4>{emailSubscription.fullName}</h4>
      </div>
      <div className='table-column'>
        <input
          type="checkbox"
          value={subscription.newClientRegistered}
          checked={subscription.newClientRegistered}
          onChange={() => handleChange('newClientRegistered')}
        />
      </div>
      <div className='table-column'>
        <input
          type="checkbox"
          value={subscription.reachingMonthlyLimit}
          checked={subscription.reachingMonthlyLimit}
          onChange={() => handleChange('reachingMonthlyLimit')}
        />
      </div>
      <div className='table-column'>
        <input
          type="checkbox"
          value={subscription.monthlyLimitChanged}
          checked={subscription.monthlyLimitChanged}
          onChange={() => handleChange('monthlyLimitChanged')}
        />
      </div>
      <div className='table-column'>
        <input
          type="checkbox"
          value={subscription.newVacationAdded}
          checked={subscription.newVacationAdded}
          onChange={() => handleChange('newVacationAdded')}
        />
      </div>
      <div className='table-column'>
        <input
          type="checkbox"
          value={subscription.weeklyProjectsReport}
          checked={subscription.weeklyProjectsReport}
          onChange={() => handleChange('weeklyProjectsReport')}
        />
      </div>
    </div>
  );
};

export default EmailSubscriptionTableRow;
