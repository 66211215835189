// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { format } from 'date-fns';
import ProjectsSearch from './ProjectsSearch';
import type { Project } from '../../../../types';

type Props = {
  onSelectMonthChange: Function,
  onSelectYearChange: Function,
  onSearchProjectsSubmit: Function,
  onSearchProjectsReset: Function,
  selectedYear: number,
  selectedMonth: number,
  searchTriggered: boolean,
  projects: Array<Project>,
  triggerSearch: Function,
  triggeredMonth: number,
  triggeredYear: number,
};

class ProjectTableContent extends Component<Props, any> {
  render(): Node {
    const {
      onSelectMonthChange, onSelectYearChange, onSearchProjectsSubmit,
      onSearchProjectsReset, selectedMonth, selectedYear,
      searchTriggered, projects, triggerSearch,
      triggeredMonth, triggeredYear,
    } = this.props;

    const billableArray = projects.map(project => project.billable && project.total_hours);
    const totalHoursArray = projects.map(project => project.total_hours);
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const totalBillable = parseFloat(billableArray.reduce(reducer)).toFixed(2);
    const totalHours = parseFloat(totalHoursArray.reduce(reducer)).toFixed(2);

    return (
      <div className="container container-header mt-4">
        <div className="upper-header-wrapper">
          <div className="left-column">
            <h2>Projects</h2>
            <hr className="float-left" />
            <p className="clearfix mb-4">List of all projects and total hours</p>
          </div>
          <ProjectsSearch
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            onSelectMonthChange={onSelectMonthChange}
            onSelectYearChange={onSelectYearChange}
            onSearchProjectsSubmit={onSearchProjectsSubmit}
            onSearchProjectsReset={onSearchProjectsReset}
            searchTriggered={searchTriggered}
            triggerSearch={triggerSearch}
          />
        </div>
        {searchTriggered ? (
          <>
            <div className="filter-result">
              <div className="date-wrapper">
                <div className="month pr-2">{`${format(new Date(new Date().getFullYear(), triggeredMonth - 1), 'MMMM')},`}</div>
                <div className="year">{triggeredYear}</div>
              </div>
            </div>
            <div className="total-hours-wrapper">
              <div className="total-hours">{totalHours}</div>
              &nbsp;/&nbsp;
              <div className="total-billable">{totalBillable}</div>
              &nbsp;h
            </div>
          </>
        ) : (
          <div className="filter-result">
            <div className="text-wrapper">All projects</div>
          </div>
        )}
      </div>
    );
  }
}

export default (ProjectTableContent: React$ComponentType<Props>);
