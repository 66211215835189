// @flow
import React, { Fragment } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import Alert from '../../elements/Alert';
import { displayNumber } from '../../../helpers';

type Props = {
  showTaskId: boolean,
  monthReport: { stats: {
    users: Array<Object>,
    categories: Object,
    logs: Array<Object>,
    project_total_hours: number,
    category_colors: Object,
  } },
};

const BreakdownTable = (props: Props): Node => {
  const { monthReport, showTaskId } = props;
  const userStats = _.sortBy(monthReport.stats.users, 'surname');
  const categoryStats = monthReport.stats.categories;
  const categoriesSorted = _.sortBy(_.keys(categoryStats));
  const categoryStatsTotal = _.sumBy(_.map(categoryStats));
  const logsByUser = _.groupBy(monthReport.stats.logs, 'user_id');
  const usersIds = Object.keys(logsByUser);
  const logsByUserByDay = {};

  usersIds.forEach(userId => {
    const logs = _.groupBy(logsByUser[userId], 'date');
    logsByUserByDay[userId] = Object.entries(logs).map((e) => ({ [e[0]]: e[1] }));
  });

  if (monthReport.stats.project_total_hours === 0) {
    return (
      <div className="col-12">
        <Alert display message="No statistics available." />
      </div>
    );
  }

  const getUserName = (userId: number) => {
    const user = _.find(monthReport.stats.users, ({ id: _.toInteger(userId) }));
    if (!user) return '';

    return `${user.name} ${user.surname}`;
  };

  const getCategoryIconAndName = (category: string) => {
    if (category && monthReport.stats.category_colors[category]) {
      const color = monthReport.stats.category_colors[category];

      return (
        <span>
          <i className="fa fa-circle mr-2" style={{ color }} aria-hidden="true" />
          {_.startCase(category)}
        </span>
      );
    }

    return null;
  };

  return (
    <>
      <div className="col-md-6">
        <table className="table mt-2">
          <thead className="thead-light">
            <tr className="table-project-header">
              <th>Developers on project</th>
              <th className="text-center">Total hours</th>
            </tr>
          </thead>
          <tbody>
            {userStats.map(user => (
              <tr key={user.id}>
                <td>
                  {`${user.name} ${user.surname}`}
                </td>
                <td className="text-right">
                  <span className="mr-3">
                    {`${displayNumber(user.total_hours)}h`}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col-md-6">
        <table className="table mt-2">
          <thead className="thead-light">
            <tr className="table-project-header">
              <th>
                <span className="device-big">Service </span>
                Category
              </th>
              <th>Percentage</th>
              <th className="text-center">
                <span className="device-big">Total </span>
                Hours
              </th>
            </tr>
          </thead>
          <tbody>
            {categoriesSorted.map((category) => (
              <tr key={category}>
                <td>
                  {getCategoryIconAndName(category)}
                </td>
                <td>
                  {Math.round((categoryStats[category] / categoryStatsTotal) * 100) + '%'}
                </td>
                <td className="text-right">
                  <span className="mr-3">
                    {`${displayNumber(categoryStats[category])}h`}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {userStats.map(user => (
        <div className="col-12" key={user.id}>
          <table className="table mt-2 user-breakdown-table">
            <thead className="thead-light">
              <tr>
                <th className="text-center">{getUserName(user.id)}</th>
              </tr>
              <tr className="table-project-header">
                <th className="date-heading">Date</th>
                <th className="desc-heading">Description of service</th>
                {showTaskId && <th className="device-big service-heading">Task ID</th>}
                <th className="device-big service-heading">Service category</th>
                <th className="text-center efforts-heading">
                  <span className="device-big">Efforts in </span>
                  h
                </th>
              </tr>
            </thead>
            <tbody>
              {logsByUserByDay[user.id].map(date => (
                date[Object.keys(date)[0]].map((log, ind) => (
                  <tr key={`${log.date}${Math.random()}`}>
                    <td>{(ind % 2 === 0) && (ind % 3 === 0) && (ind % 5 === 0) ? log.date : ''}</td>
                    <td className="log-cell-description">{log.description}</td>
                    {showTaskId && <td className="device-big">{log.task_id}</td>}
                    <td className="device-big">{getCategoryIconAndName(log.category)}</td>
                    <td className="text-right">{`${displayNumber(log.time_spent)}`}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </table>
          <div className='total-hours-container'>
            <span>Total hours</span>
            <div className="text-right table-secondary small-screen-total">{displayNumber(_.find(userStats, ['id', user.id]).total_hours)}</div>
            <div className="text-right table-secondary device-big d-md-none">{displayNumber(_.find(userStats, ['id', user.id]).total_hours)}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BreakdownTable;
