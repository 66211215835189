// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import AddLogButton from './AddLogButton';
import TimeLog from './TimeLog';
import type { Project, Holiday } from '../../../types';

type Props = {
  onOpenAddModal: Function,
  onOpenEditModal: Function,
  logsById: Object,
  logIds: Object,
  projects: Array<Project>,
  selectedDate: string,
  holidays: Array<Holiday>,
  selectedUserVacationDates: Array<string>,
}

const DayColumn = (props: Props): Node => {
  const {
    onOpenAddModal,
    onOpenEditModal,
    logsById,
    logIds,
    projects,
    selectedDate,
    holidays,
    selectedUserVacationDates,
  } = props;
  const dailyTotal = _.sum(logIds.map(logId => _.toNumber(logsById[logId].time_spent)));
  const dailyTotalParsed = parseFloat(dailyTotal).toFixed(2);
  const isHoliday = holidays.filter(holiday => holiday.date === selectedDate).length > 0;
  const isVacation = selectedUserVacationDates.filter(vacation => vacation === selectedDate).length > 0;
  const dayName = format(new Date(selectedDate), 'EEE');
  const isWorkday = dayName !== 'Sat' && dayName !== 'Sun' && !isHoliday && !isVacation;
  const hoursMissing = dailyTotal < 8;

  return (
    <div className="col table-bordered text-center">
      <div className={`total-hours text-center ${isWorkday && hoursMissing ? 'hours-missing' : ''}`}>
        <h5>{`Hours: ${dailyTotal.toString().length >= 4 ? dailyTotalParsed : dailyTotal}h`}</h5>
      </div>
      <div className={`billable ${isHoliday ? 'holiday' : ''} ${isVacation ? 'vacation' : ''}`}>
        {isHoliday && (
          <div className="watermark">HOLIDAY</div>
        )}
        {isVacation && (
          <div className="watermark">VACATION</div>
        )}
        {logIds.map(logId => (
          <div key={logId}>
            {logsById[logId].project_billable && (
              <TimeLog
                day={{ dateString: selectedDate }}
                onOpenEditModal={onOpenEditModal}
                projects={projects}
                log={logsById[logId]}
                key={logId}
              />
            )}
          </div>
        ))}
      </div>
      <div className="non-billable">
        {logIds.map(logId => (
          <div key={logId}>
            {logsById[logId].project_billable === false && (
              <TimeLog
                day={{ dateString: selectedDate }}
                onOpenEditModal={onOpenEditModal}
                projects={projects}
                log={logsById[logId]}
                key={logId}
              />
            )}
          </div>
        ))}
      </div>
      <AddLogButton onOpenAddModal={onOpenAddModal} id={selectedDate} />
    </div>
  );
};

export default DayColumn;
