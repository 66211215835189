// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import ClientDashboardView from '../components/views/ClientDashboard';
import {
  getProjectMonthStatistic, getProjectMonthReportStatistic,
} from '../redux/actions';
import { selectors } from '../redux/selectors/projects';
import type {
  State, User, Project, ProjectStatistics,
} from '../types';

type Props = {
  projects: Array<Project>,
  currentUser: User,
  navigate: Object,
  onGetProjectMonthStatistic: Function,
  onGetProjectMonthReportStatistic: Function,
  projectStatistics: ProjectStatistics,
  location: Object,
};

class ClientDashboard extends Component<Props> {
  render(): Node {
    return (
      <ClientDashboardView {...this.props} />
    );
  }
}

const mapStateToProps = (state: State) => ({
  currentUser: state.currentUser,
  projects: selectors.getDashboardProjects(state),
  projectStatistics: state.projectStatistics,
});

const mapDispatchToProps = dispatch => ({
  onGetProjectMonthStatistic: (year: number, month: number, projectId: number) => dispatch(getProjectMonthStatistic(year, month, projectId)),
  onGetProjectMonthReportStatistic: (year: number, month: number, projectId: number) => dispatch(getProjectMonthReportStatistic(year, month, projectId)),
});

export default (connect(mapStateToProps, mapDispatchToProps)((ClientDashboard: React$ComponentType<Props>)): any);
