// @flow
import _ from 'lodash';
import {
  OPEN_MODAL, CLOSE_MODAL, CLOSE_ERROR, UPDATE_MODAL,
} from '../actions/types';
import type { ModalState, ActionWithPayload } from '../../types';

export const defaultModalState: any = {
  display: false,
  disableButtons: false,
};

// Modal identifier constants
export const USER_MODAL = 'user-modal';
export const LOG_ADD_MODAL = 'add-modal';
export const LOG_EDIT_MODAL = 'edit-modal';
export const LOG_COPY_MODAL = 'copy-log-modal';
export const PROJECT_MODAL = 'project-modal';
export const SEND_NOTIFICATION_MODAL = 'send-notification';
export const HOLIDAY_MODAL = 'holiday-modal';
export const CONFIRM_MODAL = 'confirm-modal';

export function modalReducer(state: ModalState, action: ActionWithPayload): any {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [action.payload.id]: {
          ...action.payload,
          disableButtons: false,
          display: true,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [action.payload.id]: {
          ...defaultModalState,
        },
      };
    case UPDATE_MODAL:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      };
    case CLOSE_ERROR:
      const incomingState = { ...state };
      const activeModals = _.keys(_.pickBy(incomingState, { display: true }));
      _.forEach(activeModals, m => _.set(incomingState, `${m}.disableButtons`, false));

      return {
        ...incomingState,
      };
    default:
      return state || {};
  }
}
