// @flow
import React from 'react';
import type { Node } from 'react';
import type { User } from '../../../../types';

type Props = {
  user: User,
  onEditModalOpen: Function,
}

const UsersTableRow = (props: Props): Node => {
  const { user, onEditModalOpen } = props;

  return (
    <div
      className="user-row"
      data-toggle="modal"
      data-target="#edit-user"
      onClick={() => onEditModalOpen(user)}
    >
      <div className="user-full-name w-100">
        <h4 className="user-name" id="user-name-2">{user.name}</h4>
        <h4 className="user-surname" id="user-surname-2">{user.student ? user.surname + ' (student)' : user.surname}</h4>
      </div>
      <div className="role-column">
        <h4 className="user-role" id="user-role-2">{user.role}</h4>
      </div>
    </div>
  );
};

export default UsersTableRow;
