// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  display: boolean,
  message: string,
  type?: string,
}

const Alert = (props: Props): Node => {
  const { message, display, type } = props;
  let classes = `alert alert-${type || 'primary'} fade show`;
  if (!display) {
    classes += ' d-none';
  }

  return (
    <div className={classes}>
      {message}
    </div>
  );
};

export default Alert;
