// @flow
import * as API from '../../api';
import {
  ADD_HOLIDAY,
  EDIT_HOLIDAY,
  REMOVE_HOLIDAY,
  FETCH_HOLIDAYS_API,
} from './types';
import type { Action, Holiday } from '../../types';

export const addHoliday = (holiday: Holiday): Action => ({
  type: ADD_HOLIDAY,
  payload: API.addHoliday(holiday),
});

export const editHoliday = (holiday: Holiday): Action => ({
  type: EDIT_HOLIDAY,
  payload: API.editHoliday(holiday),
});

export const removeHoliday = (holidayId: number): Action => ({
  type: REMOVE_HOLIDAY,
  payload: API.removeHoliday(holidayId),
});

export const fetchHolidaysFromApi = (year: number): Action => ({
  type: FETCH_HOLIDAYS_API,
  payload: API.fetchHolidaysFromApi(year),
});
