// @flow
import _ from 'lodash';
import type { ActionWithPayload, User } from '../../types';
import {
  REQUEST_LOGS_FULFILLED,
  CHANGE_SELECTED_USER_FULFILLED,
  CHANGE_DATES,
  PROJECT_SEARCH_FULFILLED,
  ADD_PROJECT_FULFILLED,
  EDIT_PROJECT_FULFILLED,
  REMOVE_PROJECT_FULFILLED,
  ADD_PROJECT_MONTH_STATISTIC_FULFILLED,
  ADD_PROJECT_MONTH_REPORT_STATISTIC_FULFILLED,
  ADD_USER_FULFILLED,
  EDIT_USER_FULFILLED,
  ADD_LOG_FULFILLED,
  UPDATE_LOG_FULFILLED,
  REMOVE_LOG_FULFILLED,
  BULK_SET_VACATIONS_FULFILLED,
  ADD_OVERTIME_REPORT_FULFILLED,
} from '../actions/types';
import { formatDateToISOString } from '../../helpers/dates';
import { getRequestWeekForUser, getNormalizedProjectStatistics } from '../../helpers';

export function selectedUserReducer(state: ?User = null, action: ActionWithPayload): any {
  switch (action.type) {
    case CHANGE_SELECTED_USER_FULFILLED:
      return action.payload;
    default:
      return state;
  }
}

export function datesReducer(state: Array<string> = [], action: ActionWithPayload): Array<string> {
  switch (action.type) {
    case REQUEST_LOGS_FULFILLED:
      return action.payload.logs.map(day => formatDateToISOString(new Date(day.date)));
    case CHANGE_DATES:
      return action.payload;
    default:
      return state;
  }
}

export function daysInWeekReducer(state: Array<Object> = [], action: ActionWithPayload): Array<Object> {
  switch (action.type) {
    case REQUEST_LOGS_FULFILLED:
      return action.payload.logs;
    default:
      return state;
  }
}

export function currentUserReducers(state: ?User = null, action: ActionWithPayload): any {
  switch (action.type) {
    case EDIT_USER_FULFILLED:
      // change currentUser on User edit only on profile edit
      if (state && state.id === action.payload.id) {
        return { ...state, ...action.payload };
      }

      return state;
    default:
      return state;
  }
}

function addCalendarRequest(state: ?Object = null, payload) {
  const { logs, selectedUserId } = payload;

  if (!state) {
    return { ...state };
  }

  return {
    ...state,
    calendar: {
      ...state.calendar,
      [getRequestWeekForUser(new Date(logs[0].date), selectedUserId)]: new Date(),
    },
  };
}

function addProjectsRequest(state: ?Object = null, year: number, month: number) {
  if (!state) {
    return { ...state };
  }

  return {
    ...state,
    projects: {
      ...state.projects,
      [`${month}-${year}`]: new Date(),
    },
  };
}

export function requestsReducer(state: ?Object = null, action: ActionWithPayload): Object {
  switch (action.type) {
    case REQUEST_LOGS_FULFILLED:
      return addCalendarRequest(state, action.payload);
    case PROJECT_SEARCH_FULFILLED:
      return addProjectsRequest(state, action.payload.year, action.payload.month);
    default:
      return state || {};
  }
}

function addProjectDropdown(state: Array<Object>, newProject: Object) {
  return [
    ...state,
    newProject,
  ];
}

function editProjectDropdown(state: Array<Object>, editedProject: Object) {
  const updatedState = [..._.filter(state, p => p.id !== editedProject.id), editedProject];

  return updatedState;
}

function removeProjectDropdown(state: Array<Object>, removedProjectId: number) {
  const remainingprojects = _.filter(state, p => p.id !== removedProjectId);

  return remainingprojects;
}

export function rolesReducer(state: Array<Object> = [], action: ActionWithPayload): Array<Object> {
  switch (action.type) {
    default:
      return state;
  }
}

export function projectsDropdownReducer(state: Array<Object> = [], action: ActionWithPayload): Array<Object> {
  switch (action.type) {
    case ADD_PROJECT_FULFILLED:
      return addProjectDropdown(state, action.payload);
    case EDIT_PROJECT_FULFILLED:
      return editProjectDropdown(state, action.payload);
    case REMOVE_PROJECT_FULFILLED:
      return removeProjectDropdown(state, action.payload);
    default:
      return state;
  }
}

function addProjectMonthStatistics(state: Object, payload: any) {
  return {
    ...state,
    ...getNormalizedProjectStatistics(payload.stats, payload.month, payload.year),
  };
}

export function projectStatisticReducer(state: Object, action: ActionWithPayload): Object {
  switch (action.type) {
    case ADD_PROJECT_MONTH_STATISTIC_FULFILLED:
      return addProjectMonthStatistics(state, action.payload);
    case ADD_PROJECT_MONTH_REPORT_STATISTIC_FULFILLED:
      return { ...state, monthReport: action.payload };
    default:
      return state || {};
  }
}

export function categoriesReducer(state: Object): Object {
  return state || {};
}

export function projectsWeeklyHoursReducers(state: Object, action: ActionWithPayload): Object {
  const { payload } = action;

  switch (action.type) {
    case ADD_LOG_FULFILLED: case UPDATE_LOG_FULFILLED: case REMOVE_LOG_FULFILLED:
      return { ...state, ...payload.projects_weekly_hours };
    case REQUEST_LOGS_FULFILLED:
      return { ...state, ...payload.projectsWeeklyHours };
    default:
      return state || [];
  }
}

export function vacationsReducer(state: Object, action: ActionWithPayload): Object {
  const updateState = (orgState: Object, userId: Number, payload: any) => ({
    ...orgState,
    [userId.toString()]: payload,
  });

  switch (action.type) {
    case ADD_USER_FULFILLED:
      return updateState(state, action.payload.vacations.id, action.payload.vacations);
    case EDIT_USER_FULFILLED:
      return updateState(state, action.payload.vacations.id, action.payload.vacations);
    case BULK_SET_VACATIONS_FULFILLED:
      return updateState(state, action.payload.id, action.payload);
    default:
      return state || {};
  }
}

export function overtimeReducer(state: Object, action: ActionWithPayload): Object {
  if (action && action.type === ADD_OVERTIME_REPORT_FULFILLED) {
    return action.payload;
  }

  return state || {};
}
