// @flow
import _ from 'lodash';
import { CLOSE_ERROR, CLEAR_ERROR } from '../actions/types';
import type { Error, Action } from '../../types';

const getErrorMessage = (payload: any): string[]|string => {
  if (payload.response && payload.response.data && payload.response.data.errors) {
    // eslint-disable-next-line prefer-destructuring
    const errors = payload.response.data.errors;

    return _.keys(errors).map(key => `${key} ${errors[key]}`);
  }

  return payload.message;
};

export function errorReducer(state: Error, action: Action): Object {
  const { type, payload } = action;
  if (_.isEmpty(state)) {
    state = {
      display: false,
      message: '',
      type: '',
    };
  }
  if (type.includes('_REJECTED')) {
    return {
      display: true,
      message: getErrorMessage(payload),
      type,
    };
  }
  if (type === CLOSE_ERROR) {
    return {
      ...state,
      display: false,
    };
  }
  if (type === CLEAR_ERROR) {
    return {
      display: false,
      message: '',
      type: '',
    };
  }

  return state;
}
