// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  display: boolean,
}

const Loader = (props: Props): Node => {
  const { display } = props;
  let classes = 'loader-wrapper';
  if (!display) {
    classes += ' d-none';
  }

  return (
    <div className={classes}>
      <div className="loader" />
    </div>
  );
};

export default Loader;
