// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import CalendarLib from 'rc-year-calendar';
import { addDays, isSameDay } from 'date-fns';
import _ from 'lodash';
import { formatDateToISOString, isSameOrBefore } from '../../../helpers/dates';

type DatesSource = {
  startDate: Date,
  endDate: Date,
  date: string,
  color: string,
  persisted?: boolean,
  locked?: boolean,
}

type Props = {
  selectedYear: number,
  selectedDate?: string,
  dates: Array<{ date: string, locked: boolean, hoursMissing: boolean, isVacation: boolean, isHoliday: boolean }>,
  displayHeader?: boolean,
  disabledDays?: Array<Date>,
  weekStart?: number,
  isYearCalendar?: boolean,
  onDateSelect?: Function,
  onAcceptChange?: Function,
};

type State = {
  dataSource: Array<DatesSource>,
};

class Calendar extends Component<Props, State> {
  static defaultProps: Object = {
    displayHeader: false,
    disabledDays: [],
    weekStart: 1,
    selectedDate: '',
    onDateSelect: () => {},
    isYearCalendar: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      dataSource: this.getDatesAsDateSourse(props.dates),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const {
      selectedYear, dates, selectedDate, isYearCalendar,
    } = this.props;
    if (isYearCalendar) {
      const firstDatePrev = _.first(prevProps.dates) || { date: '' };
      const firstDate = _.first(dates) || { date: '' };
      if (firstDatePrev.date !== firstDate.date || prevProps.dates.length !== dates.length) {
        this.handleReset();
      }
      if (prevProps.selectedDate !== selectedDate) {
        this.handleReset();
      }
    } else {
      if (prevProps.selectedYear !== selectedYear) {
        this.handleYearChange();
      }
      if (_.sumBy(prevProps.dates, 'locked') !== _.sumBy(dates, 'locked')) {
        this.handleReset();
      }
    }
  }

  handleYearChange = () => {
    const { dates } = this.props;
    this.setState({ dataSource: this.getDatesAsDateSourse(dates) });
  };

  getDatesAsDateSourse = (dates: Array<any>) => dates.map(e => {
    const handleColor = (): string => {
      if (e.locked) return '#007bff';
      if (e.isVacation) return '#00802b80';
      if (e.isHoliday) return '#fcdb0880';
      if (e.hoursMissing) return '#fd7d1499';

      return '#4253af80';
    };

    return {
      startDate: new Date(e.date),
      endDate: new Date(e.date),
      color: handleColor(),
      date: e.date,
    };
  });

  getDateAsDateSource = (date: string) => [{
    startDate: new Date(date),
    endDate: new Date(date),
    color: '#4253af',
    date,
  }];

  handleDatesChange = (e: { startDate: string, endDate: string}) => {
    let { dataSource } = this.state;
    let startDate = new Date(e.startDate);
    const endDate = new Date(e.endDate);
    let values = [formatDateToISOString(startDate)];
    if (!isSameDay(startDate, endDate)) {
      startDate = addDays(startDate, 1);
      while (isSameOrBefore(startDate, endDate)) {
        values.push(formatDateToISOString(startDate));
        startDate = addDays(startDate, 1);
      }
      const totalAlreadyOnCalendar = _.sumBy(values, t => dataSource.map(r => r.date).includes(t));
      if (totalAlreadyOnCalendar === values.length) {
        dataSource = _.filter(dataSource, l => !values.includes(l.date));
        values = [];
      }
    } else {
      const clickedOnExisting = _.find(dataSource, (el) => isSameDay(new Date(el.startDate), startDate));
      if (clickedOnExisting) {
        values = [];
        dataSource = _.filter(dataSource, l => !isSameDay(new Date(l.startDate), startDate));
      }
    }
    const newCalendarData = [...dataSource, ...this.getDatesAsDateSourse(values.map(d => ({ date: d })))];
    this.setState({
      dataSource: _.uniqBy(newCalendarData, 'date'),
    });
  };

  handleAcceptChange = () => {
    const { dataSource } = this.state;
    const { dates, onAcceptChange } = this.props;
    const markedDates = _.map(dataSource, 'date');
    const originalDates = _.map(dates, 'date');
    const deleted = _.filter(originalDates, d => !markedDates.includes(d));
    const added = _.filter(markedDates, d => !deleted.includes(d) && !originalDates.includes(d));
    const tolock = _.filter(dates, d => !d.locked && !deleted.includes(d.date));
    const asPayload = (date: string) => ({ date });
    const payload = {
      deleted: _.map(deleted, d => asPayload(d)),
      added: _.map(added, a => asPayload(a)),
      locked: _.map(tolock, a => ({ date: a.date, locked: true, id: a.id })),
    };
    if (onAcceptChange) onAcceptChange(payload);
  };

  handleReset = () => {
    const { dates } = this.props;
    this.setState({ dataSource: this.getDatesAsDateSourse(dates) });
  };

  handleDayClick = (event: Object) => {
    const { onDateSelect } = this.props;
    if (onDateSelect) onDateSelect(event);
  };

  render(): Node {
    const {
      selectedYear, displayHeader, disabledDays, weekStart, isYearCalendar,
    } = this.props;
    const { dataSource } = this.state;

    return (
      <>
        {!isYearCalendar && (
          <div className="d-flex flex-row-reverse w-100 p-1">
            <button type="button" className="btn btn-primary primary-color mr-2" onClick={this.handleAcceptChange}>Accept</button>
            <button type="button" className="btn btn-secondary mr-2" onClick={this.handleReset}>Reset</button>
          </div>
        )}
        {isYearCalendar ? (
          <CalendarLib
            weekStart={weekStart}
            dataSource={dataSource}
            year={_.toInteger(selectedYear)}
            onDayClick={this.handleDayClick}
            // eslint-disable-next-line react/style-prop-object
            style="background"
            displayHeader={displayHeader}
          />
        ) : (
          <CalendarLib
            weekStart={weekStart}
            dataSource={dataSource}
            year={selectedYear}
            enableRangeSelection
            onRangeSelected={this.handleDatesChange}
            // eslint-disable-next-line react/style-prop-object
            style="background"
            displayHeader={displayHeader}
            disabledDays={disabledDays}
            displayDisabledDataSource
          />
        )}
      </>
    );
  }
}

export default (Calendar: React$ComponentType<Props>);
