// @flow
import React from 'react';
import type { Node } from 'react';
import NavToday from '../../elements/NavToday';
import NavDatesSelector from './NavDatesSelector';
import NavCollapse from './NavCollapse';
import { isUserAdmin, isUserOwner } from '../../../helpers';
import UserSelect from '../../elements/UserSelect';
import type { NavProps } from '../../../types';

const CalendarNavSmallScreen = (props: NavProps): Node => {
  const {
    navDates,
    isCalendar,
    currentUser,
    users,
    selectedUser,
    onSelectedUserChange,
    onNavTodayClick,
    onNavPrevWeekClick,
    onNavNextWeekClick,
  } = props;
  const isAdmin = isUserAdmin(currentUser);
  const isOwner = isUserOwner(currentUser);

  return (
    <>
      <div className="container-fluid device-small-menu">
        <div className="row">
          <nav className="navbar navbar-default nav-flex-small">
            <div className="today-btn-wrapper">
              <NavToday isCalendar onNavTodayClick={onNavTodayClick} />
            </div>
            <NavDatesSelector
              isCalendar={isCalendar || false}
              navDates={navDates}
              onNavPrevWeekClick={onNavPrevWeekClick}
              onNavNextWeekClick={onNavNextWeekClick}
            />
            <div className="nav-right">
              <button className="btn menu-bars" type="button" data-toggle="collapse" data-target="#menu" aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa fa-bars fa-2x" aria-hidden="true" />
              </button>
            </div>
          </nav>
        </div>
      </div>
      {isAdmin || isOwner ? (
        <div className="user-select-wrapper">
          <UserSelect
            users={users}
            selectedUser={selectedUser}
            onSelectedUserChange={onSelectedUserChange}
          />
        </div>
      ) : null}
      <NavCollapse isAdmin={isAdmin} isOwner={isOwner} users={users} selectedUser={selectedUser} onSelectedUserChange={onSelectedUserChange} />
    </>
  );
};

export default CalendarNavSmallScreen;
