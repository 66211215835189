// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  showEditButton: boolean,
  isFormValid?: boolean,
  disableButtons: boolean,
  onSubmitButtonClick: Function,
  onDeleteButtonClick?: Function,
  onCloseButtonClick: Function,
  submitButtonText: string,
  deleteButtonText?: string,
}

const ModalControls = (props: Props): Node => {
  const {
    disableButtons, showEditButton, onSubmitButtonClick, onDeleteButtonClick, isFormValid,
    submitButtonText, deleteButtonText, onCloseButtonClick,
  } = props;

  return (
    <>
      {showEditButton ? (
        <button
          type="button"
          className="btn btn-primary secondary-color"
          style={{ position: 'absolute', left: 17 }}
          disabled={disableButtons}
          onClick={onDeleteButtonClick}
        >
          {deleteButtonText}
        </button>
      ) : null}
      <button
        type="button"
        className="btn btn-secondary js-close"
        onClick={onCloseButtonClick}
      >
        Close
      </button>
      <button
        type="button"
        className="btn btn-primary primary-color"
        disabled={isFormValid || disableButtons}
        onClick={onSubmitButtonClick}
      >
        {submitButtonText}
      </button>
    </>
  );
};

export default ModalControls;
