// @flow
import * as API from '../../api';
import {
  ADD_PROJECT,
  EDIT_PROJECT,
  REMOVE_PROJECT,
  PROJECT_SEARCH,
  PROJECTS_DATE_CHANGED,
  ADD_PROJECT_MONTH_STATISTIC,
} from './types';
import type { ProjectRequest, Project, Action } from '../../types';

export const addProject = (project: ProjectRequest): Action => ({
  type: ADD_PROJECT,
  payload: API.addProject(project),
});

export const editProject = (project: Project): Action => ({
  type: EDIT_PROJECT,
  payload: API.editProject(project),
});

export const removeProject = (projectId: number): Action => ({
  type: REMOVE_PROJECT,
  payload: API.removeProject(projectId),
});

export const projectSearch = (year: number, month: number): Action => ({
  type: PROJECT_SEARCH,
  payload: API.projectSearch(year, month),
});

export const datesChanged = (year: number, month: number): Action => ({
  type: PROJECTS_DATE_CHANGED,
  payload: { year, month },
});

export const getProjectMonthStatistic = (year: number, month: number, projectId: number): Action => ({
  type: ADD_PROJECT_MONTH_STATISTIC,
  payload: API.getProjectMonthStatistic(year, month, projectId),
});
