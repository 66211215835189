// @flow
import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import StaticLoader from './StaticLoader';
import type { State } from '../../types';

type Props = {
  display: boolean,
}

const Loader = (props: Props): Node => {
  const { display } = props;

  return (
    <StaticLoader display={display} />
  );
};

const mapStateToProps = (state: State) => ({
  display: state.displayLoader,
});

export default (connect(mapStateToProps)((Loader: React$ComponentType<Props>)): any);
