// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import EmailSubscriptionsView from '../components/views/EmailSubscriptions';
import { updateEmailSubscriptions } from '../redux/actions';
import type { EmailSubscriptionsProps, State } from '../types';
import { withRouter } from '../helpers/withRouter';

class EmailSubscriptions extends Component<EmailSubscriptionsProps> {
  render(): Node {
    return (
      <EmailSubscriptionsView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  currentUser: state.currentUser,
  subscriptions: state.subscriptions,
});

const mapDispatchToProps = dispatch => ({
  onUpdateEmailSubscriptions: (subscriptions: any) => dispatch(updateEmailSubscriptions(subscriptions)),
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)((EmailSubscriptions: React$ComponentType<EmailSubscriptionsProps>))): any);
