// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import Select from '../../elements/Select';
import ReactSelect from '../../elements/ReactSelect';
import ReportForm from './ReportForm';
import { getMonthsForReactSelect, getYearsForSelect } from '../../../helpers';
import type { Project } from '../../../types';

type State = {
  form: {
    projectIds: Array<number|string>,
    months: Array<number|string>,
    year: number,
  },
}

type Props = {
  projects: Array<Project>,
};

class ReportsContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        projectIds: [],
        months: [new Date().getMonth() + 1],
        year: new Date().getFullYear(),
      },
    };
  }

  changeFormState = (field: string, value: any) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [field]: value,
      },
    });
  };

  handleSelectProjectChange = (data: Object|null) => {
    data = data || [];
    this.changeFormState('projectIds', data.map(p => p.value));
  };

  handleSelectMonthChange = (data: Array<{ value: number }>) => {
    data = data || [];
    this.changeFormState('month', data.map(m => m.value));
  };

  handleSelectYearChange = (event: Object) => {
    this.changeFormState('year', event.target.value);
  };

  render(): Node {
    const { projects } = this.props;
    const { form } = this.state;
    const currentMonth = new Date().getMonth();
    const selectMonths = getMonthsForReactSelect();
    const selectYears = getYearsForSelect();
    const projectsForReactSelect = projects.map((p) => ({ value: `${p.id}`, label: `${p.title}` }));

    return (
      <div className="container container-header mt-4">
        <div className="upper-header-wrapper">
          <div className="left-column">
            <h2>Reports</h2>
            <hr className="float-left" />
            <p className="clearfix mb-4">Generate report based on:</p>
          </div>
        </div>
        <ReportForm>
          <ReactSelect
            id="projectIds"
            name="projectIds[]"
            label="Projects"
            defaultValue={[]}
            options={projectsForReactSelect}
            onChange={this.handleSelectProjectChange}
          />
          <br />
          <ReactSelect
            id="months"
            name="months[]"
            label="Months"
            defaultValue={selectMonths[currentMonth]}
            options={selectMonths}
            onChange={this.handleSelectMonthChange}
          />

          <br />
          <Select
            id="year"
            name="year"
            label="Year:"
            selectClass="form-control"
            value={form.year}
            items={selectYears}
            onSelectChange={this.handleSelectYearChange}
          />
          <br />
        </ReportForm>
      </div>
    );
  }
}

export default (ReportsContent: React$ComponentType<Props>);
