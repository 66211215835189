// @flow
import _ from 'lodash';
import {
  ADD_PROJECT_FULFILLED, EDIT_PROJECT_FULFILLED, REMOVE_PROJECT_FULFILLED, PROJECT_SEARCH_FULFILLED, PROJECTS_DATE_CHANGED,
} from '../actions/types';
import type { ActionWithPayload, Project, Projects } from '../../types';
import { getNormalizedProjects } from '../../helpers';

function addProject(projects: Projects, newProject: Project) {
  return {
    items: [...projects.items, newProject],
    projectsById: { ...projects.projectsById, [newProject.id]: newProject },
    projectsToDisplay: [...projects.items, newProject],
  };
}

function editProject(projects: Projects, editedProject: Project) {
  const items = [..._.filter(projects.items, p => p.id !== editedProject.id), editedProject];

  return {
    items,
    projectsById: { ...projects.projectsById, [editedProject.id]: editedProject },
    projectsToDisplay: [...items],
  };
}

function removeProject(projects: Projects, removedProjectId: number) {
  const items = _.filter(projects.items, p => p.id !== removedProjectId);

  return {
    items,
    projectsById: _.omit(projects.projectsById, removedProjectId),
    projectsToDisplay: [...items],
  };
}

function getProjectsToDisplay(projects: Array<Project>, year: number, month: number) {
  let displayMonth: any = _.toInteger(month);
  if (displayMonth < 10) {
    displayMonth = `0${displayMonth}`;
  }

  return projects;
}

function addAdditionalProjects(projects: Projects, additionalProjects: Projects, year: number, month: number) {
  if (!additionalProjects.items.length) {
    return {
      ...projects,
      projectsToDisplay: getProjectsToDisplay(projects.items, year, month),
    };
  }

  const additionalProjectIds = _.flatMap(additionalProjects.items, 'id');
  let items = _.filter(projects.items, p => !_.includes(additionalProjectIds, p.id));
  items = [...items, ...additionalProjects.items];

  return {
    items,
    projectsById: {
      ...projects.projectsById, ...additionalProjects.projectsById,
    },
    projectsToDisplay: getProjectsToDisplay(items, year, month),
  };
}

const defaultProject = {
  items: [],
  projectsById: {},
  projectsToDisplay: [],
};

export function projectsReducer(state: Projects = defaultProject, action: ActionWithPayload): Projects {
  switch (action.type) {
    case ADD_PROJECT_FULFILLED:
      return addProject(state, action.payload);
    case EDIT_PROJECT_FULFILLED:
      return editProject(state, action.payload);
    case REMOVE_PROJECT_FULFILLED:
      return removeProject(state, action.payload);
    case PROJECT_SEARCH_FULFILLED:
      return addAdditionalProjects(state, getNormalizedProjects(action.payload.projects), action.payload.year, action.payload.month);
    case PROJECTS_DATE_CHANGED:
      return addAdditionalProjects(state, defaultProject, action.payload.year, action.payload.month);
    default:
      return state;
  }
}
