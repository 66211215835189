// @flow
import React, { type Node } from 'react';

type Props = {
  children?: Node,
  months?: string[],
  projectId?: string[],
  year?: number,
}

const ReportForm = (props: Props): Node => {
  const {
    children, months, year, projectId,
  } = props;
  const formInputs = children || (
    <>
      <input name="year" type="hidden" value={year} />
      <input name="months[]" type="hidden" value={months} />
      <input name="projectIds[]" type="hidden" value={projectId} />
    </>
  );
  const setAction = (action: string) => {
    const form = document.querySelector('#reports_form');
    if (form) {
      form.setAttribute('action', `/report/generate.${action}`);
    }
  };

  return (
    <form id='reports_form' acceptCharset="UTF-8" method="get">
      <input name="utf8" type="hidden" value="✓" />
      {formInputs}
      <input
        type="submit"
        name="commit"
        value="Download as PDF"
        className="btn btn-default btn-primary secondary-color float-right mb-3 ml-3"
        formTarget="_blank"
        onClick={() => setAction('pdf')}
      />
      <input
        type="submit"
        name="commit"
        value="Download as XLS"
        className="btn btn-default btn-primary secondary-color float-right"
        formTarget="_blank"
        onClick={() => setAction('xlsx')}
      />
    </form>
  );
};

export default ReportForm;
