// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  isCalendar: boolean,
  onNavTodayClick?: Function,
}

const NavToday = (props: Props): Node => {
  const { isCalendar, onNavTodayClick } = props;
  if (!isCalendar) {
    return (
      <button type="button" className="btn today-btn js-curr-week invisible">Today</button>
    );
  }

  return (
    <button
      type="button"
      className="btn today-btn js-curr-week"
      onClick={() => onNavTodayClick && onNavTodayClick('to-today')}
    >
      Today
    </button>
  );
};

export default NavToday;
