// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  logs: Array<any>,
  trigger?: string,
  projects: Array<any>,
};

const OvertimeLogsPopover = (props: Props): Node => {
  const { logs, trigger, projects } = props;
  const popoverTrigger = trigger || 'hover';
  const getProjectTitle = (id: number) => projects[id].title;
  const logsHTML = logs.map(log => `
    <div class="popover-row">
      <span class="popover-cell">${getProjectTitle(log.project_id)}</span>
      <span class="popover-cell">${log.date}</span>
      <span class="popover-cell">${log.time_spent}</span>
    </div>
  `).join('');
  const popoverContent = `<div class="popover-table">${logsHTML}</div>`;

  return (
    <i
      className="overtime-info fa fa-info-circle"
      data-toggle="popover"
      data-content={popoverContent}
      data-trigger={popoverTrigger}
      data-placement="auto"
      data-html
    />
  );
};

export default OvertimeLogsPopover;
