// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  isYearCalendar: boolean,
  selectedYear: number,
  onNavPrevYearClick: Function,
  onNavNextYearClick: Function,
}

const NavYearSelector = (props: Props): Node => {
  const {
    isYearCalendar,
    selectedYear,
    onNavPrevYearClick,
    onNavNextYearClick,
  } = props;
  if (!isYearCalendar) {
    return <div className="invisible" />;
  }

  return (
    <div className="date-nav-container">
      <button type="button" className="btn arrow-left js-prev-week" onClick={onNavPrevYearClick}>
        <i className="fa fa-angle-left fa-2x" aria-hidden="true" />
      </button>
      <p className="navbar-text calendar-date">
        {`${selectedYear}`}
      </p>
      <button type="button" className="btn arrow-right js-next-week" onClick={onNavNextYearClick}>
        <i className="fa fa-angle-right fa-2x" aria-hidden="true" />
      </button>
    </div>
  );
};

export default NavYearSelector;
