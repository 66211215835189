// @flow
import React from 'react';
import type { Node } from 'react';
import CalendarContentBig from './CalendarContentBig';
import CalendarContentSmall from './CalendarContentSmall';
import type { CalendarContentProps } from '../../../types';

const CalendarContent = (props: CalendarContentProps): Node => (
  <>
    <CalendarContentBig {...props} />
    <CalendarContentSmall {...props} />
  </>
);

export default CalendarContent;
