// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ProfileView from '../components/views/Profile';
import { editUser, editProject, bulkSetVacations } from '../redux/actions';
import { getUsersProjects } from '../helpers';
import { withRouter } from '../helpers/withRouter';
import type {
  State as StoreState, User, Project, VacationsPayload, ProfileProps,
} from '../types';

class Profile extends Component<ProfileProps> {
  render(): Node {
    return (
      <ProfileView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  projects: _.sortBy(getUsersProjects(state.currentUser, state.projects.items), 'title'),
  currentUser: {
    ...state.currentUser,
    ..._.find(state.users, { id: state.currentUser.id }), // on update own profile we need updated info
  },
  holidays: state.holidays,
  vacations: state.vacations[state.currentUser.id],
});

const mapDispatchToProps = dispatch => ({
  onEditUser: (user: User) => dispatch(editUser(user)),
  onEditProject: (project: Project) => dispatch(editProject(project)),
  onBulkSetVacations: (userId: number, year: number, vacations: VacationsPayload) => dispatch(bulkSetVacations(userId, year, vacations)),
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)((Profile: React$ComponentType<ProfileProps>))): any);
