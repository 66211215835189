// @flow
/* eslint camelcase: ["off"] */
import React, { Component } from 'react';
import type { Node } from 'react';
import { format } from 'date-fns';
import { map, startCase } from 'lodash';
import classNames from 'classnames';
import ModalHeader from '../modal/ModalHeader';
import ModalFooter from '../modal/ModalFooter';
import ModalControls from '../modal/ModalControls';
import { getNextWeekdayDate } from '../../../helpers/dates';
import type { WeekdayLabels } from '../../../helpers/dates';

type Props = {
  log: Object,
  onSubmit: Function,
  onCloseModal: Function,
}

type State = {
  selectedDay: WeekdayLabels
}

class CopyTimeLogModal extends Component<Props, State> {
  state: State = {
    selectedDay: 'mon',
  };

  handleSelectDay = (day: WeekdayLabels) => {
    this.setState({ selectedDay: day });
  };

  handleClose = () => {
    this.handleSelectDay('mon');
    this.props.onCloseModal();
  };

  handleSubmit = () => {
    const { log, onSubmit } = this.props;
    const { selectedDay } = this.state;

    onSubmit(log.project_id, {
      ...log,
      date: getNextWeekdayDate(new Date(log.date), selectedDay),
    }).then(this.handleClose);
  };

  handleCopyToDay = () => {
    const { log, onSubmit } = this.props;
    onSubmit(log.project_id, log).then(this.handleClose);
  };

  getDayText = (date: string) => `Copy to ${date && format(new Date(date), 'EEEE')}`;

  render(): Node {
    const { log } = this.props;
    const { selectedDay } = this.state;
    const weekdays = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
    };

    return (
      <>
        <ModalHeader
          title={`Copy to next ${weekdays[selectedDay]}?`}
          onCloseClick={this.handleClose}
        />
        <div className="copy-time-log-modal-buttons btn-group p-3 mx-auto" role="group">
          {map(weekdays, (day, key) => (
            <button
              key={key}
              type="button"
              className={classNames('btn', {
                'btn-primary primary-color': key === selectedDay,
                'btn-secondary': key !== selectedDay,
              })}
              onClick={() => this.handleSelectDay(key)}
            >
              {startCase(key)}
            </button>
          ))}
        </div>
        <ModalFooter>
          <ModalControls
            showEditButton
            disableButtons={false}
            isFormValid={false}
            onSubmitButtonClick={this.handleSubmit}
            onDeleteButtonClick={this.handleCopyToDay}
            onCloseButtonClick={this.handleClose}
            submitButtonText="Yes"
            deleteButtonText={this.getDayText(log.date)}
          />
        </ModalFooter>
      </>
    );
  }
}

export default (CopyTimeLogModal: React$ComponentType<Props>);
