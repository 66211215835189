// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import UsersTableSearch from './UsersTableSearch';
import type { Project } from '../../../../types';

type Props = {
  selectedRole: string,
  selectedProjectId: number,
  onSelectRoleChange: Function,
  onSelectProjectChange: Function,
  onSearchUsersSubmit: Function,
  onSearchUsersReset: Function,
  onInactiveChange: Function,
  onStudentChange: Function,
  searchTriggered: boolean,
  roles: Array<any>,
  projects: Array<Project>,
  inactive: boolean,
  student: boolean,
  triggeredRole: string,
  triggeredProjectId: any,
};

const UsersTableHeader = (props: Props): Node => {
  const {
    selectedRole,
    selectedProjectId,
    onSelectRoleChange,
    onSelectProjectChange,
    onSearchUsersSubmit,
    onSearchUsersReset,
    onInactiveChange,
    onStudentChange,
    searchTriggered,
    roles,
    projects,
    inactive,
    student,
    triggeredRole,
    triggeredProjectId,
  } = props;
  const triggeredProjectTitle = triggeredProjectId ? _.find(projects, ['id', triggeredProjectId]).title : '';

  return (
    <div className="container container-header mt-4">
      <div className="upper-header-wrapper">
        <div className="left-column">
          <h2>Users</h2>
          <hr className="float-left" />
          <p className="clearfix mb-4">List of all users and roles</p>
        </div>
        <UsersTableSearch
          selectedRole={selectedRole}
          selectedProjectId={selectedProjectId}
          onSelectRoleChange={onSelectRoleChange}
          onSelectProjectChange={onSelectProjectChange}
          onSearchUsersSubmit={onSearchUsersSubmit}
          onSearchUsersReset={onSearchUsersReset}
          onInactiveChange={onInactiveChange}
          onStudentChange={onStudentChange}
          inactive={inactive}
          student={student}
          roles={roles}
          projects={projects}
        />
      </div>
      {(searchTriggered && triggeredRole !== 'any') ? (
        <div className="filter-result">
          <div className="text-wrapper">
            {(triggeredProjectId && triggeredProjectId !== 0)
              ? _.capitalize(triggeredRole) + ' users on ' + triggeredProjectTitle
              : _.capitalize(triggeredRole) + ' users'
            }
          </div>
        </div>
      ) : (
        <div className="filter-result">
          <div className="text-wrapper">
            {(searchTriggered && triggeredProjectId !== 0) ? ('All users on ' + triggeredProjectTitle) : 'All users'}
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersTableHeader;
