// @flow
import { CLOSE_ERROR, CLEAR_ERROR } from './types';
import type { Action } from '../../types';

export const closeError = (): Action => ({
  type: CLOSE_ERROR,
});

export const clearError = (): Action => ({
  type: CLEAR_ERROR,
});
