// @flow
import React from 'react';
import type { Node } from 'react';
import NavLinks from './NavLinks';

type Props = {
  isOwner?: boolean,
  isAdmin?: boolean,
  isClient?: boolean,
}

const NavDropdown = (props: Props): Node => {
  const { isOwner, isAdmin, isClient } = props;

  return (
    <div className="nav-item dropdown">
      <button className="btn menu-bars" id="navbarDropdownMenu" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fa fa-bars fa-2x" aria-hidden="true" />
      </button>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenu">
        <NavLinks isAdmin={isAdmin} isOwner={isOwner} isClient={isClient} />
      </ul>
    </div>
  );
};

export default NavDropdown;
