// @flow
import React from 'react';
import type { Node } from 'react';
import ConfirmModal from '../../modal/ConfirmModal';

type Props = {
  onFetchHolidaysFromApi: Function,
};

const HolidaysTableHeader = (props: Props): Node => {
  const { onFetchHolidaysFromApi } = props;

  return (
    <div className="container container-header holidays-header mt-4">
      <div className="upper-header-wrapper mb-2">
        <div className="left-column">
          <p className="clearfix mb-4">List of all holidays</p>
        </div>
        <div className="right-column">
          <div className="buttons-wrapper">
            <ConfirmModal onConfirm={onFetchHolidaysFromApi} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HolidaysTableHeader;
