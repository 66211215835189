// @flow
import _ from 'lodash';
import {
  addYears,
  differenceInMinutes,
  getDayOfYear,
  setDayOfYear,
  setISOWeek,
  setYear,
} from 'date-fns';
import type {
  User, Project, ReactSelectData, Notification, NotificationState,
} from '../types';
import { getWeekBeginningMon } from './dates';

declare var $: Function;

export function getRequestWeekForUser(date: Date, userId: number): string {
  return `${date.getFullYear()}-${getWeekBeginningMon(date)}-${userId}`;
}

export function getCSRFToken(): string {
  /* $FlowFixMe */
  return document.querySelector('[name=csrf-token]').content;
}

export function getMonthsForSelect(): Array<any> {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months.map((month, index) => ({
    id: index + 1,
    title: month,
  }));
}

export function getMonthsForReactSelect(): Array<any> {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return months.map((month, index) => ({
    value: index + 1,
    label: month,
  }));
}

export function getYearsForSelect(): number[] {
  return _.range(2017, addYears(new Date(), 2).getFullYear()).map(year => ({
    id: year,
    title: year,
  }));
}

export function getVacationYearsForSelect(): number[] {
  return _.range(new Date().getFullYear(), addYears(new Date(), 2).getFullYear()).map(year => ({
    id: year,
    title: year,
  }));
}

export function isEmailValid(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export function arrangeById(items: Array<any>): Object {
  return _.forIn(_.groupBy(items, 'id'), (value, itemId, item) => {
    item[itemId] = { ..._.first(item[itemId]) };
  });
}

export function getFirstItemId(items: Array<any>): number {
  if (_.isArray(items) && !_.isEmpty(items)) {
    const firstItem = _.first(items);

    return firstItem.id || 0;
  }

  return 0;
}

export function getUserRolesForSelect(roles: Array<any>): Array<any> {
  return roles.map(role => ({
    id: role[0],
    title: role[0],
  }));
}

export function getProjectsForSelect(projects: Array<any>): Array<any> {
  return projects.map(project => ({
    id: project.id,
    title: project.title,
  }));
}

export function getArrayForSelect(array: Array<any>): Array<any> {
  return array.map(item => ({
    id: item,
    title: item,
  }));
}

export function isUserAdmin(user: User): boolean {
  return user.role === 'admin';
}

export function isUserOwner(user: User): boolean {
  return user.role === 'owner';
}

export function isUserClient(user: User|{ role: string }): boolean {
  return user.role === 'client';
}

export function isUserMember(user: User|{ role: string }): boolean {
  return user.role === 'member';
}

export function isOwnerOrAdmin(user: User): boolean {
  return isUserAdmin(user) || isUserOwner(user);
}

export function canManageOwnVacations(user: User|{ role: string }): boolean {
  return ['member', 'owner', 'admin'].includes(user.role);
}

export function getCalendarRoute(year: number, weekNum: number, userId: ?number): string {
  let route = `/${year}/week/${weekNum}`;
  if (userId) {
    route += `?user_id=${userId}`;
  }

  return route;
}

export function getNormalizedLogs(daysInWeek: Array<Object>): Object {
  const logs = _.flatMap(daysInWeek, log => log.items);
  const logsByUserId = _.forIn(_.groupBy(logs, 'user.id'), (userLogs, userId, users) => {
    users[userId] = userLogs.map(log => log.id);
  });
  const logsById = arrangeById(logs);
  const logsByWeekday = {};
  _.forEach(daysInWeek, day => {
    logsByWeekday[day.date] = day.items.map(log => ({ id: log.id, userId: log.user.id }));
  });

  return {
    items: logs,
    draggedLog: null,
    logsById,
    logsByUserId,
    logsByWeekday,
    datesWithLogs: [],
  };
}

export function getNormalizedProjects(projects: Array<Object>): Object {
  return {
    items: projects,
    projectsById: arrangeById(projects),
    projectsToDisplay: projects,
  };
}

export function getNormalizedDaysInWeek(daysInWeek: Array<Object>): Object {
  return _.forIn(_.groupBy(daysInWeek, 'date'), (value, key, day) => {
    value = { ...value[0] };
    day[key] = {
      date: value.date,
      logIds: value.items.map(log => log.id),
      dailyTotal: value.daily_total,
    };
  });
}

export function getNormalizedProjectStatistics(projectStatistic: any, month: number, year: number): Object {
  const stats = {};
  stats[`${projectStatistic.project_id}-${month}-${year}`] = {
    monthlyView: projectStatistic.monthly_view.map(([x, y]) => ({ x, y })),
    radialView: projectStatistic.type_breakdown,
  };

  return stats;
}

export function getNormalizedNotifications(state: NotificationState, notificationsPayload: Array<Notification>): NotificationState {
  const byId = { ...state.byId, ..._.keyBy(notificationsPayload, 'id') };

  return {
    total: state.total,
    items: _.orderBy(_.values(byId), 'id', 'desc'),
    byId,
  };
}

export function getNormalizedData({
  currentUser, dates, projects, users, daysInWeek, roles, projectsDropdown, projectStatistics, categories,
  notifications, notificationsCount, projectsWeeklyHours, holidays, vacations, overtime, subscriptions,
} : any, token: string, page: string): Object {
  const requestCalendar = page === '/' ? {
    [getRequestWeekForUser(new Date(daysInWeek[0].date), currentUser.id)]: new Date(),
  } : {};
  const requestProjects = {}; /* page === 'projects' ? {
    []: new Date(),
  } : {}; */
  const logsData = getNormalizedLogs(daysInWeek);
  daysInWeek = getNormalizedDaysInWeek(daysInWeek);
  projectStatistics = isUserClient(currentUser) && projectStatistics.length > 0
    ? getNormalizedProjectStatistics(projectStatistics[0], (new Date().getMonth() + 1), new Date().getFullYear())
    : {};

  return {
    logs: { ...logsData },
    dates,
    projects: getNormalizedProjects(projects),
    users,
    daysInWeek,
    selectedUser: currentUser,
    currentUser: {
      ...currentUser, token, isUserClient: isUserClient(currentUser), isUserAdmin: isUserAdmin(currentUser), isUserOwner: isUserOwner(currentUser),
    },
    roles: roles || [],
    projectsDropdown,
    requests: {
      calendar: requestCalendar,
      projects: requestProjects,
    },
    projectStatistics,
    categories,
    notifications: getNormalizedNotifications({ items: [], byId: {}, total: notificationsCount }, notifications),
    projectsWeeklyHours,
    holidays,
    modal: {},
    vacations,
    overtime,
    subscriptions,
  };
}

export function hasCalendarRequestExpired(year: number, weekNum: number, userId: number, requests: Object): boolean {
  const date = setISOWeek(setYear(new Date(), year), weekNum);
  const requestWeekId = getRequestWeekForUser(date, userId);
  const requestExpireDate: Date = requests[requestWeekId];
  if (!requests || !requestExpireDate) return true;

  return differenceInMinutes(new Date(), new Date(requestExpireDate)) > 30;
}

export function hasProjectRequestExpired(year: number, month: number, requests: Object): boolean {
  const requestParams = `${month}-${year}`;
  if (_.isEmpty(requests) || _.isNil(requests[requestParams])) {
    return true;
  }

  return differenceInMinutes(new Date(), new Date(requests[requestParams])) > 30;
}

export function mapEnumJsontoArray(enumjson: any): Array<Object> {
  const array = [];
  const keys = Object.keys(JSON.parse(enumjson));

  for (let i = 0; i < keys.length; i++) {
    array[i] = { title: _.startCase(keys[i]), id: keys[i] };
  }

  return array;
}

export function projectsAsReactSelectData(projects: Array<Project>): ReactSelectData {
  return projects.map((p: Project) => ({ value: p.id, label: p.title }));
}

export function getUsersProjects(user: User, projects: Object|Array<Project>): Object[] {
  const projectIds = user.project_ids;
  if (!projectIds || projectIds.length === 0 || _.isEmpty(projects)) {
    return [];
  }

  return _.isArray(projects)
    ? projects.filter(p => projectIds.includes(p.id))
    // $FlowFixMe
    : projectIds.map(projectId => projects[projectId]);
}

export const displayNumber = (num: number|string): string => parseFloat(num).toFixed(2);

export const getPastDaysForYear = (year: number): Date[] => _.times(getDayOfYear(new Date()), i => {
  const iDate = setDayOfYear(new Date(), i + 1);

  return new Date(year, iDate.getMonth(), iDate.getDate());
});

export const getAsDateArray = (dates: Array<{ date: string }>): Date[] => _.map(dates, d => {
  const date = new Date(d.date);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
});

export const initPopElements = () => {
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').tooltip();
};

export const removePopElements = () => {
  $('[data-toggle="popover"]').popover('dispose');
  $('[data-toggle="tooltip"]').tooltip('dispose');
};

export const getMemberRelevantProjects = (projects: Project[], currentUser: User): Project[] => {
  const irrelevantProjectIds = { thespian: 93, other: 38 };

  const isRelevantProject = (project: Project) => project.active && !_.includes(_.values(irrelevantProjectIds), project.id) && _.includes(currentUser.projects_with_recent_logs, project.id);

  return _.filter(projects, (project) => isRelevantProject(project));
};
