// @flow
import React from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  link: string,
  label: string,
  last?: boolean,
}

const NavLink = (props: Props): Node => {
  const { link, label, last } = props;
  const activeLink = '/';
  let liClasses = 'dropdown-item nav-item';
  if (last) {
    liClasses += ' last-ch';
  }
  if (link === activeLink) {
    liClasses += ' active';
  }

  return (
    <li className={liClasses}>
      <Link className="nav-link" to={link}>{label}</Link>
    </li>
  );
};

export default NavLink;
