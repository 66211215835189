// @flow
import React from 'react';
import type { Node } from 'react';

const NavLogoutLink = (): Node => (
  <li className="dropdown-item last-ch">
    <a className="nav-link" rel="nofollow" data-method="delete" href="/logout">Logout</a>
  </li>
);

export default NavLogoutLink;
