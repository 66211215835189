// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import UsersTableRow from './UsersTableRow';
import type { User } from '../../../../types';

type Props = {
  users: Array<User>,
  onEditModalOpen: Function,
  onAddModalOpen: Function,
}

const UsersTableContent = (props: Props): Node => {
  const { users, onEditModalOpen, onAddModalOpen } = props;

  return (
    <div className="container main-content">
      <div className="table-navbar">
        <div className="w-100">
          <h2>User</h2>
        </div>
        <div className="w-80px">
          <h2>Role</h2>
        </div>
      </div>
      <div className="table-rows users full-height">
        {_.sortBy(users, ['role', 'name']).map(user => (
          <UsersTableRow
            key={user.id}
            user={user}
            onEditModalOpen={onEditModalOpen}
          />
        ))}
      </div>
      <button
        type="button"
        className="add-btn"
        id=""
        onClick={onAddModalOpen}
      >
        <i className="fa fa-plus" aria-hidden="true" />
      </button>
    </div>
  );
};

export default UsersTableContent;
