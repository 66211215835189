// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import ProjectView from '../components/views/Projects';
import type {
  State, User, ProjectRequest, Projects as ProjectsType, Project,
  SingleModalState,
} from '../types';
import {
  addProject, editProject, removeProject, projectSearch, datesChanged,
  openModal, closeModal, updateModal,
} from '../redux/actions';

type Props = {
  currentUser: User,
  projects: ProjectsType,
  onAddProject: Function,
  onRemoveProject: Function,
  onEditProject: Function,
  onProjectSearch: Function,
  onDatesChanged: Function,
  onOpenModal: Function,
  onCloseModal: Function,
  onUpdateModal: Function,
  navigate: Object,
  location: Object,
  requests: Object,
};

class Projects extends Component<Props> {
  render(): Node {
    return (
      <ProjectView
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  ...state,
  requests: state.requests.projects,
});

const mapDispatchToProps = dispatch => ({
  onAddProject: (project: ProjectRequest) => dispatch(addProject(project)),
  onEditProject: (project: Project) => dispatch(editProject(project)),
  onRemoveProject: (projectId: number) => dispatch(removeProject(projectId)),
  onProjectSearch: (year: number, month: number) => dispatch(projectSearch(year, month)),
  onDatesChanged: (year: number, month: number) => dispatch(datesChanged(year, month)),
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  onUpdateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
});

export default (connect(mapStateToProps, mapDispatchToProps)((Projects: React$ComponentType<Props>)): any);
