// @flow
import React, { Component } from 'react';
import type { Node } from 'react';

type Props = {
  month: number,
  day: number,
  dayName: string,
  isToday: boolean,
  onTabLinkClick: Function,
  activeTab: string,
}

class DayHeadingSmall extends Component<Props> {
  handleClick = (event: Object) => {
    event.preventDefault();
    const { dayName, onTabLinkClick } = this.props;
    onTabLinkClick(dayName);
  };

  render(): Node {
    const {
      month, day, dayName, isToday, activeTab,
    } = this.props;
    let liClasses = 'nav-item col seven-cols';
    let linkClasses = 'nav-link js-day-navigation';
    if (isToday) {
      liClasses += ' today';
      linkClasses += ' highlight-today';
    }
    if (activeTab === dayName) {
      linkClasses += ' active';
    }

    return (
      <li className={liClasses} data-date={day}>
        <a className={linkClasses} href={`#${dayName}`} data-day={`${month}-${day}`} onClick={this.handleClick}>
          {`${day}/${month}`}
        </a>
      </li>
    );
  }
}

export default (DayHeadingSmall: React$ComponentType<Props>);
