// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import ModalBody from '../modal/ModalBody';
import ModalHeader from '../modal/ModalHeader';
import ModalFooter from '../modal/ModalFooter';
import ModalControls from '../modal/ModalControls';
import Select from '../../elements/Select';
import ReactSelect from '../../elements/ReactSelect';
import Input from '../../elements/Input';
import { getArrayForSelect, projectsAsReactSelectData } from '../../../helpers';
import { selectors } from '../../../redux/selectors/projects';
import type { Project } from '../../../types';

type Props = {
  onCloseClick: Function,
  addNotification: Function,
  checkForNotifications: Function,
  projects: Array<Project>,
  disableButtons?: boolean,
  loading?: boolean,
};

type State = {
  activeTarget: 'slack'|'mail'|'custom',
  selectedSlackChannel: string,
  slackMessage: string,
  sentMessage: boolean,
  selectedPresetMail: string,
  selectedProjects: Array<any>,
  formError: string,
  showReactSelect: boolean,
};

class SendNotificationsModal extends Component<Props, State> {
  static defaultProps = {
    disableButtons: false,
    loading: false,
  };

  targets = ['slack', 'mail'];

  targetsName = {
    slack: 'Slack',
    mail: 'Preset Mail',
    custom: 'Custom email',
  };

  defaultState = {
    activeTarget: 'slack',
    selectedSlackChannel: '#general',
    slackMessage: 'Please check your Timesheets calendar.',
    sentMessage: false,
    selectedPresetMail: 'Monthly limit',
    selectedProjects: [],
    formError: '',
    showReactSelect: true,
  };

  slackChannelOptions = getArrayForSelect(['#general']);

  presetMailOptions = getArrayForSelect(['Monthly limit', 'Weekly report']);

  state = this.defaultState;

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { selectedPresetMail } = this.state;
    if (prevState.selectedPresetMail !== selectedPresetMail) {
      this.resetSelectedProjects();
    }
  }

  resetSelectedProjects = () => {
    this.setState({
      showReactSelect: false,
      selectedProjects: [],
    });
    setTimeout(() => {
      this.setState({ showReactSelect: true });
    }, 200);
  };

  handleInputChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleReactSelectInputChange = (name: string, val: any) => {
    this.setState({
      [name]: _.isNil(val) ? [] : val,
    });
  };

  isFormValid = () => {
    const { activeTarget, slackMessage, selectedProjects } = this.state;
    if (activeTarget === 'slack' && slackMessage.length === 0) {
      this.setState({ formError: 'You must enter a text message!' });

      return false;
    }
    if (activeTarget === 'mail' && selectedProjects.length === 0) {
      this.setState({ formError: 'You must select a project!' });

      return false;
    }

    return true;
  };

  handleSubmit = () => {
    const { activeTarget } = this.state;
    const { addNotification, checkForNotifications } = this.props;
    if (this.isFormValid()) {
      let { slackMessage: message, selectedSlackChannel: sentTo } = this.state;
      if (activeTarget === 'mail') {
        const { selectedProjects, selectedPresetMail } = this.state;
        message = selectedPresetMail;
        sentTo = _.map(selectedProjects, 'value').join();
      }
      addNotification(activeTarget, message, sentTo)
        .then(() => {
          setTimeout(() => {
            checkForNotifications();
          }, 1500);
          this.setState({ sentMessage: true, formError: '' });
        });
    }
  };

  handleCloseClick = () => {
    const { onCloseClick } = this.props;
    this.setState({ formError: '' });
    onCloseClick();
  };

  hideWithDelay = () => {
    setTimeout(() => {
      this.setState({ sentMessage: false });
    }, 2500);
  };

  getProjectsForReactSelect = () => {
    let { projects } = this.props;
    const { selectedPresetMail } = this.state;
    if (selectedPresetMail === 'Monthly limit') {
      projects = selectors.getProjectsWithMonthyLimit(projects);
    } else if (selectedPresetMail === 'Weekly report') {
      projects = selectors.getProjectsWithEnabledReportSending(projects);
    }

    return projectsAsReactSelectData(projects);
  };

  render(): Node {
    const {
      activeTarget, selectedSlackChannel, slackMessage, sentMessage,
      formError, selectedPresetMail, selectedProjects, showReactSelect,
    } = this.state;
    const { disableButtons, loading } = this.props;
    const projectsForReactSelect = this.getProjectsForReactSelect();

    return (
      <>
        <ModalHeader
          title="Send new notifications"
          onCloseClick={this.handleCloseClick}
        />
        <ModalBody>
          <div className="col mb-2">
            <span className="mr-2">Target:</span>
            <div className="btn-group" role="group" aria-label="Default button group">
              {this.targets.map(target => (
                <button
                  key={target}
                  type="button"
                  className={`btn ${activeTarget === target ? 'btn-secondary' : 'btn-light'}`}
                  onClick={() => this.setState({ activeTarget: target })}
                >
                  {this.targetsName[target]}
                </button>
              ))}
            </div>
          </div>
          {activeTarget === 'slack' && (
            <div className="col">
              <Select
                groupClass="month js-month"
                selectClass="form-control select-project"
                labelClass=""
                name="selectedSlackChannel"
                label="Slack Channel:"
                id="slack-channel"
                items={this.slackChannelOptions}
                value={selectedSlackChannel}
                onSelectChange={this.handleInputChange}
              />
              <Input
                label="Enter message:"
                id="slack-message"
                name="slackMessage"
                value={slackMessage}
                onChange={this.handleInputChange}
              />
            </div>
          )}
          {activeTarget === 'mail' && (
            <div className="col">
              <Select
                groupClass="month js-month"
                selectClass="form-control select-project"
                labelClass=""
                name="selectedPresetMail"
                label="Preset Mail:"
                id="preset-mail"
                items={this.presetMailOptions}
                value={selectedPresetMail}
                onSelectChange={this.handleInputChange}
              />
              {showReactSelect && (
                <ReactSelect
                  id="project_ids"
                  name="selectedProjects"
                  label="Projects"
                  defaultValue={selectedProjects}
                  options={projectsForReactSelect}
                  onChange={(val: any) => this.handleReactSelectInputChange('selectedProjects', val)}
                />
              )}
              <span className="text-secondary">
                {selectedPresetMail === 'Monthly limit'
                  ? 'Monthly limit report will not be sent if criteria has not been reached.'
                  : 'Weekly report will not be sent if there are no log entries for the current week.'
                }
              </span>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {loading && <div>Sending...</div>}
          {sentMessage && <div ref={this.hideWithDelay}>Sent!</div>}
          {formError && <div className="text-danger">{formError}</div>}
          <ModalControls
            showEditButton={false}
            disableButtons={disableButtons || false}
            onCloseButtonClick={this.handleCloseClick}
            submitButtonText="Send"
            onSubmitButtonClick={this.handleSubmit}
          />
        </ModalFooter>
      </>
    );
  }
}

export default (SendNotificationsModal: React$ComponentType<Props>);
