// @flow
import React, { Component, type Node } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { openModal, closeModal, updateModal } from '../../../redux/actions';
import type { State, SingleModalState } from '../../../types';

type Props = {
  id: string,
  children: Node,
  error: boolean,
  modal: SingleModalState,
  watchError: string,
  loading: boolean,
  onUpdateModal: Function,
}

class Modal extends Component<Props> {
  static defaultProps = {
    watchError: '',
    loading: false,
  };

  componentDidUpdate(prevProps: Props) {
    const {
      modal: { display }, error, watchError, onUpdateModal, id, loading,
    } = this.props;
    const { body } = document;
    if (prevProps.modal.display !== display && body) {
      body.classList.remove('modal-open');
      if (display) {
        body.classList.add('modal-open');
      }
    }

    const expectedErrorOccured = watchError !== '' && error && prevProps.error !== error;
    const loadingOver = !loading && prevProps.loading !== loading;
    if (display && (expectedErrorOccured || loadingOver)) {
      onUpdateModal({ id, disableButtons: false });
    }
  }

  render(): Node {
    const {
      children, error, modal: { display }, id, loading,
    } = this.props;
    const { modal } = this.props;
    const classesModal = display ? 'modal fade show d-block' : 'modal';
    const classesBackdrop = display ? 'modal-backdrop show' : '';
    const disableButtons = modal.disableButtons && !error;
    const childrenWithProps = React.Children.map(
      children,
      // $FlowFixMe
      child => React.cloneElement(child, {
        error, loading, ...modal, disableButtons,
      }),
    );

    return (
      <>
        <div className={classesModal} id={id} tabIndex="-1" role="dialog" aria-labelledby={id} aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {childrenWithProps}
            </div>
          </div>
        </div>
        <div className={classesBackdrop} />
      </>
    );
  }
}

const getErrorState = (state: State, props: Props) => {
  const { watchError } = props;
  let error = state.error.display && _.includes(state.error.type, '_REJECTED');
  if (error && watchError !== '') {
    error = state.error.type === watchError;
  }

  return error;
};

const mapStateToProps = (state: State, ownProps: Props) => ({
  currentUser: state.currentUser,
  modal: _.get(state.modal, ownProps.id, { display: false }),
  error: getErrorState(state, ownProps),
  loading: state.displayLoader,
});

const mapDispatchToProps = dispatch => ({
  onOpenModal: (modal: SingleModalState) => dispatch(openModal(modal)),
  onCloseModal: (modal: SingleModalState) => dispatch(closeModal(modal)),
  onUpdateModal: (modal: SingleModalState) => dispatch(updateModal(modal)),
});

export default (connect(mapStateToProps, mapDispatchToProps)((Modal: React$ComponentType<Props>)): any);
