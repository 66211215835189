// @flow
import React from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { displayNumber } from '../../../helpers';
import type { Project } from '../../../types';

type Props = {
  project: Project,
  statistics: any,
  totalSum: number,
  colors: any,
};

const ReportTable = (props: Props): Node => {
  const {
    project, statistics, totalSum, colors,
  } = props;
  const categorizeLogs = project.categorize_logs;
  const categories = categorizeLogs ? _.sortBy(_.keys(statistics)) : [];

  return (
    <table className="table mt-2">
      <thead>
        <tr className="text-center table-project-header">
          <th colSpan="2">{project.title}</th>
        </tr>
        <tr>
          {categorizeLogs && (
            <th scope="col">Category</th>
          )}
          <th scope="col" className="text-center">Time spent</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((category: string) => (
          <tr key={category}>
            <td>
              <i className="fa fa-circle mr-2" style={{ color: colors[category] }} aria-hidden="true" />
              {_.startCase(category) + ` (${Math.round((statistics[category] / _.sum(_.values(statistics))) * 100) + '%'})`}
            </td>
            <td className="text-right">
              <span className="mr-3">
                {`${displayNumber(statistics[category])}h`}
              </span>
            </td>
          </tr>
        ))}
        <tr className="text-center">
          {categorizeLogs && (
            <td />
          )}
          <td className="table-secondary text-right">
            <span className="mr-3">
              {`${displayNumber(totalSum)}h`}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReportTable;
